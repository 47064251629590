import React from 'react'
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from "react-router-dom";
import { actionTypes, useStateValue } from "./../../store";
import FotterMain from '../BaseComponents/FotterMain';
import Header from '../BaseComponents/Header';

const Compare = () => {
    const products = [
        {
          id: "1",
          name: 'Product Name',
          category: 'Tillers',
          price: "23,231",
          image: "https://www.hondaindiapower.com/uploads/products/6/WB15X.jpg",
          sdesc: "WB20XD, the 2 Inch category model",

        }, {
            id: "2",
            name: 'Product Name',
            category: 'Tillers',
            price: "23,231",
            image: "https://www.hondaindiapower.com/uploads/products/6/WB15X.jpg",
            sdesc: "WB20XD, the 2 Inch category model",
        },
        {
            id: "3",
          name: 'Product Name',
          category: 'Tillers',
          price: "23,231",
          image: "https://www.hondaindiapower.com/uploads/products/6/WB15X.jpg",
          sdesc: "WB20XD, the 2 Inch category model",
        },
        {
            id: "4",
            name: 'Product Name',
            category: 'Tillers',
            price: "23,231",
            image: "https://www.hondaindiapower.com/uploads/products/6/WB15X.jpg",
            sdesc: "WB20XD, the 2 Inch category model",
        }
      ];
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location.state.id);

    const [{compareitemcount}, dispatch] = useStateValue();

    // const removeAllCompareData = () => {
    //     localStorage.removeItem("allEntries");
    //     dispatch({ type: actionTypes.SET_COMPAREITEMCOUNT, value: '' });
     
    // }
    const goBack = () => {
       navigate(-1);
    }
    


    return (
        <>
            <Header />
            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <h3 className='txtdgray text-uppercase fw-bold f22'>Compare</h3>
                        </div>
                    </div>
                </div>
       
                {location.state.id}<br />

                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="compare card">
                                <div className='d-flex align-items-center p-3 justify-content-between' style={{backgroundColor:'#f7f7f7'}}>
                                    <div className='f16 text-uppercase fw-bold'>
                                        <span className='txtred'>Compare</span> <span className='txtdgray'>Result</span>
                                    </div>
                                    {/* <Button variant="text" 
                                    onClick={removeAllCompareData} 
                                    size="small" className='d-block'>
                                         REMOVE ALL
                                    </Button> */}
                                    <Button variant="text" 
                                    onClick={goBack} 
                                    size="small" className='d-block'>
                                         GO BACK
                                    </Button>
                                </div>
                                <div className="table-responsive">
                                    <table className="compare__table compare-table table">
                                        <thead className="thead-default">
                                        <tr className='compare-table__row'>
                                            <th />
                                            {products?.map(product =>
                                            <th key={product.id} className="compare-table__column--product">
                                                <img alt="" className="img-fulid" style={{maxWidth:'100px'}} src={product.image} />
                                                <p className='mt-3'>{product.name}</p>
                                            </th>
                                            )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className="price">
                                            <th scope="row" className='compare-table__column compare-table__column--header'>Price</th>
                                            {products?.map(product =>
                                            <td key={product.id} className="compare-table__column--product">{product.price}</td>
                                            )}
                                        </tr>
                                        <tr className="category">
                                            <th scope="row" className='compare-table__column compare-table__column--header'>Category</th>
                                            {products?.map(product =>
                                            <td key={product.id} className="compare-table__column--product">
                                                {product.category}
                                            </td>
                                            )}
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <FotterMain />
        </>
    )
}

export default Compare