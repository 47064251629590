import React, { useState } from 'react'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import * as yup from 'yup';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { jobApply } from '../../networkCalls';
import { Tune } from '@mui/icons-material';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow:'scroll',
};

const expddl = [
    {
        value: '0',
        label: '0 years',
    },
    {
        value: '1',
        label: '1 years',
    },
    {
        value: '2',
        label: '2 years',
    },
    {
        value: '3',
        label: '3 years',
    },
    {
        value: '4',
        label: '4 years',
    },
    {
        value: '5',
        label: '5 years',
    },
    {
        value: '6',
        label: '6 years',
    },
    {
        value: '7',
        label: '7 years',
    },
    {
        value: '8',
        label: '8 years',
    },
    {
        value: '9',
        label: '9 years',
    },

    {
        value: '10',
        label: '10 years',
    },
    {
        value: '11',
        label: '11 years',
    },
    {
        value: '12',
        label: '12 years',
    },
    {
        value: '13',
        label: '13 years',
    },
    {
        value: '14',
        label: '14 years',
    },
    {
        value: '15',
        label: '15 years',
    },
    {
        value: '16',
        label: '16 years',
    },
    {
        value: '16',
        label: '16 years',
    },
    {
        value: '17',
        label: '17 years',
    },
    {
        value: '18',
        label: '18 years',
    },
    {
        value: '19',
        label: '19 years',
    },
    {
        value: '20',
        label: '20 years',
    },
    {
        value: '21',
        label: '21 years',
    },
    {
        value: '22',
        label: '22 years',
    },
    {
        value: '23',
        label: '23 years',
    },
    {
        value: '24',
        label: '24 years',
    },
    {
        value: '25',
        label: '25 years',
    },
    {
        value: '26',
        label: '26 years',
    },
    {
        value: '27',
        label: '27 years',
    },
    {
        value: '28',
        label: '28 years',
    },
    {
        value: '29',
        label: '29 years',
    },
    {
        value: '30',
        label: '30 years',
    },
    {
        value: '31',
        label: '31 years',
    },
    {
        value: '32',
        label: '32 years',
    },
    {
        value: '33',
        label: '33 years',
    },
    {
        value: '34',
        label: '34 years',
    },
    {
        value: '35',
        label: '35+ years',
    },
];
const ageddl = [
    {
        value: '18',
        label: '18 years',
    },
    {
        value: '19',
        label: '19 years',
    },
    {
        value: '20',
        label: '20 years',
    },
    {
        value: '21',
        label: '21 years',
    },
    {
        value: '22',
        label: '22 years',
    },
    {
        value: '23',
        label: '23 years',
    },
    {
        value: '24',
        label: '24 years',
    },
    {
        value: '25',
        label: '25 years',
    },
    {
        value: '26',
        label: '26 years',
    },
    {
        value: '27',
        label: '27 years',
    },
    {
        value: '28',
        label: '28 years',
    },
    {
        value: '29',
        label: '29 years',
    },
    {
        value: '30',
        label: '30 years',
    },
    {
        value: '31',
        label: '31 years',
    },
    {
        value: '32',
        label: '32 years',
    },
    {
        value: '33',
        label: '33 years',
    },
    {
        value: '34',
        label: '34 years',
    },
    {
        value: '35',
        label: '35 Years',
    },
    {
        value: '36',
        label: '36 Years',
    },
    {
        value: '37',
        label: '37 Years',
    },
    {
        value: '38',
        label: '38 Years',
    },
    {
        value: '39',
        label: '39 Years',
    },
    {
        value: '40',
        label: '40 Years',
    },
    {
        value: '41',
        label: '41 Years',
    },
    {
        value: '42',
        label: '42 Years',
    },
    {
        value: '43',
        label: '43 Years',
    },
    {
        value: '44',
        label: '44 Years',
    },
    {
        value: '45',
        label: '45 Years',
    },
    {
        value: '46',
        label: '46 Years',
    },
    {
        value: '47',
        label: '47 Years',
    },
    {
        value: '48',
        label: '48 Years',
    },
    {
        value: '49',
        label: '49 Years',
    },
    {
        value: '50',
        label: '50 Years',
    },
    {
        value: '51',
        label: '51 Years',
    },
    {
        value: '52',
        label: '52 Years',
    },
    {
        value: '53',
        label: '53 Years',
    },
    {
        value: '54',
        label: '54 Years',
    },
    {
        value: '55',
        label: '55 Years',
    },
    {
        value: '56',
        label: '56 Years',
    },
    {
        value: '57',
        label: '57 Years',
    },
    {
        value: '58',
        label: '58 Years',
    },
    {
        value: '59',
        label: '59 Years',
    },
    {
        value: '60',
        label: '60 Years',
    },

];
const genderddl = [
    {
        value: 'male',
        label: 'Male',
    },
    {
        value: 'female',
        label: 'Female',
    },
    {
        value: 'others',
        label: 'Others',
    },
];
const mstatusddl = [
    {
        value: 'single',
        label: 'Single',
    },
    {
        value: 'married',
        label: 'Married',
    },
    {
        value: 'divorced',
        label: 'Divorced',
    },
    {
        value: 'others',
        label: 'Others',
    },
];
const abledddl = [
    {
        value: 'yes',
        label: 'Yes',
    },
    {
        value: 'no',
        label: 'No',
    },
];



const JobsViewCell = ({ itemIn }) => {
    const validationSchema = yup.object({
        name: yup
            .string('Enter your name')
            .required('Name is required'),
        totalexp: yup
            .string('Select Total Experience')
            .required('Total experience is required'),
        age: yup
            .string('Select Age')
            .required('Age is required'),
        gender: yup
            .string('Select Gender')
            .required('Gender is required'),
        hiquli: yup
            .string('Select qulification')
            .required('Highest educational is required'),
        ccompany: yup
            .string('Select Company')
            .required('Current company is required'),
        cdesignation: yup
            .string('Select Designation')
            .required('Current designation is required'),
        clocation: yup
            .string('Select Location')
            .required('Current location is required'),
        ctc: yup
            .string('Select CTC')
            .required('Current CTC is required'),
        ectc: yup
            .string('Select CTC')
            .notRequired(''),
        mstatus: yup
            .string('Select Marital Status')
            .required('Marital status is required'),
        abled: yup
            .string('Select Differently Abled')
            .notRequired(''),
        mobile: yup
            .string('')
            .required()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Enter 10 digit mobile number')
            .max(10, 'Enter 10 digit mobile number'),
        email: yup
            .string('Enter your email')
            .required('Email is required')
            .email('Enter a valid email id'),
    });
    const [susMessage, setSusMessage] = useState('');
    const [errMessage, setErrMessage] = useState('');
    const [loading, setLoading] = React.useState(false);
    const [openEnq, setopenEnq] = useState(false);
    const handleOpenEnq = () => setopenEnq(true);
    const handleCloseEnq = () => setopenEnq(false);
    const [openJob, setOpenJob] = useState(false);
    const handleOpenJob = () => setOpenJob(true);
    const handleCloseJob = () => setOpenJob(false);

    const [file, setFileState] = React.useState(null);
    const userAttachment = e => {
        if (validateFileSize(e)) {
           // console.log(e.target.files[0]);
            setFileState(e.target.files[0]);
        }
    };
    const [err, seterr] = React.useState("");
    const validateFileSize = (e) => {
        let file = e.target.files[0];
        let size = 2000000;
        if (file.size > size) {
            setErrMessage('File To large');
            return false
        }
        setErrMessage('');
        return true
    };

   // console.log('file', file)
    const enabledbtn = file;

    const formik = useFormik({
        initialValues: {
            name: '',
            totalexp: '',
            age: '',
            gender: '',
            hiquli: '',
            ccompany: '',
            cdesignation: '',
            clocation: '',
            ctc: '',
            ectc: '',
            mstatus: '',
            abled: '',
            mobile: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            var data = new FormData();
            data.append('name', values.name);
            data.append('email', values.email);
            data.append('mobile', values.mobile);
            data.append('currentJobLocation', values.clocation);
            data.append('experience', values.totalexp);
            data.append('age', values.age);
            data.append('gender', values.gender);
            data.append('heighestEducationQualification', values.hiquli);
            data.append('currentCompany', values.ccompany);
            data.append('currentDesignation', values.cdesignation);
            data.append('currentCtc', values.ctc);
            data.append('expectedCtc', values.ectc);
            data.append('maritialStatus', values.mstatus);
            data.append('differentlyAbled', values.abled); 
            data.append('uploadCv', file);
            data.append('jobId', itemIn.jobId);

            // for (var pair of data.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            setLoading(true);
            setErrMessage('');
            setSusMessage('');
            jobApply(data).then((res) => {
           // console.log('resres', res.data);
            setLoading(false);
            switch (res.data.status) {
                case 1:
                    setSusMessage(res.data.msz);
                    setErrMessage('');
                    return true;
                case 0:
                    setErrMessage(res.data.msz);
                    break;
                default:
                    return;
            }
            });
        },
    });

    return (
        <>
            <div className='card rounded-0 shadow-sm p-3 mb-3'>
                <div className='row'>
                    <div className='col-lg-9 col-12'>
                        <div className='row'>
                            <div className="col-sm-4 col-12 mb-2">
                                <label className="f12 d-block text-muted">Designation</label>
                                <label className="f14 d-block">{itemIn?.designation}</label>
                            </div>
                            <div className="col-sm-4 col-12 mb-2">
                                <label className="f12 d-block text-muted">Department</label>
                                <label className="f14 d-block">{itemIn?.department}</label>
                            </div>
                            <div className="col-sm-4 col-12 mb-2">
                                <label className="f12 d-block text-muted">Location</label>
                                <label className="f14 d-block">{itemIn?.location}</label>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-12 text-end'>
                        <Button variant="outlined"
                            // onClick={goBack} 
                            onClick={handleOpenEnq}
                            size="small" className='mt-2'>
                            Job Details
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleOpenJob}
                            color='error'
                            size="small" className='ms-3 mt-2 flex-grow-1'>
                            Apply Now
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                open={openEnq}
                // onClose={handleCloseProfile}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, width: { xs: 300, sm: 700 },   height: { xs: '100%', sm: 'auto' }, }}>
                    <IconButton
                        onClick={handleCloseEnq}
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ float: 'right', position: 'absolute', right: '20px', top: '20px', zIndex: '3' }}
                    >
                        <CancelIcon />
                    </IconButton>
                    <div>
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>JOB</span> <span className='txtdgray'>DETAILS</span>
                                </h4>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-4 col-12 mb-2">
                                <label className="f12 d-block text-muted">Designation</label>
                                <label className="f14 d-block">{itemIn?.designation}</label>
                            </div>
                            <div className="col-sm-4 col-12 mb-2">
                                <label className="f12 d-block text-muted">Department</label>
                                <label className="f14 d-block">{itemIn?.department}</label>
                            </div>
                            <div className="col-sm-4 col-12 mb-2">
                                <label className="f12 d-block text-muted">Location</label>
                                <label className="f14 d-block">{itemIn?.location}</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-12 col-12 mb-2">
                                <hr />
                                <label className="d-block fw-bold mb-2">Description</label>
                                <label className="f14 d-block">{itemIn?.jobDesc}</label>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openJob}
                // onClose={handleCloseProfile}
                aria-labelledby=""
                aria-describedby=""
            >
                <Box sx={{...style, width: { xs: 300, sm: 700 },   height: { xs: '100%', sm: 'auto' }, }}>
                    <IconButton
                        onClick={handleCloseJob}
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ float: 'right', position: 'absolute', right: '20px', top: '20px', zIndex: '3' }}
                    >
                        <CancelIcon />
                    </IconButton>
                    <div>
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>JOB</span> <span className='txtdgray'>APPLY</span>
                                </h4>
                            </div>
                        </div>
                        {errMessage?<><Alert severity="error">{errMessage}</Alert></>:null}
                        {susMessage?<><Alert severity="success">{susMessage}</Alert></>:null}
                        <form onSubmit={formik.handleSubmit} autoComplete="off">
                            <div className='row mt-3'>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        className='mb-3 w-100'
                                        variant="standard"
                                        id="name"
                                        name="name"
                                        label="Your Name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        id="totalexp"
                                        className='mb-3 w-100'
                                        select
                                        label="Total Experience"
                                        name="totalexp"
                                        variant="standard"
                                        value={formik.values.totalexp}
                                        onChange={formik.handleChange}
                                        error={formik.touched.totalexp && Boolean(formik.errors.totalexp)}
                                        helperText={formik.touched.totalexp && formik.errors.totalexp}
                                    >
                                        {expddl.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        id="age"
                                        className='mb-3 w-100'
                                        select
                                        label="Age"
                                        name="age"
                                        variant="standard"
                                        value={formik.values.age}
                                        onChange={formik.handleChange}
                                        error={formik.touched.age && Boolean(formik.errors.age)}
                                        helperText={formik.touched.age && formik.errors.age}
                                    >
                                        {ageddl.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        id="gender"
                                        className='mb-3 w-100'
                                        select
                                        label="Gender"
                                        name="gender"
                                        variant="standard"
                                        value={formik.values.gender}
                                        onChange={formik.handleChange}
                                        error={formik.touched.gender && Boolean(formik.errors.gender)}
                                        helperText={formik.touched.gender && formik.errors.gender}
                                    >
                                        {genderddl.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        className='mb-3 w-100'
                                        variant="standard"
                                        id="hiquli"
                                        name="hiquli"
                                        label="Highest Educational Qualification"
                                        value={formik.values.hiquli}
                                        onChange={formik.handleChange}
                                        error={formik.touched.hiquli && Boolean(formik.errors.hiquli)}
                                        helperText={formik.touched.hiquli && formik.errors.hiquli}
                                    />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        className='mb-3 w-100'
                                        variant="standard"
                                        id="ccompany"
                                        name="ccompany"
                                        label="Current Company"
                                        value={formik.values.ccompany}
                                        onChange={formik.handleChange}
                                        error={formik.touched.ccompany && Boolean(formik.errors.ccompany)}
                                        helperText={formik.touched.ccompany && formik.errors.ccompany}
                                    />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        className='mb-3 w-100'
                                        variant="standard"
                                        id="cdesignation"
                                        name="cdesignation"
                                        label="Current Designation"
                                        value={formik.values.cdesignation}
                                        onChange={formik.handleChange}
                                        error={formik.touched.cdesignation && Boolean(formik.errors.cdesignation)}
                                        helperText={formik.touched.cdesignation && formik.errors.cdesignation}
                                    />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        className='mb-3 w-100'
                                        variant="standard"
                                        id="clocation"
                                        name="clocation"
                                        label="Current Location"
                                        value={formik.values.clocation}
                                        onChange={formik.handleChange}
                                        error={formik.touched.clocation && Boolean(formik.errors.clocation)}
                                        helperText={formik.touched.clocation && formik.errors.clocation}
                                    />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        className='mb-3 w-100'
                                        variant="standard"
                                        id="ctc"
                                        name="ctc"
                                        label="Current CTC"
                                        value={formik.values.ctc}
                                        onChange={formik.handleChange}
                                        error={formik.touched.ctc && Boolean(formik.errors.ctc)}
                                        helperText={formik.touched.ctc && formik.errors.ctc}
                                    />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        className='mb-3 w-100'
                                        variant="standard"
                                        id="ectc"
                                        name="ectc"
                                        label="Expected CTC"
                                        value={formik.values.ectc}
                                        onChange={formik.handleChange}
                                        error={formik.touched.ectc && Boolean(formik.errors.ectc)}
                                        helperText={formik.touched.ectc && formik.errors.ectc}
                                    />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        id="mstatus"
                                        className='mb-3 w-100'
                                        select
                                        label="Marital Status"
                                        name="mstatus"
                                        variant="standard"
                                        value={formik.values.mstatus}
                                        onChange={formik.handleChange}
                                        error={formik.touched.mstatus && Boolean(formik.errors.mstatus)}
                                        helperText={formik.touched.mstatus && formik.errors.mstatus}
                                    >
                                        {mstatusddl.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        id="abled"
                                        className='mb-3 w-100'
                                        select
                                        label="Differently Abled"
                                        name="abled"
                                        variant="standard"
                                        value={formik.values.abled}
                                        onChange={formik.handleChange}
                                        error={formik.touched.abled && Boolean(formik.errors.abled)}
                                        helperText={formik.touched.abled && formik.errors.abled}
                                    >
                                        {abledddl.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        variant="standard"
                                        className='mb-3 w-100'
                                        label="Mobile Number"
                                        inputProps={{
                                            maxLength: 10
                                        }}
                                        id="mobile"
                                        name="mobile"
                                        value={formik.values.mobile}
                                        onChange={formik.handleChange}
                                        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                        helperText={formik.touched.mobile && formik.errors.mobile}
                                    />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <TextField
                                        required
                                        className='mb-3 w-100'
                                        variant="standard"
                                        id="email"
                                        name="email"
                                        label="Email ID"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <div>Upload CV * - <span className='f12'>(max size: 2MB)</span></div>
                                    <input
                                        accept=".docx,.doc,image/jpeg,image/png,application/pdf"
                                        type="file"
                                        onChange={userAttachment}
                                        className="mb-3 w-100 mt-2"
                                        defaultValue=""
                                    />
                                </div>
                                <div className='col-12 col-lg-12 mt-2'>
                                    {!loading ? <Button color="error" disabled={!enabledbtn} variant="contained" className='w-100' type="submit">
                                        SUBMIT
                                    </Button> : <LoadingButton className="w-100"
                                        loading
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        SUBMIT...
                                    </LoadingButton>}
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default JobsViewCell