import React from 'react'

const UserIconsm = () => {
  return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M20 20h-2c0-4.4-3.6-8-8-8s-8 3.6-8 8H0c0-4.2 2.6-7.8 6.3-9.3C4.9 9.6 4 7.9 4 6c0-3.3 2.7-6 6-6s6 2.7 6 6c0 1.9-.9 3.6-2.3 4.7 3.7 1.5 6.3 5.1 6.3 9.3zM14 6c0-2.2-1.8-4-4-4S6 3.8 6 6s1.8 4 4 4 4-1.8 4-4z"></path></svg>
    </>
  )
}

export default UserIconsm