import React from 'react'
import { Link } from 'react-router-dom'

const EventBoxCel = () => {
  return (
    <>
    <div className="post-card">
        <div className="post-card__image"><Link to="#">
            <img alt="" src="https://www.hondaindiapower.com//front/media/events/24.jpg" /></Link></div>
        <div className="post-card__content">
            <div className="post-card__category"><span className='txtlgeay'>28 February, 2022</span></div>
            <div className="post-card__title">
                <h2><Link to="#"  className='txtdgray'>Shareholders Meet</Link></h2>
            </div>
            <div className="post-card__date f12 mt-2"><Link to="#" className='txtred'>READ MORE</Link></div>
        </div>
    </div>
    </>
  )
}

export default EventBoxCel