import React, { useEffect } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-router-dom";
import { getCategoryList } from '../../networkCalls';

const promise = getCategoryList();
const LeftMenuHeader = () => {
    const [catlist, setCatlist] = React.useState();
    useEffect(() => {
        promise.then(data => {
            //console.log('dateee',data?.CategoryListArray)
            setCatlist(data?.CategoryListArray);
        });
    }, [catlist]);
    return (
        <>
            <Navbar collapseOnSelect expand="xs" className="m-0 position-static" >
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" className='m-0' /> */}
                <Navbar.Collapse id="responsive-navbar-nav" className='navbar-collapse collapse show'>
                    <Nav className="me-auto f16">
                        <Link className="pt-1 pe-1 nav-link txtdgray" to="/" ><HomeIcon className='txtred' /></Link>
                        <Link className="nav-link txtdgray" to="/about-us" >About Us</Link>
                        <NavDropdown title="Buy Online" id="collasible-nav-dropdown" className='maga-menu position-static'>
                            <div className="">
                                <div className="row bg-white rounded-0 m-0">
                                    {/* <div className="col-lg-9 col-xl-9"> */}
                                    <div className="col-lg-12">
                                        <div className="row">
                                            {catlist && catlist.length > 0 && catlist.map((data, key) => (
                                                <>
                                                    <Link to={`/product-category/${data?.slug}`} key={key}>
                                                        <div className="col-lg-12 mb-2" >
                                                            <div className='card'>
                                                                <div className='p-2'>
                                                                    <img src={data?.image} style={{ width: '50px' }} alt={data?.name} />
                                                                    <span className='text-black'>{data?.name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-xl-3 px-0 d-none d-lg-block megaimaga"></div>
                                </div>
                            </div>
                        </NavDropdown>
                        <NavDropdown title="Investors" id="investor-nav-dropdown" className='maga-menu position-static'>
                            <div className="">
                                <div className="row bg-white rounded-0 m-0">
                                    {/* <div className="col-lg-9 col-xl-9"> */}
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-12 mb-2">
                                                <Link to="/investors/financials" className="dropdown-item subleftmenu">Financials</Link>
                                                <Link to="/investors/stock-information" className="dropdown-item subleftmenu">Stock Information</Link>
                                                <Link to="/investors/meetings" className="dropdown-item subleftmenu">Meetings</Link>
                                                <Link to="/investors/dividend-and-IEPF" className="dropdown-item subleftmenu">Dividend and IEPF</Link>
                                                <Link to="/investors/corporate-governance" className="dropdown-item subleftmenu">Corporate Governance</Link>
                                                <Link to="/investors/investor-service-request" className="dropdown-item subleftmenu">Investor Service Request</Link>
                                                <Link to="/investors/investors-contact" className="dropdown-item subleftmenu">Investors Contact</Link>
                                                <Link to="/investors/reports" className="dropdown-item subleftmenu">Reports</Link>
                                                <Link to="/investors/related-party-transactions" className="dropdown-item subleftmenu">Related Party Transactions</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-xl-3 px-0 d-none d-lg-block megaimaga"></div>
                                </div>
                            </div>
                        </NavDropdown>
                        <NavDropdown title="Customer Care" id="Customer-nav-dropdown" className='maga-menu position-static'>
                            <div className="">
                                <div className="row bg-white rounded-0 m-0">
                                    {/* <div className="col-lg-9 col-xl-9"> */}
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-12 mb-2">
                                                <a href="https://www.hondappsv.com/IND/" target="_blank" className="dropdown-item subleftmenu">Maintenance & Care Information</a>
                                                <Link to="/extended-warranty" className="dropdown-item subleftmenu">Extended warranty</Link>
                                                <Link to="/honda-genuine-oil" className="dropdown-item subleftmenu">Honda Genuine Oil</Link>
                                                <Link to="/service-network" className="dropdown-item subleftmenu">Service Network</Link>
                                                <Link to="/calculator" className="dropdown-item subleftmenu">Model Calculator</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-xl-3 px-0 d-none d-lg-block megaimaga"></div>
                                </div>
                            </div>
                        </NavDropdown>
                        <Link className="nav-link txtdgray" to="/exports" >Exports</Link>
                        <Link className="nav-link txtdgray" to="/press-release" >Press Release</Link>
                        <Link className="nav-link txtdgray" to="/contact-us">Contact Us</Link>
                        {/* <NavDropdown title="Jobs" id="collasible-nav-dropdown">
                            <Link to="#" className="dropdown-item">Link 1</Link>
                            <Link to="#" className="dropdown-item">Link 1</Link>
                        </NavDropdown> */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default LeftMenuHeader