import React from 'react'
import FotterMain from '../BaseComponents/FotterMain'
import Header from '../BaseComponents/Header'

const InvestorsListing = () => {
  return (
    <>
    <Header />
    <div className='innerpagebg pt-5 pb-5'>
        <div className='container mb-4'>
          <div className='row'>
            <div className='col-12  m-auto'>
              <div className="align-items-center text-center">
                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                  <span className='txtred'>Investors</span> <span className='txtdgray'></span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
           API CALL DATA COME HERE
        </div>
    </div>
    <FotterMain />
    </>
  )
}

export default InvestorsListing