import axios from "axios";

export const googleCaptchaAPIKey= '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
export const baseURL= 'https://honda.wipl.co.in/'; 

const domain = "https://honda.wipl.co.in/SuperAdmin/";
export async function login(data) {
  return await axios.post(`${domain}api/customer-login`, data);
}
export async function loginVerifyOtp(data) {
  return await axios.post(`${domain}api/customer-login-otp--verification`, data);
}

export async function customerSignup(data) {
  return await axios.post(`${domain}api/customer-signup`, data);
}

export async function customerOTPVerify(data) {
  return await axios.post(`${domain}api/customer-otp--verification`, data);
}

export  async function getCustomerProfile (data) {
  return await axios.post(`${domain}api/get-customer-profile`, data);
}
export  async function updateCustomerProfile (data) {
  return await axios.post(`${domain}api/update-customer-profile`, data);
}

export  async function getCountryList () {
  return await axios.get(`${domain}api/GetCountryList`,);
}
export  async function getStateList (data) {
  return await axios.post(`${domain}api/GetStateList`, data);
}
export  async function getCityList (data) {
  return await axios.post(`${domain}api/GetCityList`, data);
}
export  async function getCustomerAddressList (data) {
  return await axios.post(`${domain}api/get-customer-address-list`, data);
}

export  async function saveCustomerAddress (data) {
  return await axios.post(`${domain}api/save-customer-address`, data);
}
export  async function updateCustomerAddress (data) {
  return await axios.post(`${domain}api/update-customer-address`, data);
}
export  async function sendEmailVerification (data) {
  return await axios.post(`${domain}api/send-email-verification`, data);
}
export  async function emailOTPVerification (data) {
  return await axios.post(`${domain}api/email-otp-verification`, data);
}
export  async function deleteCustomerAddress (data) {
  return await axios.post(`${domain}api/delete-customer-address`, data);
}
export  async function saveToWishList (data) {
  return await axios.post(`${domain}api/save-to-wishlist`, data);
}
export  async function getWishListData (data) {
  return await axios.post(`${domain}api/wishlist`, data);
}
export  async function getJobsList () {
  return await axios.get(`${domain}api/GetJobsList`);
}
export  async function jobApply (data) {
  return await axios.post(`${domain}api/JobApply`, data);
}
export  async function getDealerListByZipCode (data) {
  return await axios.post(`${domain}api/GetDealerListByZipCode`, data);
}






// export  async function getCategoryList () {
//   return await axios.get(`${domain}api/GetCategoryList`,);
// }
export async function getCategoryList () {
  let categoryData = '';
   await axios.get(`${domain}api/GetCategoryList`).then((res) => {
   switch (res.data.status) {
       case 1:
          categoryData = (res.data);
       case 0:
           break;
       default:
         return;
   }
 })
 return categoryData;
}











