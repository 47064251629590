import React from 'react'
import { Link } from 'react-router-dom'

import FotterMain from '../BaseComponents/FotterMain'
import Header from '../BaseComponents/Header'

const PressReleaseListing = () => {
  return (
    <>
      <Header />
      <div className='innerpagebg pt-5 pb-5'>
        <div className='container mb-4'>
          <div className='row'>
            <div className='col-12  m-auto'>
              <div className="align-items-center text-center">
                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                  <span className='txtred'>Press</span> <span className='txtdgray'>Release</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className="post-card mb-3">
                <div className="post-card__image"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022">
                  <img alt="" className='img-fluid' src="https://www.hondaindiapower.com/front/media/production_milestone_img01.png" /></Link></div>
                <div className="post-card__content">
                  <div className="post-card__category"><span className='txtlgeay'>28 February, 2022</span></div>
                  <div className="post-card__title mb-2">
                    <h2 ><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtdgray'>Honda India Power Products Limited reaches 5 Million Units Production Milestone in February 2022</Link></h2>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis neque ut purus fermentum, ac pretium nibh facilisis. Vivamus venenatis viverra iaculis.</p>
                  <div className="post-card__date f12 mt-2"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtred'>READ MORE</Link></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className="post-card mb-3">
                <div className="post-card__image"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022">
                  <img alt="" className='img-fluid' src="https://www.hondaindiapower.com/front/media/production_milestone_img01.png" /></Link></div>
                <div className="post-card__content">
                  <div className="post-card__category"><span className='txtlgeay'>28 February, 2022</span></div>
                  <div className="post-card__title mb-2">
                    <h2 ><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtdgray'>Honda India Power Products Limited reaches 5 Million Units Production Milestone in February 2022</Link></h2>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis neque ut purus fermentum, ac pretium nibh facilisis. Vivamus venenatis viverra iaculis.</p>
                  <div className="post-card__date f12 mt-2"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtred'>READ MORE</Link></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className="post-card mb-3">
                <div className="post-card__image"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022">
                  <img alt="" className='img-fluid' src="https://www.hondaindiapower.com/front/media/production_milestone_img01.png" /></Link></div>
                <div className="post-card__content">
                  <div className="post-card__category"><span className='txtlgeay'>28 February, 2022</span></div>
                  <div className="post-card__title mb-2">
                    <h2 ><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtdgray'>Honda India Power Products Limited reaches 5 Million Units Production Milestone in February 2022</Link></h2>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis neque ut purus fermentum, ac pretium nibh facilisis. Vivamus venenatis viverra iaculis.</p>
                  <div className="post-card__date f12 mt-2"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtred'>READ MORE</Link></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className="post-card mb-3">
                <div className="post-card__image"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022">
                  <img alt="" className='img-fluid' src="https://www.hondaindiapower.com/front/media/production_milestone_img01.png" /></Link></div>
                <div className="post-card__content">
                  <div className="post-card__category"><span className='txtlgeay'>28 February, 2022</span></div>
                  <div className="post-card__title mb-2">
                    <h2 ><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtdgray'>Honda India Power Products Limited reaches 5 Million Units Production Milestone in February 2022</Link></h2>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis neque ut purus fermentum, ac pretium nibh facilisis. Vivamus venenatis viverra iaculis.</p>
                  <div className="post-card__date f12 mt-2"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtred'>READ MORE</Link></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className="post-card mb-3">
                <div className="post-card__image"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022">
                  <img alt="" className='img-fluid' src="https://www.hondaindiapower.com/front/media/production_milestone_img01.png" /></Link></div>
                <div className="post-card__content">
                  <div className="post-card__category"><span className='txtlgeay'>28 February, 2022</span></div>
                  <div className="post-card__title mb-2">
                    <h2 ><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtdgray'>Honda India Power Products Limited reaches 5 Million Units Production Milestone in February 2022</Link></h2>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis neque ut purus fermentum, ac pretium nibh facilisis. Vivamus venenatis viverra iaculis.</p>
                  <div className="post-card__date f12 mt-2"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtred'>READ MORE</Link></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className="post-card mb-3">
                <div className="post-card__image"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022">
                  <img alt="" className='img-fluid' src="https://www.hondaindiapower.com/front/media/production_milestone_img01.png" /></Link></div>
                <div className="post-card__content">
                  <div className="post-card__category"><span className='txtlgeay'>28 February, 2022</span></div>
                  <div className="post-card__title mb-2">
                    <h2 ><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtdgray'>Honda India Power Products Limited reaches 5 Million Units Production Milestone in February 2022</Link></h2>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis neque ut purus fermentum, ac pretium nibh facilisis. Vivamus venenatis viverra iaculis.</p>
                  <div className="post-card__date f12 mt-2"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtred'>READ MORE</Link></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FotterMain />
    </>
  )
}

export default PressReleaseListing