import React, { useEffect, useState, Suspense } from 'react'
import Header from "./BaseComponents/Header";
import FotterMain from "./BaseComponents/FotterMain";
import CategoryDisplayCel from "./BaseComponents/HomeCels/CategoryDisplayCel";
import SectionTitle from "./BaseComponents/SectionTitle";
import { getCategoryList } from "../networkCalls";

import AboutUSCel from "./BaseComponents/HomeCels/AboutUSCel";
import EnqueyCel from "./BaseComponents/HomeCels/EnqueyCel";
import ExploreMore from "./BaseComponents/HomeCels/ExploreMore";
import FindDealerCel from "./BaseComponents/HomeCels/FindDealerCel";
import MainSlider from "./BaseComponents/HomeCels/MainSlider";
import WhyChoseUsCel from "./BaseComponents/HomeCels/WhyChoseUsCel";
import LoadingCellFullPage from './LoadingCell/LoadingCellFullPage';

// const MainSlider = React.lazy(() => import("./BaseComponents/HomeCels/MainSlider"));
// const AboutUSCel = React.lazy(() => import("./BaseComponents/HomeCels/AboutUSCel.js"));
// const WhyChoseUsCel = React.lazy(() => import("./BaseComponents/HomeCels/WhyChoseUsCel.js"));
// const EnqueyCel = React.lazy(() => import("./BaseComponents/HomeCels/EnqueyCel.js"));
// const FindDealerCel = React.lazy(() => import("./BaseComponents/HomeCels/FindDealerCel.js"));
// const ExploreMore = React.lazy(() => import("./BaseComponents/HomeCels/ExploreMore.js"));

const promise = getCategoryList();
const Home = () => {
    const [catlist, setCatlist] = React.useState();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        promise.then(data => {
            //console.log('dateee',data?.CategoryListArray)
            setCatlist(data?.CategoryListArray);
            setLoading(false);
        });
    }, [catlist]);

    return (
        <>
            {/* {isLoading?<><LoadingCellFullPage /></>:<></>} */}
            <Header />
            <Suspense fallback={<div></div>}>
                <MainSlider />
            </Suspense>
            <div className="mt-5"></div>
            <SectionTitle />
            {catlist && catlist.length > 0 ? <CategoryDisplayCel catlist={catlist} /> : null}
            <div className="mt-5"></div>
            <Suspense fallback={<div></div>}>
                <AboutUSCel />
            </Suspense>
            {/* <AboutUSCel /> */}
            <div className="mt-5"></div>
            <Suspense fallback={<div></div>}>
                <WhyChoseUsCel />
            </Suspense>
            <div className="mt-5"></div>
            <div className='bg-white'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <Suspense fallback={<div></div>}>
                                <EnqueyCel />
                            </Suspense>
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <Suspense fallback={<div></div>}>
                                <FindDealerCel />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5"></div>
            <Suspense fallback={<div></div>}>
                <ExploreMore />
            </Suspense>
            <FotterMain />
        </>
    );
}
export default Home;