import React from 'react'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CompareIcon from '@mui/icons-material/Compare';
import { actionTypes, useStateValue } from "./../../store";

const BtnCompare = ({ pid, pname, btntype, pimage, entryText }) => {
    const [{ token, huid }, dispatch] = useStateValue();
    const handleCompareProduct = () => {
        var existingEntries = JSON.parse(localStorage.getItem("allEntries"));
        if (existingEntries == null) existingEntries = [];
        var entry = {
            "id": pid,
            "title": pname,
            "text": entryText,
            "img": pimage,
        };
        var dataMatch = existingEntries.map(t => t.id);
        if (existingEntries.length > 3) {
            dispatch({ type: actionTypes.SET_MSGALERTTYPE, value: 'error' });
            dispatch({ type: actionTypes.SET_MSGALERTDATA, value: 'You have already selected 4 products' });
            dispatch({ type: actionTypes.SET_MSGALERT, value: true });
        } else if (dataMatch.includes(pid)) {
            dispatch({ type: actionTypes.SET_MSGALERTTYPE, value: 'error' });
            dispatch({ type: actionTypes.SET_MSGALERTDATA, value: `${pname} alreay in comparison list` });
            dispatch({ type: actionTypes.SET_MSGALERT, value: true });
        } else {
            existingEntries.push(entry);
            localStorage.setItem("allEntries", JSON.stringify(existingEntries));
            dispatch({ type: actionTypes.SET_MSGALERTTYPE, value: 'success' });
            dispatch({ type: actionTypes.SET_MSGALERTDATA, value: `You added ${pname} to the comparison list` });
            dispatch({ type: actionTypes.SET_MSGALERT, value: true });
            dispatch({ type: actionTypes.SET_COMPAREITEMCOUNT, value: existingEntries.length });
        }
    }
    return (
        <>
        {btntype=='icon'?<>
        <IconButton size="small" className='mt-2 txtlgeay' edge="end" onClick={() => handleCompareProduct(pid)} > <CompareIcon fontSize="small" /></IconButton>
        </>:<>
        <Button variant="outlined" onClick={() => handleCompareProduct(pid)} color="primary" startIcon={<CompareIcon />} type="submit" size="small" className='w-100 text-capitalize'>Add compare</Button>
        </>}
        </>
    )
}

export default BtnCompare