import React from 'react'
import Button from '@mui/material/Button';
import { useParams, useNavigate, Link } from "react-router-dom";
import FotterMain from '../BaseComponents/FotterMain';
import Header from '../BaseComponents/Header';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SectionTitle from '../BaseComponents/SectionTitle';

const Page = () => {

    const navigate = useNavigate();

    const { investorURL } = useParams();
    console.log('investorURL---', investorURL)


    return (
        <>
            <Header />

            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-left m-auto'>
                            <div className="d-flex align-items-center">
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>Heading</span> <span className='txtdgray'>{investorURL}</span>
                                </h4>
                                <div className="ms-auto">
                                    <Button variant="text" onClick={() => navigate(-1)} size="medium" className='d-block mt-3'>
                                        BACK
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card mb-3 p-3 bg-white shadow-sm'>
                                <div className='d-flex'>
                                    <div><PictureAsPdfIcon /></div>
                                    <div className='ms-3'>
                                        <h5 className='f14 text-capitalize'>{investorURL} December 31, 2021</h5>
                                        <div className='d-inline-block position-relative' >
                                            <a href="https://www.hondaindiapower.com/uploads/pdf/19/shareholding_pattern.pdf" className=" ">
                                                <div class="tiltbgred ps-3 pe-3 f12 position-relative text-white" style={{ zIndex: '1' }}>Download</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card mb-3 p-3 bg-white shadow-sm'>
                                <div className='d-flex'>
                                    <div><PictureAsPdfIcon /></div>
                                    <div className='ms-3'>
                                        <h5 className='f14 text-capitalize'>{investorURL} September 30, 2021</h5>
                                        <div className='d-inline-block position-relative' >
                                            <a href="https://www.hondaindiapower.com/uploads/pdf/19/shareholding_pattern.pdf" className=" ">
                                                <div class="tiltbgred ps-3 pe-3 f12 position-relative text-white" style={{ zIndex: '1' }}>Download</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card mb-3 p-3 bg-white shadow-sm'>
                                <div className='d-flex'>
                                    <div><PictureAsPdfIcon /></div>
                                    <div className='ms-3'>
                                        <h5 className='f14 text-capitalize'>{investorURL} June 30, 2021</h5>
                                        <div className='d-inline-block position-relative' >
                                            <a href="https://www.hondaindiapower.com/uploads/pdf/19/shareholding_pattern.pdf" className=" ">
                                                <div class="tiltbgred ps-3 pe-3 f12 position-relative text-white" style={{ zIndex: '1' }}>Download</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card mb-3 p-3 bg-white shadow-sm'>
                                <div className='d-flex'>
                                    <div><PictureAsPdfIcon /></div>
                                    <div className='ms-3'>
                                        <h5 className='f14 text-capitalize'>{investorURL} March 31, 2021</h5>
                                        <div className='d-inline-block position-relative' >
                                            <a href="https://www.hondaindiapower.com/uploads/pdf/19/shareholding_pattern.pdf" className=" ">
                                                <div class="tiltbgred ps-3 pe-3 f12 position-relative text-white" style={{ zIndex: '1' }}>Download</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card mb-3 p-3 bg-white shadow-sm'>
                                <div className='d-flex'>
                                    <div><PictureAsPdfIcon /></div>
                                    <div className='ms-3'>
                                        <h5 className='f14 text-capitalize'>{investorURL} December 31, 2020</h5>
                                        <div className='d-inline-block position-relative' >
                                            <a href="https://www.hondaindiapower.com/uploads/pdf/19/shareholding_pattern.pdf" className=" ">
                                                <div class="tiltbgred ps-3 pe-3 f12 position-relative text-white" style={{ zIndex: '1' }}>Download</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card mb-3 p-3 bg-white shadow-sm'>
                                <div className='d-flex'>
                                    <div><PictureAsPdfIcon /></div>
                                    <div className='ms-3'>
                                        <h5 className='f14 text-capitalize'>{investorURL} September 30, 2020</h5>
                                        <div className='d-inline-block position-relative' >
                                            <a href="https://www.hondaindiapower.com/uploads/pdf/19/shareholding_pattern.pdf" className=" ">
                                                <div class="tiltbgred ps-3 pe-3 f12 position-relative text-white" style={{ zIndex: '1' }}>Download</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card mb-3 p-3 bg-white shadow-sm'>
                                <div className='d-flex'>
                                    <div><PictureAsPdfIcon /></div>
                                    <div className='ms-3'>
                                        <h5 className='f14 text-capitalize'>{investorURL} June 30, 2020</h5>
                                        <div className='d-inline-block position-relative' >
                                            <a href="https://www.hondaindiapower.com/uploads/pdf/19/shareholding_pattern.pdf" className=" ">
                                                <div class="tiltbgred ps-3 pe-3 f12 position-relative text-white" style={{ zIndex: '1' }}>Download</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card mb-3 p-3 bg-white shadow-sm'>
                                <div className='d-flex'>
                                    <div><PictureAsPdfIcon /></div>
                                    <div className='ms-3'>
                                        <h5 className='f14 text-capitalize'>{investorURL} March 31, 2020</h5>
                                        <div className='d-inline-block position-relative' >
                                            <a href="https://www.hondaindiapower.com/uploads/pdf/19/shareholding_pattern.pdf" className=" ">
                                                <div class="tiltbgred ps-3 pe-3 f12 position-relative text-white" style={{ zIndex: '1' }}>Download</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card mb-3 p-3 bg-white shadow-sm'>
                                <div className='d-flex'>
                                    <div><PictureAsPdfIcon /></div>
                                    <div className='ms-3'>
                                        <h5 className='f14 text-capitalize'>{investorURL} December 31, 2019</h5>
                                        <div className='d-inline-block position-relative' >
                                            <a href="https://www.hondaindiapower.com/uploads/pdf/19/shareholding_pattern.pdf" className=" ">
                                                <div class="tiltbgred ps-3 pe-3 f12 position-relative text-white" style={{ zIndex: '1' }}>Download</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <FotterMain />
        </>
    )
}

export default Page