import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import InputAdornment from '@mui/material/InputAdornment';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import LinesEllipsis from 'react-lines-ellipsis'
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useMediaQuery } from '@mui/material';
import { actionTypes, useStateValue } from "./../../store";
import FotterMain from '../BaseComponents/FotterMain';
import Header from '../BaseComponents/Header';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getDealerListByZipCode } from '../../networkCalls';

import BtnCompare from './BtnCompare';
import BtnWishList from './BtnWishList';
import { CheckBoxOutlineBlankRounded } from '@mui/icons-material';


const ProductDetail = () => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [{ token, huid, wishlistitemcount }, dispatch] = useStateValue();


    const { productID } = useParams();
    //  console.log('productID---', productID);

    const mediumViewport = useMediaQuery('(min-width:768px)');
    const [value, setValue] = React.useState('1');

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const [delivery, setDelivery] = useState('viapin');

    // geo location
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [status, setStatus] = useState(null)
    const getLocation = () => {
        // if (!navigator.geolocation) {
        //     setStatus('Geolocation is not supported by your browser');
        // } else {
        //     setStatus('Locating...');
        //     navigator.geolocation.getCurrentPosition((position) => {
        //         setStatus(null);
        //         setLat(position.coords.latitude);
        //         setLng(position.coords.longitude);
        //     }, () => {
        //         setStatus('Unable to retrieve your location');
        //     });
        // }
    }

    const deliveryChange = (event) => {
        setDelivery(event.target.value);
    };
    const btnmyaccount = () => {
        dispatch({ type: actionTypes.SET_LOGINMODAL, value: true });
    }

    // select delear
    const [selectDealer, setSelectDealer] = React.useState('');
    const [selecWhs, setSelecWhs] = React.useState('');
    const selectDealerChange = (value) => {
        setSelectDealer(value);
    };
    const selectwhsChange = (value) => {
        setSelecWhs(value);
    };
    // Check pin code
    const validationSchemaPIN = yup.object({
        pin: yup
            .string('Enter your Pincode')
            .min(6, 'Min 6 Digit')
            .max(6, 'Max 6 Digit')
            .matches(/^[0-9]+$/, "Must be only digits")
            .required('You must specify a 6 Digit Pincode'),
    });
    const [checkPin, setCheckPin] = useState(false)
    const [dealerData, setDealerData] = useState('')
    const formikpin = useFormik({
        initialValues: {
            pin: '',
        },
        validationSchema: validationSchemaPIN,
        onSubmit: (values) => {
            setCheckPin(true);
            setDealerData('');
            setSelectDealer('');
            setSelecWhs('');
            var data = new FormData();
            data.append('zipCode', values.pin);
            for (var pair of data.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            getDealerListByZipCode(data).then((res) => {
                console.log('red data', res.data);
                setCheckPin(false);
                switch (res.data.status) {
                    case 1:
                        setDealerData(res.data.dealerList);
                        return true;
                    case 0:
                        dispatch({ type: actionTypes.SET_MSGALERTTYPE, value: 'error' });
                        dispatch({ type: actionTypes.SET_MSGALERTDATA, value: res.data.msz });
                        dispatch({ type: actionTypes.SET_MSGALERT, value: true });
                        break;
                    default:
                        return;
                }
            });
        },
    });
    // end check pin code



    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    });
    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    };

    const settingsThumbs = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '10px'
    };

    const slidesData = [
        {
            id: 1,
            title: 'repellendus id ullam',
            label: 'Dolorem officiis temporibus.'
        }, {
            id: 2,
            title: 'excepturi consequatur est',
            label: 'Officia non provident dolor esse et neque.'
        }, {
            id: 3,
            title: 'eius doloribus blanditiis',
            label: 'Ut recusandae vel vitae molestiae id soluta.'
        }, {
            id: 4,
            title: 'nihil voluptates delectus',
            label: 'Qui vel consequatur recusandae illo repellendus.'
        }, {
            id: 5,
            title: 'nemo dolorem necessitatibus',
            label: 'Placeat odit velit itaque voluptatem.'
        }, {
            id: 6,
            title: 'dolorem quibusdam quasi',
            label: 'Adipisci officiis repudiandae.'
        },
    ];




    return (
        <>
            <Header />
            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='vh-100'>
                        <div className='w-100' >
                            <div className='w-100 w-lg-75 w-md-50' style={{ float: 'left' }}>
                                <div className='card mb-3'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-12 col-12'>
                                            {/* <div className='product_imf_wrap_details'>
                                                <img src="https://www.hondaindiapower.com/uploads/products/6/20XD_01.png" className='img-fluid' />
                                            </div> */}
                                            <div className="slider-wrapper">
                                                <Slider
                                                    {...settingsMain}
                                                    asNavFor={nav2}
                                                    ref={slider => (setSlider1(slider))}
                                                >
                                                    {slidesData.map((slide) =>
                                                        <div className="slick-slide" key={slide.id}>
                                                            {/* <h2 className="slick-slide-title">{slide.title}</h2> */}
                                                            {/* <img className="slick-slide-image" src={`https://picsum.photos/800/400?img=${slide.id}`} /> */}
                                                            <img className="slick-slide-image img-fluid" src={`https://www.hondaindiapower.com/uploads/products/6/20XD_01.png?img=${slide.id}`} />
                                                            {/* <label className="slick-slide-label">{slide.label}</label> */}
                                                        </div>
                                                    )}
                                                </Slider>
                                                <div className="thumbnail-slider-wrap mb-3">
                                                    <Slider
                                                        {...settingsThumbs}
                                                        asNavFor={nav1}
                                                        ref={slider => (setSlider2(slider))}>
                                                        {slidesData.map((slide) =>
                                                            <div className="slick-slide" key={slide.id}>
                                                                <img className="slick-slide-image border" src={`https://www.hondaindiapower.com/uploads/products/6/20XD_01.png?img=${slide.id}`} />
                                                                {/* <img className="slick-slide-image" src={`https://picsum.photos/800/400?img=${slide.id}`} /> */}
                                                            </div>
                                                        )}
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-12 col-12 ps-4 pe-4'>
                                            <div className='pro_info mt-4'>
                                                <h3 className='txtdgray mb-1 mt-4'>WB20XD</h3>
                                                <p className='txtred'>High Head Petrol Pumpset</p>
                                                <p>WB20XD, the 2 Inch category model - delivers water with the best in class performance at up</p>
                                            </div>
                                            <div className='offer_info'>
                                                <hr />
                                                <p className='fw-bold mb-1 mt-3'>Available offers:</p>
                                                <div><LocalOfferIcon className='f16 me-2 text-success' />Special PriceGet extra 5% off - (Coupon Code HAPY10)</div>
                                                <hr />
                                            </div>
                                            <div className='d-flex mt-4 align-items-center'>
                                                <div><img src="https://www.hondaindiapower.com/front/media/logo.png" className='border p-1 me-2' style={{ width: '100px' }} /></div>
                                                <div>1 Year Warranty on Product, 5 Years on body</div>
                                            </div>
                                            <div className='me-3 mt-3 table-responsive mb-3'>
                                                <div className="product-general-info">
                                                    <div className="single-general-info d-flex align-items-center">
                                                        <div style={{ width: '32px' }}>
                                                            <img src="https://demo.wipl.co.in/honda/no-charges.png" style={{ width: '24px' }} alt="" />
                                                        </div>
                                                        <div className="">
                                                            <span className='fw-bold'>High Head:</span> 32 Meters
                                                        </div>
                                                    </div>
                                                    <div className="single-general-info d-flex align-items-center">
                                                        <div style={{ width: '32px' }}>
                                                            <img src="https://demo.wipl.co.in/honda/no-charges.png" style={{ width: '24px' }} alt="" />
                                                        </div>
                                                        <div className="">
                                                            <span className='fw-bold'>Easy to carry:</span> 26 kg
                                                        </div>
                                                    </div>
                                                    <div className="single-general-info d-flex align-items-center">
                                                        <div style={{ width: '32px' }}>
                                                            <img src="https://demo.wipl.co.in/honda/no-charges.png" style={{ width: '24px' }} alt="" />
                                                        </div>
                                                        <div className="">
                                                            <span className='fw-bold'>Output discharge:</span> 670 Liters per minute
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100 w-lg-25 w-md-50' style={{ float: 'right' }}>
                                <div className='card mb-3 ms-lg-2'>
                                    <div className='product__info-body p-3 pt-4'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='product__price product__price--current'>
                                                <CurrencyRupeeIcon className='fw-bold f20' />7499.00 <span className='f16 fw-normal txtlgeay'><del><CurrencyRupeeIcon className='f14' />8000</del></span>
                                            </div>
                                            <div>
                                                <Chip color="success" label="In Stock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='product-form product__form p-3'>
                                        <FormControl className='w-100 mb-3'>
                                            <FormLabel id="delivery-by" className='f14'>Check Pincode</FormLabel>
                                            {/* <RadioGroup
                                                row
                                                aria-labelledby="delivery-by"
                                                name="delivery-by-buttons-group"
                                                value={delivery}
                                                onChange={deliveryChange}
                                            >
                                                <FormControlLabel value="viapin" control={<Radio size="small" />} label="Pincode" />

                                                <FormControlLabel value="vialoc" control={<Radio size="small" />} label="Location" />
                                            </RadioGroup> */}
                                        </FormControl>
                                        {delivery == 'viapin' ? <div className='position-relative'>
                                            <form onSubmit={formikpin.handleSubmit} autoComplete="off">
                                                <div className='d-flex'>
                                                    <div className='flex-grow-1'>
                                                        <TextField
                                                            className='mb-4 w-100'
                                                            variant="standard"
                                                            inputProps={{
                                                                maxLength: 6
                                                            }}
                                                            id="pin"
                                                            name="pin"
                                                            label=""
                                                            placeholder='Enter Delivery Pincode'
                                                            value={formikpin.values.pin}
                                                            onChange={formikpin.handleChange}
                                                            error={formikpin.touched.pin && Boolean(formikpin.errors.pin)}
                                                            helperText={formikpin.touched.pin && formikpin.errors.pin}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <LocationOnIcon className='txtlgeay' />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        {checkPin ? <><LoadingButton
                                                            //className='p-1'
                                                            loading
                                                            //loadingPosition="end"
                                                            variant="text"
                                                        >
                                                            CHECK...
                                                        </LoadingButton></> : <><Button variant="text" type="submit">CHECK</Button></>}
                                                    </div>
                                                </div>
                                                <div className='w-100 d-none'>
                                                    {token ? <>
                                                        <div className='p-2 f13 d-flex align-items-center justify-content-between'>
                                                            Select Saved Addresses<Link className='linkblue btn btn-sm p-0 pe-1 f12' to="#" >Add New</Link>
                                                        </div>
                                                        <div className='p-2'>
                                                            <div className='d-flex align-items-center pb-1'>
                                                                <span className=''>201309</span> <LinesEllipsis
                                                                    text='office 307, Wizard Infoways Pvt Ltd, The Ithum Tower - A-40 Sector 62'
                                                                    maxLine='1'
                                                                    ellipsis='...'
                                                                    trimRight
                                                                    basedOn='letters'
                                                                    className='txtlgeay ms-1'
                                                                />
                                                            </div>
                                                        </div>
                                                    </> : <>
                                                        <div className='p-2 f13 d-flex align-items-center'>
                                                            See your saved addresses <span className='linkblue btn btn-sm p-0 ps-1' onClick={btnmyaccount} >Login</span>
                                                        </div>
                                                    </>}
                                                </div>
                                            </form>
                                        </div> : <>
                                            {/* <div className='d-flex mb-3'>
                                                <div className='flex-grow-1 d-flex align-items-center' style={{ borderBottom: 'solid 1px #999' }}>
                                                    <GpsFixedIcon className='txtlgeay me-2' />
                                                    {lat}, {lng}
                                                </div>
                                                <div>
                                                    <Button variant="text" type="submit" onClick={() => getLocation()}>GET LOCATION</Button>
                                                </div>
                                            </div> */}
                                        </>}

                                        <div className='delear-wrap'>
                                            <FormControl sx={{ width: '100%' }}>
                                                {dealerData ? <><FormLabel id="dealer-label" className='mb-2'>Select Dealer</FormLabel></> : null}
                                                <div className='row'>
                                                    {dealerData && dealerData.length > 0 && dealerData.map((data, key) => (
                                                        <div key={key} className="col-12" onClick={() => { selectDealerChange(data?.dealerId); selectwhsChange(data?.whsId) }}>
                                                            <div className={`border mb-2 bg-white rounded d-flex ${selecWhs == data?.whsId ? 'border-success' : ''}`} >
                                                                <Radio
                                                                    checked={selecWhs == data?.whsId}
                                                                    value={data?.dealerId}
                                                                    size="small"
                                                                    name="radiodealer"
                                                                    className="float-start"
                                                                    inputProps={{ 'aria-label': 'A' }}
                                                                />
                                                                <div className="flex-grow-1">
                                                                    <div className='mt-2'>{data?.dealeName}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='p-3'>
                                            <Button variant="contained" type="submit" color="error" className='w-100'
                                            //  onClick={()=>getLocation()}
                                            >ADD TO CART</Button>
                                            <div className='mt-3 d-flex align-items-center'>
                                                <div className='w-50 pe-1'>
                                                    <BtnWishList pid={2} btntype={'withlbl'} />
                                                </div>
                                                <div className='w-50 ps-1'>
                                                    <BtnCompare pid={2} btntype={'withlbl'} pname={'WB20XD'} entryText={'High Head Petrol Pumpset'} pimage={'https://www.hondaindiapower.com/uploads/products/4/11.jpg'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100 w-lg-75' style={{ float: 'left' }}>
                                <div className='card mb-3'>
                                    <h3 className='p-3 mb-0 f16 fw-bold'>DESCRIPTION</h3>
                                    <hr className='mb-1 mt-0' />
                                    <div className='row'>
                                        <div className='col-sm-12 f14'>
                                            <div className='p-3'> <p>WB20XD, the 2 Inch category model - delivers water with the best in class performance at up to 32 meters head and 670 litres discharge volume per minute. While being so light at 24 Kg, it fulfills hilly region farmers' irrigation and ease of carrying needs. It is backed by Honda Advanced 4-Stroke Technology OHV Petrol Engine for superior Performance, Reliability and Portability.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card mb-3'>
                                    <h3 className='p-3 mb-0 f16 fw-bold'>SPECIFICATIONS</h3>
                                    <hr className='mb-1 mt-0' />
                                    <div className='row'>
                                        <div className='col-sm-12 f14'>
                                            <div className='p-3'>
                                                <div className='row'>
                                                    <div className='col-sm-12'></div>
                                                    <div className='col-sm-12'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className=''><img src="https://www.hondaindiapower.com/front/media/engine-icon.png" alt="" style={{ width: '50px' }} /></div>
                                                            <div className='ms-2'> <span className='txtred'>Engine</span> <span className='txtdgray'>Parameters</span> </div>
                                                        </div>
                                                        <table className='table table-sm table-striped table-hover f14'>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Type</td>
                                                                    <td>4-Stroke, Single Cylinder, Air cooled, Horizontal Shaft, Over Head Valve</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Displacement (cm3)</td>
                                                                    <td>163</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bore X Stroke (mm)</td>
                                                                    <td>68.0 X 45.0</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Fuel Type</td>
                                                                    <td>Petrol</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Rated Power (kW/rpm)</td>
                                                                    <td>2.9 kw / 3600 rpm</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Net Power (kW/rpm)</td>
                                                                    <td>3.6 kw / 3600 rpm</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Air Cleaner</td>
                                                                    <td>Dual Type</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Fuel Tank Capacity (L)</td>
                                                                    <td>3.1</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <hr />
                                                        <div className='d-flex align-items-center'>
                                                            <div className=''><img src="https://www.hondaindiapower.com/front/media/capacity.png" alt="" style={{ width: '50px' }} /></div>
                                                            <div className='ms-2'> <span className='txtred'>Pump</span> <span className='txtdgray'>Parameters</span> </div>
                                                        </div>
                                                        <table className='table table-sm table-striped table-hover f14'>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Type:</td>
                                                                    <td>Centrifugal Self Priming, Monoblock
                                                                        Horizontal with Mechanical Seal</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Suction Port Diameter (mm):</td>
                                                                    <td>50</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Discharge Port Diameter (mm):</td>
                                                                    <td>50</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dry Weight (kg):</td>
                                                                    <td>26</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Total Head (m):</td>
                                                                    <td>32</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Suction Head (m):</td>
                                                                    <td>7.5</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Maximum Discharge (Litre per Minute):</td>
                                                                    <td>670</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dimensions (LXWXH) (mm):</td>
                                                                    <td>490 X 385 X 410</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card mb-3'>
                                    <h3 className='p-3 mb-0 f16 fw-bold'>FEATURES</h3>
                                    <hr className='mb-1 mt-0' />
                                    <div className='row'>
                                        <div className='col-sm-12 f14'>
                                            <div className='p-3'>
                                                <div className='row'>
                                                    <div className='col-sm-12'></div>
                                                    <div className='col-sm-12'>
                                                        <div className='d-flex align-items-center'>

                                                            <div className=''> <span className='txtred'>SALIENT</span> <span className='txtdgray'>FEATURES</span> </div>
                                                        </div>
                                                        <table className='table table-sm table-striped table-hover f14'>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Type:</td>
                                                                    <td>4 Stroke, Force air-cooled,OHV engine</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Model:</td>
                                                                    <td>WB20XD</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Fuel:</td>
                                                                    <td>Petrol</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card mt-3 mb-3 d-none'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                                <TabContext value={value}>
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <TabList
                                                            onChange={handleChangeTab}
                                                            variant={mediumViewport ? "" : "scrollable"}
                                                            scrollButtons="auto"
                                                            allowScrollButtonsMobile
                                                            centered
                                                            TabIndicatorProps={{
                                                                style: {
                                                                    //display: "none",
                                                                    backgroundColor: '#CB1100'
                                                                },
                                                            }}

                                                            aria-label="">
                                                            <Tab label="DESCRIPTION" value="1" />
                                                            <Tab label="SPECIFICATIONS" value="2" />
                                                            <Tab label="FEATURES" value="3" />
                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value="1">
                                                        <div className='row'>
                                                            <div className='col-sm-12 f15'>
                                                                <p>WB20XD, the 2 Inch category model - delivers water with the best in class performance at up to 32 meters head and 670 litres discharge volume per minute. While being so light at 24 Kg, it fulfills hilly region farmers' irrigation and ease of carrying needs. It is backed by Honda Advanced 4-Stroke Technology OHV Petrol Engine for superior Performance, Reliability and Portability.</p>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                        <div className='row'>
                                                            <div className='col-sm-12'></div>
                                                            <div className='col-sm-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className=''><img src="https://www.hondaindiapower.com/front/media/engine-icon.png" alt="" style={{ width: '50px' }} /></div>
                                                                    <div className='ms-2'> <span className='txtred'>Engine</span> <span className='txtdgray'>Parameters</span> </div>
                                                                </div>
                                                                <table className='table table-sm table-striped table-hover f14'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Type</td>
                                                                            <td>4-Stroke, Single Cylinder, Air cooled, Horizontal Shaft, Over Head Valve</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Displacement (cm3)</td>
                                                                            <td>163</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Bore X Stroke (mm)</td>
                                                                            <td>68.0 X 45.0</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Fuel Type</td>
                                                                            <td>Petrol</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Rated Power (kW/rpm)</td>
                                                                            <td>2.9 kw / 3600 rpm</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Net Power (kW/rpm)</td>
                                                                            <td>3.6 kw / 3600 rpm</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Air Cleaner</td>
                                                                            <td>Dual Type</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Fuel Tank Capacity (L)</td>
                                                                            <td>3.1</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <hr />
                                                                <div className='d-flex align-items-center'>
                                                                    <div className=''><img src="https://www.hondaindiapower.com/front/media/capacity.png" alt="" style={{ width: '50px' }} /></div>
                                                                    <div className='ms-2'> <span className='txtred'>Pump</span> <span className='txtdgray'>Parameters</span> </div>
                                                                </div>
                                                                <table className='table table-sm table-striped table-hover f14'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Type:</td>
                                                                            <td>Centrifugal Self Priming, Monoblock
                                                                                Horizontal with Mechanical Seal</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Suction Port Diameter (mm):</td>
                                                                            <td>50</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Discharge Port Diameter (mm):</td>
                                                                            <td>50</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Dry Weight (kg):</td>
                                                                            <td>26</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Total Head (m):</td>
                                                                            <td>32</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Suction Head (m):</td>
                                                                            <td>7.5</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Maximum Discharge (Litre per Minute):</td>
                                                                            <td>670</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Dimensions (LXWXH) (mm):</td>
                                                                            <td>490 X 385 X 410</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                        <div className='row'>
                                                            <div className='col-sm-12'></div>
                                                            <div className='col-sm-12'>
                                                                <div className='d-flex align-items-center'>

                                                                    <div className=''> <span className='txtred'>SALIENT</span> <span className='txtdgray'>FEATURES</span> </div>
                                                                </div>
                                                                <table className='table table-sm table-striped table-hover f14'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Type:</td>
                                                                            <td>4 Stroke, Force air-cooled,OHV engine</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Model:</td>
                                                                            <td>WB20XD</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Fuel:</td>
                                                                            <td>Petrol</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                </TabContext>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='container'>

                    <div className='row'>
                        <div className='col-lg-9 bg-primary'>

                        </div>
                        <div className='col-lg-3 mb-3 '>

                        </div>
                        <div className='col-lg-9 '>

                        </div>
                    </div>
                </div> */}
            </div>
            <div style={{ clear: 'both', height: '40px' }}></div>
            <FotterMain />
        </>

    )
}

export default ProductDetail