import React, { useState, useEffect, useCallback } from 'react'
import { actionTypes, useStateValue } from '../../store';
import WishListViewCell from './WishListViewCell';
import Alert from '@mui/material/Alert';
import Header from '../BaseComponents/Header';
import FotterMain from '../BaseComponents/FotterMain';
import { getWishListData } from '../../networkCalls';
import FullPageApiLoadingCell from '../BaseComponents/FullPageApiLoadingCell';


const MyWishList = () => {
    const [{ token, huid }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false);
    const [errMessage, setErrMessage] = useState('');


    const [wishlistData, setWishlistData] = useState(null);
    const checkWishListData = () => {
        var data = new FormData();
        data.append('customerId', huid);
        data.append('XAuth', token);
        setLoading(true);
        getWishListData(data).then((res) => {
           // console.log("res.data ", res.data);
            setLoading(false);
            switch (res.data.status) {
                case 1:
                    setWishlistData(res?.data?.customerWishlist)
                    dispatch({ type: actionTypes.SET_WISHLISTCOUNT, value: res?.data?.customerWishlist?.length });
                    dispatch({ type: actionTypes.SET_WISHLISTALLID, value: res?.data?.customerWishlist.map((item) => item.productId) });
                    setErrMessage('');
                    return true;
                case 0:
                    setErrMessage(res.data.msz);
                    break;
                default:
                    return;
            }
        })
    }

    // eend wishlist api call
    useEffect(() => {
        checkWishListData();
    }, [token, huid, dispatch]);

    const callback = useCallback((pass) => {
        checkWishListData();
    }, []);
    return (
        <>
            <Header />
            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 text-left m-auto'>
                            <div className="d-flex align-items-center">
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>My </span> <span className='txtdgray'>Wishlist ({wishlistData?.length})</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-lg-9 col-md-12 col-12 m-auto'>
                            {errMessage?<><Alert severity="error">{errMessage}</Alert></>:null}
                            {loading ? <><FullPageApiLoadingCell /></> : <>
                                {wishlistData && wishlistData.length > 0 && wishlistData.map((data, key) => (
                                    <WishListViewCell itemIn={data} key={key} wishListBackIn={callback} />
                                ))}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <FotterMain />
        </>
    )
}

export default MyWishList