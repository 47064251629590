import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Header from '../BaseComponents/Header'
import FotterMain from '../BaseComponents/FotterMain'
import { getJobsList } from '../../networkCalls';
import FullPageApiLoadingCell from '../BaseComponents/FullPageApiLoadingCell';
import JobsViewCell from './JobsViewCell';

const CareersLP = () => {
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [jobs, setJobs] = React.useState('');
  const checkjobListData = () => {
    var data = new FormData();
    // data.append('customerId', huid);
    // data.append('XAuth', token);
    setLoading(true);
    getJobsList(data).then((res) => {
      console.log("res.data ", res.data);
      setLoading(false);
      switch (res.data.status) {
        case 1:
          setJobs(res?.data?.jobsList)
          setErrMessage('');
          return true;
        case 0:
           setErrMessage(res.data.msz);
          break;
        default:
          return;
      }
    })
  }

  useEffect(() => {
    checkjobListData();
  }, []);



  return (
    <>
      <Header />
      <div className='innerpagebg pt-5 pb-5'>
        <div className='container mb-4'>
          <div className='row'>
            <div className='col-12  m-auto'>
              <div className="align-items-center text-left">
                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                  <span className='txtred'>Jobs</span> <span className='txtdgray'>Available</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              {errMessage ? <><Alert severity="error">{errMessage}</Alert></> : null}
              {loading ? <><FullPageApiLoadingCell /></> : <>
                {jobs && jobs.length > 0 && jobs.map((data, key) => (
                  <JobsViewCell itemIn={data} key={key} />
                ))}
              </>}
            </div>
          </div>
        </div>
      </div>
      <FotterMain />
    </>
  )
}

export default CareersLP