import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import WishListIcon from '../../assets/icons/WishListIcon';
import Badge from '@mui/material/Badge';
import { actionTypes, useStateValue } from "./../../store";
import { getWishListData } from '../../networkCalls';
const WishListCel = () => {
    const navigate = useNavigate();

    const [{ token, huid, wishlistitemcount, wishListArrayID }, dispatch] = useStateValue();
   
    // get wishlist Data form api
    const checkWishListData = () => {
        if (!token) {
            dispatch({ type: actionTypes.SET_WISHLISTCOUNT, value: '0' });
            dispatch({ type: actionTypes.SET_WISHLISTALLID, value: null });
        }
        else {
            var data = new FormData();
            data.append('customerId', huid);
            data.append('XAuth', token);
            getWishListData(data).then((res) => {
                console.log("res.data ", res.data);
                switch (res.data.status) {
                    case 1:
                        dispatch({ type: actionTypes.SET_WISHLISTCOUNT, value: res?.data?.customerWishlist?.length });
                        dispatch({ type: actionTypes.SET_WISHLISTALLID, value: res?.data?.customerWishlist.map((item) => item.productId) });
                        return true;
                    case 0:
                        break;
                    default:
                        return;
                }
            })
        }
    }


   

    //const varn = wishListArrayID.map((wishListArrayID) => wishListArrayID.productId);

   // console.log('wishListArrayID', wishListArrayID)
   // console.log('varn', varn)

     // eend wishlist api call
     useEffect(() => {
        checkWishListData();
    }, [token, huid, dispatch]);

    const goTOWishListPage = () => {
        if (!token) {
            dispatch({ type: actionTypes.SET_LOGINMODAL, value: true });
        }
        else {
            navigate("/myaccount/manage-wishlist");
        }
    }

    return (
        <>
            <IconButton size="large"  onClick={() => goTOWishListPage()} color="inherit">
                <Badge badgeContent={wishlistitemcount?wishlistitemcount:'0'} color="error">
                    <WishListIcon />
                </Badge>
            </IconButton>
        </>
    )
}

export default WishListCel