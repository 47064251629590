import React from 'react'
import { Link } from 'react-router-dom'
const PressReleaseCel = () => {
    return (
        <div className="post-card">
            <div className="post-card__image"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022">
                <img alt="" src="https://www.hondaindiapower.com/front/media/production_milestone_img01.png" /></Link></div>
            <div className="post-card__content">
                <div className="post-card__category"><span className='txtlgeay'>28 February, 2022</span></div>
                <div className="post-card__title">
                    <h2><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtdgray'>Honda India Power Products Limited reaches 5 Million Units Production Milestone in February 2022</Link></h2>
                </div>
                <div className="post-card__date f12 mt-2"><Link to="/press-release/honda-india-power-products-limited-reaches-5-million-units-production-milestone-in-february-2022" className='txtred'>READ MORE</Link></div>
            </div>
        </div>
    )
}

export default PressReleaseCel