import React from 'react'
import IconButton from '@mui/material/IconButton';
import LinesEllipsis from 'react-lines-ellipsis';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
const MiniCartSidebar = () => {
    // const [{ token, loginmodal }, dispatch] = useStateValue();
    // useEffect(() => {
       
    // }, [dispatch]);

    // const btnmyaccount = () => {
    //     dispatch({ type: actionTypes.SET_LOGINMODAL, value: true });
    // }

    // const navigate = useNavigate();
    // const gotoCheckout = () => {
    //     if(!token){
    //         console.log('not login', token)
    //         dispatch({ type: actionTypes.SET_LOGINMODAL, value: true });
    //     }
    //     //console.log('dfs', token)
    //     //navigate("/checkout");
    // }

    return (
        <>
            <div className='p-3 bg-white'>
                <div className='d-flex'>
                    <div className='border'><img src="http://honda.wipl.co.in/SuperAdmin/public/images/Category/1646992951Category-Image.jpg" style={{ width: '70px' }} alt="" /></div>
                    <div className='flex-grow-1 ms-2'>
                        <div className='row'>
                            <div className='col-12'>
                                <LinesEllipsis
                                    text='WB20XD'
                                    maxLine='1'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                    className='f14 fw-bold overflow-hidden'
                                />
                            </div>
                            <div className='col-12 txtlgeay f12'>
                                Category Name
                            </div>
                        </div>
                        <div className='row d-flex mt-1 align-items-center'>
                            <div className='f14 col-6 col-md-3'>
                                <CurrencyRupeeIcon className='f14' />23,098
                            </div>
                            <div className='d-flex align-items-center col-6 col-md-5'>
                                <IconButton size="medium" color="inherit" >
                                    <IndeterminateCheckBoxIcon color="bgdgray" />
                                </IconButton>
                                <div className='p-1 pe-2 ps-2 bgsgray'>
                                    5
                                </div>
                                <IconButton size="medium" color="inherit" >
                                    <AddBoxIcon color="success" />
                                </IconButton>
                            </div>
                            <div className='text-start text-md-end col-12 col-md-4'>
                                <div className='f16'><CurrencyRupeeIcon className='f14' />23,098</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <IconButton size="medium" color="inherit" >
                            <DeleteForeverOutlinedIcon color="error" />
                        </IconButton>
                    </div>
                </div>
            </div>
            <hr className='m-0' />
            <div className='p-3 bg-white'>
                <div className='d-flex'>
                    <div className='border'><img src="http://honda.wipl.co.in/SuperAdmin/public/images/Category/1646992951Category-Image.jpg" style={{ width: '70px' }} alt="" /></div>
                    <div className='flex-grow-1 ms-2'>
                        <div className='row'>
                            <div className='col-12'>
                                <LinesEllipsis
                                    text='WB20XD'
                                    maxLine='1'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                    className='f14 fw-bold overflow-hidden'
                                />
                            </div>
                            <div className='col-12 txtlgeay f12'>
                                Category Name
                            </div>
                        </div>
                        <div className='row d-flex mt-1 align-items-center'>
                            <div className='f14 col-6 col-md-3'>
                                <CurrencyRupeeIcon className='f14' />23,098
                            </div>
                            <div className='d-flex align-items-center col-6 col-md-5'>
                                <IconButton size="medium" color="inherit" >
                                    <IndeterminateCheckBoxIcon color="bgdgray" />
                                </IconButton>
                                <div className='p-1 pe-2 ps-2 bgsgray'>
                                    5
                                </div>
                                <IconButton size="medium" color="inherit" >
                                    <AddBoxIcon color="success" />
                                </IconButton>
                            </div>
                            <div className='text-start text-md-end col-12 col-md-4'>
                                <div className='f16'><CurrencyRupeeIcon className='f14' />23,098</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <IconButton size="medium" color="inherit" >
                            <DeleteForeverOutlinedIcon color="error" />
                        </IconButton>
                    </div>
                </div>
            </div>
            <hr className='m-0' />
            <div className='p-3 bg-white'>
                <div className='d-flex'>
                    <div className='border'><img src="http://honda.wipl.co.in/SuperAdmin/public/images/Category/1646992951Category-Image.jpg" style={{ width: '70px' }} alt="" /></div>
                    <div className='flex-grow-1 ms-2'>
                        <div className='row'>
                            <div className='col-12'>
                                <LinesEllipsis
                                    text='WB20XD'
                                    maxLine='1'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                    className='f14 fw-bold overflow-hidden'
                                />
                            </div>
                            <div className='col-12 txtlgeay f12'>
                                Category Name
                            </div>
                        </div>
                        <div className='row d-flex mt-1 align-items-center'>
                            <div className='f14 col-6 col-md-3'>
                                <CurrencyRupeeIcon className='f14' />23,098
                            </div>
                            <div className='d-flex align-items-center col-6 col-md-5'>
                                <IconButton size="medium" color="inherit" >
                                    <IndeterminateCheckBoxIcon color="bgdgray" />
                                </IconButton>
                                <div className='p-1 pe-2 ps-2 bgsgray'>
                                    5
                                </div>
                                <IconButton size="medium" color="inherit" >
                                    <AddBoxIcon color="success" />
                                </IconButton>
                            </div>
                            <div className='text-start text-md-end col-12 col-md-4'>
                                <div className='f16'><CurrencyRupeeIcon className='f14' />23,098</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <IconButton size="medium" color="inherit" >
                            <DeleteForeverOutlinedIcon color="error" />
                        </IconButton>
                    </div>
                </div>
            </div>
            <hr className='m-0' />
            <div className='p-3 bg-white'>
                <div className='d-flex'>
                    <div className='border'><img src="http://honda.wipl.co.in/SuperAdmin/public/images/Category/1646992951Category-Image.jpg" style={{ width: '70px' }} alt="" /></div>
                    <div className='flex-grow-1 ms-2'>
                        <div className='row'>
                            <div className='col-12'>
                                <LinesEllipsis
                                    text='WB20XD'
                                    maxLine='1'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                    className='f14 fw-bold overflow-hidden'
                                />
                            </div>
                            <div className='col-12 txtlgeay f12'>
                                Category Name
                            </div>
                        </div>
                        <div className='row d-flex mt-1 align-items-center'>
                            <div className='f14 col-6 col-md-3'>
                                <CurrencyRupeeIcon className='f14' />23,098
                            </div>
                            <div className='d-flex align-items-center col-6 col-md-5'>
                                <IconButton size="medium" color="inherit" >
                                    <IndeterminateCheckBoxIcon color="bgdgray" />
                                </IconButton>
                                <div className='p-1 pe-2 ps-2 bgsgray'>
                                    5
                                </div>
                                <IconButton size="medium" color="inherit" >
                                    <AddBoxIcon color="success" />
                                </IconButton>
                            </div>
                            <div className='text-start text-md-end col-12 col-md-4'>
                                <div className='f16'><CurrencyRupeeIcon className='f14' />23,098</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <IconButton size="medium" color="inherit" >
                            <DeleteForeverOutlinedIcon color="error" />
                        </IconButton>
                    </div>
                </div>
            </div>
            <hr className='m-0' />
            <div className='p-3 bg-white'>
                <div className='d-flex'>
                    <div className='border'><img src="http://honda.wipl.co.in/SuperAdmin/public/images/Category/1646992951Category-Image.jpg" style={{ width: '70px' }} alt="" /></div>
                    <div className='flex-grow-1 ms-2'>
                        <div className='row'>
                            <div className='col-12'>
                                <LinesEllipsis
                                    text='WB20XD'
                                    maxLine='1'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                    className='f14 fw-bold overflow-hidden'
                                />
                            </div>
                            <div className='col-12 txtlgeay f12'>
                                Category Name
                            </div>
                        </div>
                        <div className='row d-flex mt-1 align-items-center'>
                            <div className='f14 col-6 col-md-3'>
                                <CurrencyRupeeIcon className='f14' />23,098
                            </div>
                            <div className='d-flex align-items-center col-6 col-md-5'>
                                <IconButton size="medium" color="inherit" >
                                    <IndeterminateCheckBoxIcon color="bgdgray" />
                                </IconButton>
                                <div className='p-1 pe-2 ps-2 bgsgray'>
                                    5
                                </div>
                                <IconButton size="medium" color="inherit" >
                                    <AddBoxIcon color="success" />
                                </IconButton>
                            </div>
                            <div className='text-start text-md-end col-12 col-md-4'>
                                <div className='f16 '><CurrencyRupeeIcon className='f14' />23,098</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <IconButton size="medium" color="inherit" >
                            <DeleteForeverOutlinedIcon color="error" />
                        </IconButton>
                    </div>
                </div>
            </div>
            <hr className='m-0' />
        </>
    )
}

export default MiniCartSidebar