import React from 'react'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
const FullPageApiLoadingCell = () => {
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" width={'100%'} height={50} />
                <Skeleton animation="wave" />
            </Box>
        </>
    )
}

export default FullPageApiLoadingCell