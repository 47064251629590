import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import { actionTypes, useStateValue } from "./../../store";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useCookies } from "react-cookie";
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate, useLocation } from "react-router-dom";
import logosm from "./../../assets/img/logo-sm.png"
import LeftMenuHeader from './LeftMenuHeader';
import BadgeIcon from '@mui/icons-material/Badge';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CartIcon from '../../assets/icons/CartIcon';
import WishListIcon from '../../assets/icons/WishListIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import UserIcon from '../../assets/icons/UserIcon';
import CartIconsm from '../../assets/icons/CartIconsm';
import FacebookIcon from '@mui/icons-material/Facebook';
import WishListIconsm from '../../assets/icons/WishListIconsm';
import UserIconsm from '../../assets/icons/UserIconsm';
import HeaderSearchCel from './HeaderSearchCel';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import MiniCartSidebar from '../Cart/MiniCartSidebar';
import WishListCel from './WishListCel';



const Header = () => {
    const location = useLocation();
   // console.log(location.pathname);
    const compareowl = {
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
    };

    const navigate = useNavigate();
    const [{ token, compareitemcount, huid, loginmodal }, dispatch] = useStateValue();
    const [cookie, , removeCookie] = useCookies(["jwt", "huid", "hdetial"]);
    const { hdetial } = cookie;

    useEffect(() => {
        const setCompareitemcount = () => {
            var existingEntries = JSON.parse(localStorage.getItem("allEntries"));
            dispatch({ type: actionTypes.SET_COMPAREITEMCOUNT, value: existingEntries?.length });
        };
        if (compareitemcount === null) {
            setCompareitemcount();
        }
    }, [token, huid, dispatch, compareitemcount]);

    const [compareData, setCompareData] = useState(JSON.parse(localStorage.getItem('allEntries')));

    const [state, setState] = React.useState({
        left: false,
        right: false,
        bottom: false,
        cartdisplay: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
        if (anchor === 'bottom') {
            setCompareData(JSON.parse(localStorage.getItem('allEntries')));
        }
        // console.log('ho');
    };
    const removeCompare = (value) => {
        // console.log('value', value)
        var removeCompare = JSON.parse(localStorage.getItem('allEntries'));
        removeCompare.splice(value, 1);
        localStorage.setItem('allEntries', JSON.stringify(removeCompare));
        setCompareData(JSON.parse(localStorage.getItem('allEntries')));
        dispatch({ type: actionTypes.SET_COMPAREITEMCOUNT, value: removeCompare.length });
    }
    const removeAllCompareData = () => {
        //console.log('hi')
        localStorage.removeItem("allEntries");
        dispatch({ type: actionTypes.SET_COMPAREITEMCOUNT, value: '' });
        // toggleDrawer('bottom', false)
        setState({ ...state, 'bottom': false });
    }
    const btnmyaccount = () => {
        dispatch({ type: actionTypes.SET_LOGINMODAL, value: true });
    }
    // const logout = () => {
    //     removeCookie("jwt");
    //     removeCookie("huid");
    //     dispatch({ type: actionTypes.SET_TOKEN, value: null });
    //     dispatch({ type: actionTypes.SET_HUID, value: null });
    //     navigate("/");
    // }

    const logout = async () => {
        try {
            removeCookie("jwt");
            removeCookie("huid");
            removeCookie("hdetial");
            dispatch({ type: actionTypes.SET_TOKEN, value: null });
            dispatch({ type: actionTypes.SET_HUID, value: null });
            dispatch({ type: actionTypes.SET_WISHLISTCOUNT, value: '0' });
            dispatch({ type: actionTypes.SET_WISHLISTALLID, value: null });
           // console.log('hhhuuu')
            navigate("/");
            
        } catch (error) {
          throw Error(error);
        }
    }
    
    const gotoComparePage = () =>{
        navigate("/compare/", {state:{id:compareData.map(t => t.id)}});
    }

    const gotoCheckout = () => {
        if(!token){
            console.log('not login', token);
            //toggleDrawer('cartdisplay', false);
            setState({ ...state, 'cartdisplay': false });
            dispatch({ type: actionTypes.SET_LOGINMODAL, value: true });
        }
        else{
            // api post call for save cart to databse along with customer id
            //console.log('dfs', token)
            navigate("/checkout");
        }
    }

  

    const list = (anchor) => (
        <Box
            sx={{ width: 300 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="flex items-center justify-between px-4 py-4 border-b bgred" >
                <div className="text-blueGray-700 font-semibold text-uppercase text-white fw-bold">My Account</div><div></div>
            </div>
            {token ? <>
                <List>
                    <Link to='/myaccount/dashboard'>
                        <ListItem button>
                            <ListItemIcon>
                                <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary='My Profile' />
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List>
                    <Link to='/myaccount/manage-orders'>
                        <ListItem button>
                            <ListItemIcon>
                                <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary='My Orders' />
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List>
                    <Link to='/myaccount/manage-addresses'>
                        <ListItem button>
                            <ListItemIcon>
                                <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary='Manage Addresses' />
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List>
                    <Link to='/myaccount/manage-wishlist'>
                        <ListItem button>
                            <ListItemIcon>
                                <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary='My Wishlist' />
                        </ListItem>
                    </Link>
                </List>
                <Divider />                
                <List>
                    <ListItem button onClick={logout}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText className='text-black' primary='Logout' />
                    </ListItem>
                </List>
            </> : <List>
                <ListItem button onClick={btnmyaccount}>
                    <ListItemIcon>
                        <UserIconsm />
                    </ListItemIcon>
                    <ListItemText primary='Login' />
                </ListItem>
            </List>}
        </Box>
    );

    const leftMenu = (anchor) => (
        <Box
            sx={{ width: 300 }}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
            className='mobile-left-nav'
        >
            <LeftMenuHeader />
        </Box>
    );

    const cartdisplay = (anchor) => (
        <Box
            sx={{ width: { xs: 300, sm: 500 }, }}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
            className='bglblue'
        >
           <div className="d-flex align-items-center justify-content-between border-b bgred" >
                <div className='d-flex  align-items-center ms-2'>
                    <IconButton size="large" color="inherit" >
                        <Badge badgeContent={20} color="info" className='mt-1'>
                            <CartIcon />
                        </Badge>
                    </IconButton>
                    <div className='ms-2 text-white fw-bold'>MY CART</div>
                </div>
                <div className='btn btn-sm bg-white me-2'><CloseIcon onClick={toggleDrawer('cartdisplay', false)} /></div>
            </div>
            <MiniCartSidebar />
            <div className='bglblue p-3'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='f18 fw-bold'>Grand Total</div>
                    <div className='f18 fw-bold'><CurrencyRupeeIcon className='f14' />323,343</div>
                </div>
                <div className='row align-items-center mt-3'>
                    <div className='col-12'>
                        <Button color="error" onClick={() => gotoCheckout()} variant="contained" className='w-100' type="submit">
                            GO TO CHECKOUT
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
    );

    

   
    const comparePop = (anchor) => (
        <Box
            sx={{ width: 'auto' }}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
            className=''
        >
            <div className='container'>
                <div className='row mt-3 mb-3'>
                    <div className='col-md-9 col-8'>
                        <div className='row'>
                            {compareData && compareData.map((data, i) => (
                                <div key={i} className='col-md-3 col-6 mb-2' >
                                    <div className='card p-2 text-center position-relative'>
                                        <IconButton onClick={() => { removeCompare(i) }} size="small" className='position-absolute text-white' style={{ right: '0px', top: '0px' }}>
                                            <CancelIcon className='txtred' />
                                        </IconButton>
                                        <div className=''>
                                            <div className='p_img'>
                                                <img src={data?.img} alt="" className='img-fluid' style={{ maxWidth: '80px' }} />
                                            </div>
                                            <div className='mt-2'>
                                                {data?.title}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='col-md-3 col-4'>
                        {compareitemcount > 1 ? <>
                            <Button variant="contained" onClick={gotoComparePage} size="medium" className='d-block'>
                                COMPARE <span className='d-none d-md-inline-block ms-2 bg-white text-primary  border rounded ps-1 pe-1'>{compareitemcount}</span>
                            </Button>
                        </> : null}
                        <Button variant="text" onClick={removeAllCompareData} size="medium" className='d-block mt-3'>
                            Clear all
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
    );

    return (
        <>
            {compareitemcount && compareitemcount > 1 && location.pathname != "/compare/" ?
                <>
                    <div className='position-fixed p-4' style={{ zIndex: 100, right: '10px', bottom: '10px', fontSize: '34px' }}>
                        <Button variant="contained" onClick={toggleDrawer('bottom', true)} size="large">
                            COMPARE <span className='ms-2 bg-white text-primary d-inline-block border rounded ps-1 pe-1'>{compareitemcount}</span>
                        </Button>
                    </div>
                </> : null
            }

            <div className='site site--desktop-header--spaceship-one site--mobile-header--mobile-one'>
                <header className='site__header d-none d-lg-block'>
                    <div className='header'>
                        <div className="header__topbar-start-bg"></div>
                        <div className="header__topbar-start ">
                            <div className="topbar topbar--spaceship-start d-flex align-items-center text-white">
                                <SupportAgentIcon className='me-1' /><div className="topbar__item-text">Call Toll Free:<a href="tel:1800112323" className="text-white ms-1">1800-11-2323</a></div>
                                {/* <div className="topbar__item-text"><Link className="topbar__link" to="">Contact Us</Link></div> */}
                            </div>
                        </div>
                        <div className="header__topbar-end-bg"></div>
                        <div className='header__topbar-end '>
                            <div className='topbar topbar--spaceship-end align-items-center d-flex text-white'>
                                <div className='topbar__item-button f16'>Follow Us On: <a href="https://www.facebook.com/hondapowerproductsindia/" target="_blank" className='text-white'><FacebookIcon /></a></div>
                            </div>
                        </div>
                        <div className='header__navbar align-items-center d-flex'>
                            {/* <LeftMenuHeader /> */}
                            <div className='d-flex align-items-center ms-2'
                                onClick={toggleDrawer('left', true)}
                            >
                                <IconButton size="large" color="inherit">
                                    <div><MenuIcon className='txtred' /></div>
                                </IconButton>
                                <div className='text-start'>
                                    <span className="indicator__value d-block f16 fw-bold">MENU</span>
                                </div>
                            </div>
                        </div>
                        <div className="header__logo">
                            <Link className="logo" to="/">
                                <div className="logo__slogan txtlgeay text-uppercase f12 ps-4 pe-4 pt-2">India's No. 1 Power Products company</div>
                                <div className="logo__image text-center"><img className='mt-2' src='https://demo.wipl.co.in/logo-desktop.png' style={{ maxWidth: '200px' }} alt="honda" /></div>
                            </Link>
                        </div>
                        <HeaderSearchCel />
                        <div className='header__indicators'>
                            <WishListCel />
                            

                            <div className='d-flex align-items-center ms-2'
                                onClick={!token ? btnmyaccount : toggleDrawer('right', true)}
                            >
                                <IconButton size="large" color="inherit">
                                    <div> <UserIcon /></div>
                                </IconButton>
                                <div className='text-start'>
                                    <span className="indicator__title d-block f12">Hello, {token ? <>{hdetial}</> : <>Log In</>}</span>
                                    <span className="indicator__value d-block f16 fw-bold">My Account</span>
                                </div>
                            </div>
                            <div className='d-flex align-items-center ms-2'  onClick={toggleDrawer('cartdisplay', true)}>
                                <IconButton size="large" color="inherit">
                                    <Badge badgeContent={20} color="error" className='mt-1'>
                                        <CartIcon />
                                    </Badge>
                                </IconButton>
                                <div className='text-start'>
                                    <span className="indicator__title d-block f12">Cart</span>
                                    <span className="indicator__value d-block f16 fw-bold"><CurrencyRupeeIcon className='f14 fw-bold' />23,231</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* toggleDrawer('bottom', true); */}




                {/* for mobile */}
                <Box sx={{ flexGrow: 1 }} className='d-lg-none mobile-app-header'>
                    <AppBar position="static" className='bg-white pt-1 pb-2'>
                        <Toolbar>
                            <IconButton
                                onClick={toggleDrawer('left', true)}
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 0 }}
                            >
                                <MenuIcon className='txtred' />
                            </IconButton>
                            <Link to="/">
                                <img src={logosm} alt="honda" />
                            </Link>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                                <IconButton onClick={!token ? btnmyaccount : null} size="large" color="inherit">
                                    <Badge badgeContent={4} color="error">
                                        <WishListIconsm />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                onClick={toggleDrawer('cartdisplay', true)}
                                    size="large"
                                    color="inherit"
                                >
                                    <Badge badgeContent={8} color="error">
                                        <CartIconsm />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    onClick={!token ? btnmyaccount : toggleDrawer('right', true)}
                                    size="large"
                                    edge="end"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <UserIconsm />
                                </IconButton>
                            </Box>
                        </Toolbar>
                        <div className='d-flex justify-content-center position-relative'>
                            <HeaderSearchCel />
                        </div>
                    </AppBar>
                </Box>
                <React.Fragment key='left'>
                    <Drawer
                        anchor='left'
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                    >
                        {leftMenu('left')}
                    </Drawer>
                </React.Fragment>
                <React.Fragment key='right'>
                    <Drawer
                        anchor='right'
                        open={state['right']}
                        onClose={toggleDrawer('right', false)}
                    >
                        {list('right')}
                    </Drawer>
                </React.Fragment>
                <React.Fragment key='bottom'>
                    <Drawer
                        anchor='bottom'
                        open={state['bottom']}
                        onClose={toggleDrawer('bottom', false)}
                    >
                        {comparePop('bottom')}
                    </Drawer>
                </React.Fragment>

                
                {/* for mobile */}
                <React.Fragment key='cartdisplay'>
                    <Drawer
                        anchor='right'
                        open={state['cartdisplay']}
                        onClose={toggleDrawer('cartdisplay', false)}
                    >
                        {cartdisplay('right')}
                    </Drawer>
                </React.Fragment>
            </div>
        </>

    )
}

export default Header