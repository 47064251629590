import React from 'react'
import FotterMain from '../BaseComponents/FotterMain'
import Header from '../BaseComponents/Header'

import { baseURL } from "../../networkCalls/index";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const OnlinePayment = () => {
  return (
    <>
    <Header />
    <div className='innerpagebg pt-5 pb-5'>
        <div className='container mb-4'>
          <div className='row'>
            <div className='col-12  m-auto'>
              <div className="align-items-center text-center">
                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                  <span className='txtred'>Onine</span> <span className='txtdgray'>Payment</span>
                </h4>
                <p className='mt-2 f16'>For Online payment, you can choose below option</p>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
           <div className='row '>
               <div className='col-lg-5 col-mg-6 col-sm-12 col-12 text-center m-auto'>
                   <div className='card d-inline-block p-3 rounded-0 shadow-sm'>
                    <LazyLoadImage
                        alt=""
                        effect="blur"
                        className='img-fluid mb-4 mt-3'
                        // style={{ maxWidth: '75px' }}
                        src={`${baseURL}images/qr-code.png`}
                        />
                   </div>
               </div>
           </div>
        </div>
    </div>
    <FotterMain />
    </>
  )
}

export default OnlinePayment