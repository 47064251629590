import React, { useState, useEffect } from 'react'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Button from '@mui/material/Button';
import { actionTypes, useStateValue } from "./../../../store";
import { Link } from 'react-router-dom';
import { saveToWishList } from '../../../networkCalls';
import BtnCompare from '../../Product/BtnCompare';
import BtnWishList from '../../Product/BtnWishList';

const ProductGridBox = () => {
   
    const [{ token, huid, wishlistitemcount, wishListArrayID, compareitemcount, msgalert }, dispatch] = useStateValue();


    return (
        <>
            <div className="product-card product-card--layout--grid d-flex bg-white position-relative rounded">
                <div className="product-card__actions-list d-flex flex-column">
                    <BtnWishList pid={2} btntype={'icon'} />
                    <BtnCompare pid={2} btntype={'icon'} pname={'WB20XD'} entryText={'High Head Petrol Pumpset'} pimage={'https://www.hondaindiapower.com/uploads/products/4/11.jpg'}  />
                </div>
                <div className="product-card__image">
                    <div className="image image--type--product">
                        <Link className="image__body" to="/product-detail/hrj216-k2">
                            <img alt="" className="image__tag" src="https://www.hondaindiapower.com/uploads/products/4/11.jpg" />
                        </Link>
                    </div>
                </div>
                <div className="product-card__info">
                    <div className="product-card__meta"><span className="product-card__meta-title">SKU: </span>985-00884-S</div>
                    <div className="product-card__name"><Link to="/product-detail/hrj216-k2" className='fw-bold f16'>UMK435T UENT</Link></div>
                    <div className="product-card__desc">Brush Cutter is designed to take on the tough jobs. Even thick grass</div>
                </div>
                <div className="product-card__footer">
                    <div className="product-card__prices">
                        <div className="product-card__price product-card__price--current"><CurrencyRupeeIcon className='f12 fw-bold' />23,212</div>
                    </div>
                    {/* <IconButton className={"HoverBgRed addtocartbtn"}
                        size="large"
                        edge="end"
                    //onClick={handleProfileMenuOpen}
                    >
                        <CartIconsm />
                    </IconButton> */}
                    <Button size="small" variant="outlined" className='HoverBgRed addtocartbtn txtdgray mb-1 mt-2'
                    //  startIcon={<DeleteIcon />}
                    >
                        Add to cart
                    </Button>
                </div>
            </div>
        </>
    )
}

export default ProductGridBox