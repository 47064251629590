import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import { actionTypes, useStateValue } from '../../store';
import Button from '@mui/material/Button';
import Header from '../BaseComponents/Header';
import FotterMain from '../BaseComponents/FotterMain';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";


const validationSchema = yup.object({
    cname: yup
        .string('Enter your business name')
        .required('Business name is required'),
    gst: yup
        .string('Enter your GST Details')
        .required('GST Details is required'),
});


const AddBusinessProfile = () => {

    const navigate = useNavigate();

    const [{ token, userID }, dispatch] = useStateValue();
   

    const formik = useFormik({
        initialValues: {
            cname: '',
            gst: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            var reqestData = new FormData();
            reqestData.append('id', userID);
            reqestData.append('token', token);
            reqestData.append('cname', values.cname);
            reqestData.append('gst', values.gst);
            // for (var pair of reqestData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }

        },
    });

    return (
        <>
            <Header />

            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 text-left m-auto'>
                            <div className="d-flex align-items-center">
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>Edit</span> <span className='txtdgray'>Profile</span>
                                </h4>
                                <div className="ms-auto">
                                    <Button variant="text" onClick={() => navigate(-1)} size="medium" className='d-block mt-3'>
                                        BACK
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-lg-9 col-md-12 col-12 m-auto'>
                            <div className='card p-4 mb-3'>
                                <div className="d-flex">
                                    <div>
                                        <label className="text-uppercase fw-bold">Business Information</label>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <hr />
                                </div>
                                <form onSubmit={formik.handleSubmit} autoComplete="off">
                                    <div className="row">
                                        <div className='col-lg-6  col-12'>
                                            <TextField
                                                
                                                className='mb-4 w-100'
                                                variant="standard"
                                                id="cname"
                                                name="cname"
                                                label="Business Name *"
                                                type="text"
                                                value={formik.values.cname}
                                                onChange={formik.handleChange}
                                                error={formik.touched.cname && Boolean(formik.errors.cname)}
                                                helperText={formik.touched.cname && formik.errors.cname}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <TextField
                                                
                                                className='mb-4 w-100'
                                                variant="standard"
                                                id="gst"
                                                name="gst"
                                                label="GST *"
                                                type="text"
                                                value={formik.values.gst}
                                                onChange={formik.handleChange}
                                                error={formik.touched.gst && Boolean(formik.errors.gst)}
                                                helperText={formik.touched.gst && formik.errors.gst}
                                            />
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <div className="relative w-full mb-3">
                                                <Button color="error" variant="contained" className='w-100' type="submit">
                                                    ADD BUSINESS PROFILE
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FotterMain />
        </>
    )
}

export default AddBusinessProfile