import React from 'react'
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {actionTypes, useStateValue } from '../../store';
import { saveToWishList } from '../../networkCalls';
const WishListViewCell = ({ itemIn, wishListBackIn }) => {

    const [{ token, huid }, dispatch] = useStateValue();
    const removeWishListItem = (value) => {
        var data = new FormData();
        data.append('customerId', huid);
        data.append('XAuth', token);
        data.append('productId', value);
        data.append('status', 0);
        // for (var pair of data.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }
        saveToWishList(data).then((res) => {
            //console.log("res.data ", res.data);
            switch (res.data.status) {
                case 1:
                    dispatch({ type: actionTypes.SET_MSGALERTTYPE, value: 'success' });
                    dispatch({ type: actionTypes.SET_MSGALERTDATA, value: res.data.msz });
                    dispatch({ type: actionTypes.SET_MSGALERT, value: true });
                    wishListBackIn(true);
                    return true;
                case 0:
                    dispatch({ type: actionTypes.SET_MSGALERTTYPE, value: 'error' });
                    dispatch({ type: actionTypes.SET_MSGALERTDATA, value: res.data.msz });
                    dispatch({ type: actionTypes.SET_MSGALERT, value: true });
                    break;
                default:
                    return;
            }
        })
    }

    return (
        <>
            <div className='card p-3 mb-3'>
                <div className='row'>
                    <div className='col-lg-2 col-3'>
                        <div className="product-card__image">
                            <div className="image image--type--product">
                                <a className="image__body" href="#">
                                    <img alt="" className="image__tag" src={itemIn?.productImage} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-10 col-9">
                        <div className="d-flex">
                            <div>
                                <p className='mt-3'><label className="fw-bold">{itemIn?.productTitle}</label></p>
                                <div className="product-card__info">
                                    <div className="product-card__meta"><span className="product-card__meta-title">{itemIn?.productCategory}</span></div>
                                    {/* <div className="product-card__name"><a href="#" className='fw-bold f16'>UMK435T UENT</a></div> */}
                                    <div className="product-card__desc d-none d-lg-block mt-2">{itemIn?.productDescription}</div>
                                </div>
                            </div>
                            <div className="ms-auto">
                                <IconButton
                                    size="small"
                                    className='text-danger'
                                    edge="end"
                                    // onClick={handleWishList}
                                    onClick={() => removeWishListItem(itemIn?.productId)}
                                >
                                    <DeleteOutlineIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WishListViewCell