import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { baseURL } from "../../../networkCalls/index";
import Image from 'react-image-webp';
import {isWebpSupported} from 'react-image-webp/dist/utils';
const MainSlider = () => {
    const state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };
    // console.log('isWebpSupported', isWebpSupported())
    return (
        <>
            <OwlCarousel className='owl-theme with-nav-bottom-center' autoplay={false} margin={25} items={4} nav={true} dots={false} responsive={state.responsive}>
                <div className="d-block">
                    <div className='makehand' >
                        <div className="icon-box  text-center">
                            <div className="icon iconSize64 m-auto">
                            {isWebpSupported()?<>
                            <Image
                                src={`${baseURL}images/honda-slider.jpeg`}
                                webp={`${baseURL}images/honda-slider.webp`}
                            />
                            </>:<>
                            <LazyLoadImage
                                    alt=""
                                    effect="blur"
                                    src={`${baseURL}images/honda-slider.jpeg`}
                                />
                            </>}
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </>
    )
}

export default React.memo(MainSlider)