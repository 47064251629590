import React from 'react'
import FotterMain from '../BaseComponents/FotterMain'
import Header from '../BaseComponents/Header'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const WhyBuyHonda = () => {
    return (
        <>
            <Header />
            <div className='innerpagebg pt-5 pb-5'>
                <div className='container mb-4'>
                    <div className='row'>
                        <div className='col-12  m-auto'>
                            <div className="align-items-center text-center">
                                <h1 className='d-inline-block m-0 fw-bold text-uppercase f24'>
                                    <span className='txtred'>WHY BUY A</span> <span className='txtdgray'>HONDA</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row '>
                        <div className='col-lg-12 col-mg-12 col-sm-12 col-12 m-auto'>
                            <div className='card p-5 rounded-0 shadow-sm'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                        <h6 className='txtred fw-bold mb-2'>PRODUCT</h6>
                                        <ul className='m-0 p-0 list-unstyled'>
                                            <li className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Wide & reliable product range of Portable Gensets, Portable Engines, Portable Water Pumping Set & Lawnmowers</li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                        <h6 className='txtred fw-bold mb-2'>GENERAL</h6>
                                        <ul className='m-0 p-0 list-unstyled'>
                                            <li className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />ISO 9001 & ISO 14001 certified company</li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                        <h6 className='txtred fw-bold mb-2'>TECHNOLOGY</h6>
                                        <ul className='m-0 p-0 list-unstyled'>
                                            <li  className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Environmental friendly 4-stroke technology</li>
                                            <li  className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Over 50 years of technical expertise in power products</li>
                                            <li  className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Common domestic & export manufacturing line using latest technology</li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                        <h6 className='txtred fw-bold mb-2'>AFTER SALES SERVICE</h6>
                                        <ul className='m-0 p-0 list-unstyled'>
                                            <li className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Assured after-sales service through a wide dedicated service network</li>
                                            <li className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />2500 company trained mechanics all over the country</li>
                                            <li  className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Regular service camps by company/ dealers Periodic training camps organised by company engineers</li>
                                            <li  className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Assured supply of spare parts through depots</li>
                                            <li  className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Automated service workshops</li>
                                            <li  className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Mobile service vans for prompt service Worldwide distribution network</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FotterMain />
        </>
    )
}

export default WhyBuyHonda