import React from 'react'
import Header from '../BaseComponents/Header'

const PrivacyPolicy = () => {
  return (
    <>
    <Header />
    <div>dsfdsf</div>
    </>
  )
}

export default PrivacyPolicy