import React from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const WhyChoseUsCel = () => {
  return (
    <div className='bglblue pt-5 pb-5'>
        <div className='container'>
            <div className='row d-flex align-items-center'>
                <div className='col-lg-4'>
                     <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                        <span className='txtred'>WHY</span> <span className='txtdgray'>CHOOSE US?</span>
                    </h4>
                    <p className='f17 mt-3'>Honda is committed to bringing joy in the lives of customers through innovative, reliable and technologically advanced power products that make a significant contribution to the Indian society by increasing the quality of their living standards and becoming a part of their daily lives.</p>
                    <Link to="" className='txtred'>KNOW MORE</Link>
                </div>
                <div className='col-lg-8'>
                    <div className='row'>
                        <div className='col-lg-4 col-6 text-center mb-2 mt-3'>
                            <LazyLoadImage
                                    alt=""
                                    effect="blur"
                                    className='img-fluid mb-3'
                                    style={{maxWidth:'75px'}}
                                    src='https://www.demo.wipl.co.in/honda/home-icon-1.png'
                            />
                            <div className='txtdgray f16 f14sm fw-bold'>
                                ISO 9001 & ISO 14001
                            </div>
                            <div className='txtlgeay'>
                                certified company
                            </div>
                        </div>
                        <div className='col-lg-4 col-6 text-center mb-2 mt-3'>
                            <LazyLoadImage
                                    alt=""
                                    effect="blur"
                                    className='img-fluid mb-3'
                                    style={{maxWidth:'68px'}}
                                    src='https://www.demo.wipl.co.in/honda/home-icon-2.png'
                            />
                            <div className='txtdgray f16 f14sm fw-bold'>
                                ISO 9001 & ISO 14001
                            </div>
                            <div className='txtlgeay'>
                                certified company
                            </div>
                        </div>
                        <div className='col-lg-4 col-6 text-center mb-2 mt-3'>
                            <LazyLoadImage
                                    alt=""
                                    effect="blur"
                                    className='img-fluid mb-3'
                                    style={{maxWidth:'110px'}}
                                    src='https://www.demo.wipl.co.in/honda/home-icon-3.png'
                            />
                            <div className='txtdgray f16 f14sm fw-bold'>
                                ISO 9001 & ISO 14001
                            </div>
                            <div className='txtlgeay'>
                                certified company
                            </div>
                        </div>
                        <div className='col-lg-4 col-6 text-center mb-2 mt-3'>
                            <LazyLoadImage
                                    alt=""
                                    effect="blur"
                                    className='img-fluid mb-3'
                                    style={{maxWidth:'75px'}}
                                    src='https://www.demo.wipl.co.in/honda/home-icon-4.png'
                            />
                            <div className='txtdgray f16 f14sm fw-bold'>
                                ISO 9001 & ISO 14001
                            </div>
                            <div className='txtlgeay'>
                                certified company
                            </div>
                        </div>
                        <div className='col-lg-4 col-6 text-center mb-2 mt-3'>
                            <LazyLoadImage
                                    alt=""
                                    effect="blur"
                                    className='img-fluid mb-3'
                                    src='https://www.demo.wipl.co.in/honda/home-icon-5.png'
                                    style={{maxWidth:'75px'}}
                            />
                            <div className='txtdgray f16 f14sm fw-bold'>
                                ISO 9001 & ISO 14001
                            </div>
                            <div className='txtlgeay'>
                                certified company
                            </div>
                        </div>
                        <div className='col-lg-4 col-6 text-center mb-2 mt-3'>
                            <LazyLoadImage
                                    alt=""
                                    effect="blur"
                                    className='img-fluid mb-3'
                                    style={{maxWidth:'75px'}}
                                    src='https://www.demo.wipl.co.in/honda/home-icon-6.png'
                            />
                            <div className='txtdgray f16 f14sm fw-bold'>
                                ISO 9001 & ISO 14001
                            </div>
                            <div className='txtlgeay'>
                                certified company
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default React.memo(WhyChoseUsCel)