import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FotterMain from '../BaseComponents/FotterMain'
import Header from '../BaseComponents/Header'
import { getCityList, getStateList } from '../../networkCalls';

const validationSchema = yup.object({
  productName: yup
    .string('Enter your email')
    .required('Enter your email or mobile phone number'),
});
const drawerWidth = {
  width: '800px',
  '@media(minWidth: 780px)': {
    width: '320px'
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: drawerWidth,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const productList = [
  {
    value: 7,
    label: 'Generators',
  },
  {
    value: 6,
    label: 'Water Pumps',
  },
  {
    value: 5,
    label: 'Tillers',
  },
  {
    value: 4,
    label: 'Brush Cutters',
  },
  {
    value: 3,
    label: 'General Purpose Engines',
  },
  {
    value: 2,
    label: 'Lawn Mowers',
  },
];

const ServiceNetwork = () => {

  // for result modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ChildModal = () => {
    return (
      <React.Fragment>
        <Modal
          //hideBackdrop
          open={open}
          //onClose={handleClose}
          aria-labelledby="title"
          aria-describedby="result-modal"
        >
          <Box sx={{...style, width: { xs: 300, sm: 500 }, }}>
            <IconButton
              onClick={handleClose}
              size="medium"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ float: 'right', position: 'absolute', right: '20px', top: '20px', zIndex: '3' }}
            >
              <CancelIcon />
            </IconButton>
            <div>
              <div className='row'>
                <div className='col-12 mb-3'>
                  <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                    <span className='txtred'>NEAREST</span> <span className='txtdgray'>DEALER</span>
                  </h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 mb-1 f12'>
                  <h6 className='mb-0'>BAY ISLAND TRADING & MARINE SER.</h6>
                  <div>Address : 6 & 7, GANDHI BHAVAN, PORT BLAIR</div>
                  <div>Pin Code : 744 101</div>
                  <div>Phone No. : 234066 , 94342 81032</div>
                  <div>Email. : btmarine@rediffmail.com</div>
                  <hr />
                </div>
                <div className='col-12 mb-1'>
                   <h6 className='mb-0'>BAY ISLAND TRADING & MARINE SER.</h6>
                  <div>Address : 6 & 7, GANDHI BHAVAN, PORT BLAIR</div>
                  <div>Pin Code : 744 101</div>
                  <div>Phone No. : 234066 , 94342 81032</div>
                  <div>Email. : btmarine@rediffmail.com</div>
                  <hr />
                </div>
                <div className='col-12 mb-1'>
                  <h6 className='mb-0'>BAY ISLAND TRADING & MARINE SER.</h6>
                  <div>Address : 6 & 7, GANDHI BHAVAN, PORT BLAIR</div>
                  <div>Pin Code : 744 101</div>
                  <div>Phone No. : 234066 , 94342 81032</div>
                  <div>Email. : btmarine@rediffmail.com</div>
                  <hr />
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
    );
  }
  // end result modal

  const [stateList, setStateList] = React.useState([]);
  const pullStateList = (value) => {
    var data = new FormData();
    data.append('countryId', 'IND');
    getStateList(data).then((res) => {
      console.log("res.data ", res.data);
      switch (res.data.status) {
        case 1:
          setStateList(res.data.stateList);
          return true;
        case 0:
          break;
        default:
          return;
      }
    })
  }

  const [state, setState] = useState('');
  const [cityList, setCityList] = React.useState([]);
  const pullCityList = (value) => {
    var data = new FormData();
    data.append('stateId', value);
    getCityList(data).then((res) => {
      //  console.log("res.data ", res.data);
      switch (res.data.status) {
        case 1:
          setCityList(res.data.cityList);
          return true;
        case 0:
          break;
        default:
          return;
      }
    })
  }

  const stateChange = (e, value) => {
    // console.log(value);
    setState(value?.id)
    pullCityList(value?.id)
  };
  const [city, setCity] = useState('');
  const cityChange = (e, value) => {
    // console.log(value);
    setCity(value?.id);
  };

  const formik = useFormik({
    initialValues: {
      productName: '',
      dType:'sales',

    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var data = new FormData();
      data.append('productName', values.productName);
      data.append('state', state);
      data.append('city', city);
      data.append('dType', values.dType);
      for (var pair of data.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
      setOpen(true);
    },
  });

  useEffect(() => {
    pullStateList();
  }, []);

  return (
    <>
      <Header />
      <div className='innerpagebg pt-5 pb-5'>
        <div className='container mb-4'>
          <div className='row'>
            <div className='col-12  m-auto'>
              <div className="align-items-center text-center">
                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                  <span className='txtred'>DEALER</span> <span className='txtdgray'>NETWORK</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row '>
            <div className='col-lg-8 col-mg-10 col-sm-12 col-12 m-auto'>
              <div className='card  p-3 rounded-0 shadow-sm'>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <TextField
                        required
                        id="productName"
                        className='w-100 mb-3'
                        select
                        label="Select Product"
                        name="productName"
                        variant="standard"
                        value={formik.values.productName}
                        onChange={formik.handleChange}
                        error={formik.touched.productName && Boolean(formik.errors.productName)}
                        helperText={formik.touched.productName && formik.errors.productName}
                      >
                        {productList.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className='col-12 col-md-6'>
                      <Autocomplete
                        disablePortal
                        id="state"
                        className='mb-4'
                        name="state"
                        options={stateList}
                        onChange={stateChange}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField required variant="standard" {...params} label="Select State" />}
                      />
                    </div>
                    <div className='col-12 col-md-6'>
                      <Autocomplete
                        disablePortal
                        className='mb-4'
                        id="city"
                        name="city"
                        options={cityList}
                        onChange={cityChange}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField required variant="standard" {...params} label="Select City" />}
                      />
                    </div>
                    <div className='col-12 col-md-6'>
                      <FormControl>
                        <FormLabel id="label" className='f10'>Dealer Type</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="dealer-type"
                          defaultValue="sales"
                          name="dType"
                          value={formik.values.dType}
                          onChange={formik.handleChange}
                        >
                          <FormControlLabel value="sales" control={<Radio />} label="Sales" />
                          <FormControlLabel value="service" control={<Radio />} label="Service" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                  </div>

                  <Button color="error" variant="contained" className='w-100' type="submit">
                    View List
                  </Button>
                </form>
                <ChildModal />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FotterMain />
    </>
  )
}

export default ServiceNetwork