import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { useStateValue } from '../../store';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';
import { deleteCustomerAddress } from '../../networkCalls';
const AddressViewCell = ({itemIn, addressBackIn}) => {
    const navigate = useNavigate();
    const [{ token, huid }] = useStateValue();
    const editAddressCall = () =>{
        navigate("/myaccount/edit-address", {state:{itemIn}});
    }

    const deleteAddress = (value) => {
       // console.log('val', value)
        var data = new FormData();
        data.append('customerId', huid);
        data.append('XAuth', token);
        data.append('addressId', value);
         for (var pair of data.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        deleteCustomerAddress(data).then((res) => {
             console.log("res.data ", res.data);
            switch (res.data.status) {
                case 1:
                    addressBackIn(true);
                    return true;
                case 0:
                    break;
                default:
                return;
            }
        })
        //
    }
    

    
    return (
        <>
            <div className='card p-3 mb-3'>
                <div className="d-flex align-items-center">
                    <div>
                        <label className="fw-bold">Zipcode: {itemIn?.zipcode}</label><br/>
                        {itemIn?.city}, {itemIn?.state}, {itemIn?.country}
                    </div>
                    <div className="ms-auto">
                        <Dropdown align="end" className="d-inline-block" >
                            <Dropdown.Toggle as='div' id="gt" className="p-2 round-edit remove-tringle">
                                <MoreVertIcon className="text-primary" />
                            </Dropdown.Toggle>
                            {/* addressId */}
                            <Dropdown.Menu className="ddl-menu">
                                <div className="ddl-item-cardedit">
                                    <Dropdown.Item onClick={editAddressCall}
                                    ><ModeEditIcon className="text-primary f16 me-1" />Edit </Dropdown.Item>
                                    <hr className="mt-2 mb-1" />
                                    <Dropdown.Item eventKey="1"
                                     onClick={() => deleteAddress(itemIn?.addressId)}
                                    ><DeleteOutlineIcon className="text-danger f16 me-1" />Delete</Dropdown.Item>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-md-12 col-12">
                    <hr />
                </div>
                <div className='row'>
                    <div className="col-md-12 col-12 mb-2">
                        <label className="f14 d-block"><span className='bglblue p-1 ps-2 pe-2 f12 me-2 text-uppercase'>{itemIn?.addressType}</span>{itemIn?.addressLine1} | {itemIn?.addressLine2}</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddressViewCell