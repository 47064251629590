import React from 'react'
import { Link, useParams } from "react-router-dom";
import Header from '../BaseComponents/Header';
import FotterMain from '../BaseComponents/FotterMain';

const SinglePage = () => {
    const { pageSlug } = useParams();
    // console.log('pageSlug---', pageSlug);
    return (
        <>
            <Header />
            
            <div className="post-view__header post-header post-header--has-image">
                <div className="post-header__image"></div>
                {/* <div className="post-header__image" style="background-image: url('https://red-parts.react.themeforest.scompiler.ru/themes/red/images/posts/post-1.jpg);"></div> */}
                <div className="post-header__body">
                    <div className="post-header__categories">
                        <div className="post-header__categories-list">
                            <div className="post-header__categories-item mb-3"><Link className="post-header__categories-link text-white bgred rounded f12 p-2 pt-1 pb-1" to="/press-release">Press Release</Link></div>
                        </div>
                    </div>
                    <h3 className="post-header__title">Honda India Power Products Limited reaches 5 Million Units Production Milestone in February 2022</h3>
                    <div className="post-header__meta">
                        <ul className="post-header__meta-list">
                            <li className="post-header__meta-item">28 February, 2022</li>
                        </ul>
                    </div>
                </div>
                <div className="decor decor--type--bottom post-header__decor">
                    <div className="decor__body">
                        <div className="decor__start"></div>
                        <div className="decor__end"></div>
                        <div className="decor__center"></div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-9  m-auto'>
                        <div className='post-view__body card rounded-0 border-0 shadow-sm p-4'>
                            {/* content here */}
                            <div class="cm-content prscot">
                                <div class="pressDatenDownloads">
                                    <div class="pressDownloadOptions">
                                        <ul>
                                            <li>
                                                <a target="_blank" class="pressbtnReadMore" href="https://www.hondaindiapower.com/uploads/pdf/49/Press_Release_-_HIPP_5_Million_unit_production_achievement_20220225_FINAL-converted.pdf" download=""><span><img src="https://www.hondaindiapower.com/front/media/pdf-icon.png" alt="brochure icon" width="20" /></span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p><strong>Proudly “Making in India” since 1988</strong></p>
                                <p><em>Leading manufacturer of Generators, Agriculture implements &amp; Engines for various equipment</em></p>
                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tbody>
                                        <tr>
                                            <td width="33%">
                                                <img alt="Product" className='img-fluid' src="https://www.hondaindiapower.com/front/media/production_milestone_img01.png" />
                                            </td>
                                            <td width="33%">
                                                <img alt="Product" className='img-fluid' src="https://www.hondaindiapower.com/front/media/production_milestone_img02.jpg" />
                                            </td>
                                            <td width="33%" colspan="3">
                                                <img alt="Product" className='img-fluid' src="https://www.hondaindiapower.com/front/media/production_milestone_img03.jpg" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="tblprs">
                                    <tbody>
                                        <tr>
                                            <td width="20%">
                                                <img alt="Product" className='img-fluid' src="https://www.hondaindiapower.com//front/media/production_milestone01.png" />
                                            </td>
                                            <td width="20%">
                                                <img alt="Product" className='img-fluid' src="https://www.hondaindiapower.com//front/media/production_milestone02.png" />
                                            </td>
                                            <td width="20%">
                                                <img alt="Product" className='img-fluid' src="https://www.hondaindiapower.com//front/media/production_milestone03.png" />
                                            </td>
                                            <td width="20%">
                                                <img alt="Product" className='img-fluid' src="https://www.hondaindiapower.com//front/media/production_milestone04.png" />
                                            </td>
                                            <td width="20%">
                                                <img alt="Product" className='img-fluid' src="https://www.hondaindiapower.com//front/media/production_milestone05.png" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br /><br />
                                <p><strong>New Delhi, 28th February 2022 –</strong> Honda India Power Products Limited (HIPP), a leading manufacturer of power products in India, successfully crossed 5 million units accumulated production milestone in February 2022. HIPP has been consistently introducing latest technology products with global standards and bringing joy to its customers since 1985. The company has been a trusted partner in progress for its valued customers in line with its vision of <em>“Empower People, To Do Better”</em>.</p>
                                <p>HIPP launched its first product namely Portable Generator Model EM650 manufactured at its Rudrapur factory in 1988. Buoyed by a strong demand from the customers, the company soon launched other well differentiated Generator models catering to the varying demands from a vast and well diversified market. HIPP made foray into the export markets in 1989 and its products were very well received in the competitive overseas markets. After having enlightened lives of many customers in domestic and overseas markets, HIPP made foray into agriculture by starting production of Engine range in 1991 followed by Water Pump range in 1992. Farmers across India immensely appreciated portable, reliable, and efficient Engines and Water Pumps meeting their timely irrigation, spraying and mechanisation needs. In the year 1995, HIPP established its second manufacturing plant at Puducherry to meet the ever-growing demand. HIPP undertook a major reconsolidation exercise in 2000 and amalgamated its production plants into its present “State-of-the-art” manufacturing facility at Greater Noida thereby achieving economies of scale while pursuing its mission of “Helping people get things done” by manufacturing products that have successfully established themselves as a benchmark for their performance and quality.</p>
                                <p><strong>Takahiro Ueda, Chairman &amp; Managing Director, President &amp; CEO, Honda India Power Products Limited said,</strong> “We were able to reach 5 million production units thanks to the support and the cooperation of all the people in India and the local society. Our suppliers and partners stood by us and believed in our common vision – delivering continuously even in acute times such as pandemic and I am grateful for their efforts all along. Our network of dealers, channel partners played excellent role as our extended arms in providing 3S - Sales, Service and Spare Parts support ensuring delightful experience of our customers all along. HIPP will continue to contribute to India and its society as a company that is expected to exist”.</p>
                                <p><strong>Honda India Power Products Limited Major Milestones:</strong></p>
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="table table-sm">
                                    <tbody>
                                        <tr>
                                            <td><strong>Year</strong></td>
                                            <td><strong>Month</strong></td>
                                            <td><strong>Events</strong></td>
                                        </tr>
                                        <tr>
                                            <td>1985</td>
                                            <td>Sep</td>
                                            <td>Company incorporated</td>
                                        </tr>
                                        <tr>
                                            <td>1988</td>
                                            <td>Feb</td>
                                            <td>Manufacturing starts in Rudrapur, Uttarakhand with Portable Generators</td>
                                        </tr>
                                        <tr>
                                            <td>1989</td>
                                            <td>Mar</td>
                                            <td>Started export of HIPP manufactured products</td>
                                        </tr>
                                        <tr>
                                            <td>1991</td>
                                            <td>Feb</td>
                                            <td>Started production of Kerosene Fueled Engines</td>
                                        </tr>
                                        <tr>
                                            <td>1992</td>
                                            <td>Feb</td>
                                            <td>Foray into agriculture sector with introduction of Portable Water Pumps</td>
                                        </tr>
                                        <tr>
                                            <td>1995</td>
                                            <td>Sep</td>
                                            <td>Second manufacturing plant established in Puducherry</td>
                                        </tr>
                                        <tr>
                                            <td>2000</td>
                                            <td>Dec</td>
                                            <td>Manufacturing consolidated at Greater Noida Factory in Uttar Pradesh</td>
                                        </tr>
                                        <tr>
                                            <td>2002</td>
                                            <td>Jul</td>
                                            <td>HIPP reaches 1 million cumulative Production</td>
                                        </tr>
                                        <tr>
                                            <td>2009</td>
                                            <td>Jun</td>
                                            <td>HIPP reaches 2 million cumulative Production</td>
                                        </tr>
                                        <tr>
                                            <td>2013</td>
                                            <td>Feb</td>
                                            <td>Started the production of GX Engines</td>
                                        </tr>
                                        <tr>
                                            <td>2013</td>
                                            <td>Sep</td>
                                            <td>HIPP reaches 3 million cumulative Production</td>
                                        </tr>
                                        <tr>
                                            <td>2016</td>
                                            <td>Apr</td>
                                            <td>Started the production of Power Tiller F300</td>
                                        </tr>
                                        <tr>
                                            <td>2018</td>
                                            <td>Aug</td>
                                            <td>HIPP reaches 4 million cumulative Production</td>
                                        </tr>
                                        <tr>
                                            <td>2020</td>
                                            <td>Sep</td>
                                            <td>HIPP celebrates 35<sup>th</sup> Anniversary</td>
                                        </tr>
                                        <tr>
                                            <td><strong>2022</strong></td>
                                            <td>Feb</td>
                                            <td>Cumulative production cross 5 million units</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br /><br />
                                <p><strong>About Honda India Power Products Limited:</strong></p>
                                <p>HIPP is one of the first Japanese companies to enter the Indian market in 1985 and thereby contribute to strengthen economic relations between India and Japan. Since inception, the company has been providing innovative, eco-friendly &amp; best-in-class products to its customers across India in the field of power backup, agriculture &amp; construction.</p>
                                <p>
                                    As a conscious corporate, the company has participated in community development programs and helped create employment opportunities at its Greater Noida factory. HIPP has regularly supported disaster relief operations across various regions of India, including recently, extending assistance for combating the Covid-19 pandemic.
                                </p>
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="table table-sm">
                                    <tbody>
                                        <tr>
                                            <td><strong>Company name</strong></td>
                                            <td><strong>Honda India Power Products Limited</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Shareholding</td>
                                            <td>Honda Motor Co., Ltd. : 66.7%, Public: 33.3%</td>
                                        </tr>
                                        <tr>
                                            <td>Established</td>
                                            <td>September 1985</td>
                                        </tr>
                                        <tr>
                                            <td>Capital</td>
                                            <td>INR 101.4 million</td>
                                        </tr>
                                        <tr>
                                            <td>Location</td>
                                            <td>Greater Noida, Uttar Pradesh</td>
                                        </tr>
                                        <tr>
                                            <td>Representative</td>
                                            <td>Takahiro Ueda – CMD, President &amp; CEO</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Employees</td>
                                            <td>Approx. 1,400</td>
                                        </tr>
                                        <tr>
                                            <td>Main Production Models</td>
                                            <td>Portable Generators, Water Pumps, Engines, Tillers</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Production Capacity</td>
                                            <td>350,000 units</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* content here */}

                        </div>
                    </div>
                </div>
            </div>


            <div style={{ clear: 'both', height: '40px' }}></div>
            <FotterMain />
        </>
    )
}

export default SinglePage