import React from 'react'
import TextField from '@mui/material/TextField';
import Header from '../BaseComponents/Header'
import FotterMain from '../BaseComponents/FotterMain';
import Button from '@mui/material/Button';

const Calculator = () => {
  const [cfl, setCFL] = React.useState(null);
  const [cflload, setCFLLoad] = React.useState(null);
  const cflChange = e => {
    if (e.target.value.split("").length <= 3) {
      setCFL(e.target.value);
    }
    setCFLLoad(e.target.value * 20)
  };

  const [tubeL, setTubeL] = React.useState(null);
  const [tubeLload, setTubeLload] = React.useState(null);
  const tubeLChange = e => {
    if (e.target.value.split("").length <= 3) {
      setTubeL(e.target.value);
    }
    setTubeLload(e.target.value * 40)
  };

  const [fanL, setFanL] = React.useState(null);
  const [fabload, setFabload] = React.useState(null);
  const fanChange = e => {
    if (e.target.value.split("").length <= 3) {
      setFanL(e.target.value);
    }
    setFabload(e.target.value * 45)
  };

  const [buldL, setBuldL] = React.useState(null);
  const [bulbload, setBulbload] = React.useState(null);
  const buldChange = e => {
    if (e.target.value.split("").length <= 3) {
      setBuldL(e.target.value);
    }
    setBulbload(e.target.value * 60)
  };

  const [tvL, settvL] = React.useState(null);
  const [tvload, settvload] = React.useState(null);
  const tvLChange = e => {
    if (e.target.value.split("").length <= 3) {
      settvL(e.target.value);
    }
    settvload(e.target.value * 120)
  };

  const [refL, setRefL] = React.useState(null);
  const [refload, setRefload] = React.useState(null);
  const refLChange = e => {
    if (e.target.value.split("").length <= 3) {
      setRefL(e.target.value);
    }
    setRefload(e.target.value * 600)
  };

  const [coolerL, setCoolerL] = React.useState(null);
  const [coolerload, setCoolerload] = React.useState(null);
  const coolerLChange = e => {
    if (e.target.value.split("").length <= 3) {
      setCoolerL(e.target.value);
    }
    setCoolerload(e.target.value * 1200)
  };

  const [motorL, setMotorL] = React.useState(null);
  const [motorload, setMotorload] = React.useState(null);
  const motorLChange = e => {
    if (e.target.value.split("").length <= 3) {
      setMotorL(e.target.value);
    }
    setMotorload(e.target.value * 2000)
  };

  const [geyserL, setGeyserL] = React.useState(null);
  const [geyserload, setGeyserload] = React.useState(null);
  const geyserLChange = e => {
    if (e.target.value.split("").length <= 3) {
      setGeyserL(e.target.value);
    }
    setGeyserload(e.target.value * 2000)
  };

  const [acL, setAcL] = React.useState(null);
  const [acload, setAcload] = React.useState(null);
  const acLChange = e => {
    if (e.target.value.split("").length <= 3) {
      setAcL(e.target.value);
    }
    setAcload(e.target.value * 2500)
  };

  
  var totalLoad = cflload + tubeLload + fabload + bulbload + tvload + refload + coolerload + motorload + geyserload + acload;
  const enabledbtn = totalLoad > 0;

  const setClear = () =>{
    setCFL('');
    setCFLLoad('')
    setTubeL('');
    setTubeLload('')
    setFanL('');
    setFabload('');
    setBuldL('');
    setBulbload('');
    settvL('');
    settvload('');
    setRefL('');
    setRefload('');
    setCoolerL('');
    setCoolerload('');
    setMotorL('');
    setMotorload('');
    setGeyserL('');
    setGeyserload('');
    setAcL('');
    setAcload('');
  }

  return (
    <>
      <Header />
      <div className='innerpagebg pt-5 pb-5'>
        <div className='container mb-4'>
          <div className='row'>
            <div className='col-12  m-auto'>
              <div className="align-items-center text-center">
                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                  <span className='txtred'>MODEL</span> <span className='txtdgray'>CALCULATOR</span>
                </h4>
                <p className='mt-2 f16'>(ONLY FOR GENSETS)</p>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row '>
            <div className='col-lg-12 col-mg-12 col-sm-12 col-12 text-center m-auto'>
              <div className='card p-3 rounded-0 shadow-sm table-responsive'>
                <table border="0" className='table table-hover table-sm'>
                  <tbody><tr>
                    <th style={{ width: '100px' }}> S.No.</th>
                    <th>Appliance*</th>
                    <th>Load</th>
                    <th>Starting Load</th>
                    <th style={{ width: '150px' }}>Quantity</th>
                  </tr>
                    <tr>
                      <td>1</td>
                      <td>CFL</td>
                      <td>20 W</td>
                      <td>20</td>
                      <td>
                        <TextField
                          className='mb-0 w-100'
                          variant="standard"
                          type="number"
                          inputProps={{
                            maxLength: 3
                          }}
                          id="cfl"
                          name="cfl"
                          label=""
                          value={cfl}
                          onChange={cflChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Tube Lights</td>
                      <td>40 W</td>
                      <td>40</td>
                      <td>
                        <TextField
                          className='mb-0 w-100'
                          variant="standard"
                          type="number"
                          inputProps={{
                            maxLength: 3
                          }}
                          id="tubeL"
                          name="tubeL"
                          label=""
                          value={tubeL}
                          onChange={tubeLChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Fan</td>
                      <td>45 W</td>
                      <td>45</td>
                      <td> <TextField
                        className='mb-0 w-100'
                        variant="standard"
                        type="number"
                        inputProps={{
                          maxLength: 3
                        }}
                        id="fanL"
                        name="fanL"
                        label=""
                        value={fanL}
                        onChange={fanChange}
                      /></td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Bulb</td>
                      <td>60 W</td>
                      <td>60</td>
                      <td>
                        <TextField
                          className='mb-0 w-100'
                          variant="standard"
                          type="number"
                          inputProps={{
                            maxLength: 3
                          }}
                          id="buldL"
                          name="buldL"
                          label=""
                          value={buldL}
                          onChange={buldChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>TV</td>
                      <td>120 W</td>
                      <td>120</td>
                      <td>
                        <TextField
                          className='mb-0 w-100'
                          variant="standard"
                          type="number"
                          inputProps={{
                            maxLength: 3
                          }}
                          id="tvL"
                          name="tvL"
                          label=""
                          value={tvL}
                          onChange={tvLChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Refrigerator</td>
                      <td>200 W</td>
                      <td>600</td>
                      <td>
                        <TextField
                          className='mb-0 w-100'
                          variant="standard"
                          type="number"
                          inputProps={{
                            maxLength: 3
                          }}
                          id="refL"
                          name="refL"
                          label=""
                          value={refL}
                          onChange={refLChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td> Air Cooler</td>
                      <td>400 W</td>
                      <td>1200</td>
                      <td>
                        <TextField
                          className='mb-0 w-100'
                          variant="standard"
                          type="number"
                          inputProps={{
                            maxLength: 3
                          }}
                          id="coolerL"
                          name="coolerL"
                          label=""
                          value={coolerL}
                          onChange={coolerLChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td> Water Motor</td>
                      <td>750 W</td>
                      <td>2000</td>
                      <td>
                        <TextField
                          className='mb-0 w-100'
                          variant="standard"
                          type="number"
                          inputProps={{
                            maxLength: 3
                          }}
                          id="motorL"
                          name="motorL"
                          label=""
                          value={motorL}
                          onChange={motorLChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Geyser</td>
                      <td>2000 W</td>
                      <td>2000</td>
                      <td>
                        <TextField
                          className='mb-0 w-100'
                          variant="standard"
                          type="number"
                          inputProps={{
                            maxLength: 3
                          }}
                          id="geyserL"
                          name="geyserL"
                          label=""
                          value={geyserL}
                          onChange={geyserLChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>AC (1.5 T ***** Rating)</td>
                      <td>2100 W</td>
                      <td>2500</td>
                      <td>
                        <TextField
                          className='mb-0 w-100'
                          variant="standard"
                          type="number"
                          inputProps={{
                            maxLength: 3
                          }}
                          id="acL"
                          name="acL"
                          label=""
                          value={acL}
                          onChange={acLChange}
                        />
                      </td>
                    </tr>
                    <tr className='bgsgray fw-bold'>
                      <td colspan="3">&nbsp;</td>
                      <td>Total Load</td>
                      <td>{totalLoad}</td>
                    </tr>
                  </tbody></table>
                <div className='text-end'>
                  <Button color="error" disabled={!enabledbtn} variant="contained" className='' size='small' type="submit">
                    Submit
                  </Button>
                  <Button color="error" onClick={() => setClear()} variant="text" className='ms-3' size='small' type="submit">
                    clear 
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FotterMain />
    </>
  )
}

export default Calculator