import React from 'react'
import Header from '../BaseComponents/Header'
import FotterMain from '../BaseComponents/FotterMain';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { baseURL } from "../../networkCalls/index";
const ExtendedWarranty = () => {
    return (
        <>
            <Header />
            <div className='innerpagebg pt-5 pb-5'>
                <div className='container mb-4'>
                    <div className='row'>
                        <div className='col-12  m-auto'>
                            <div className="align-items-center text-center">
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>EXTENDED</span> <span className='txtdgray'>WARRANTY</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row '>
                        <div className='col-lg-10 col-mg-12 col-sm-12 col-12  m-auto'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-lg-7 pe-lg-0'>
                                    <div className='card p-5 rounded-0 shadow-sm'>
                                        <div className='txtred f28'>WHY SHOULD I PURCHASE AN</div>
                                        <div className='fw-bold f28 mt-3'>EXTENDED WARRANTY</div>
                                        <div className='mt-3 f16'>Upto 2 years* Standard Warranty + Extended Warranty upto 2 years*</div>
                                    </div>
                                </div>
                                <div className='col-lg-5 ps-lg-0'>
                                    <div className='card rounded-0 shadow-sm'>
                                        <div className='p-4 d-flex align-items-center'>
                                            <div><LazyLoadImage
                                                alt=""
                                                effect="blur"
                                                className='img-fluid me-2'
                                                style={{ maxWidth: '60px' }}
                                                src={`${baseURL}images/warrenty-icon-1.png`}
                                            /></div>
                                            <div className='f16'>Peace of mind assured with Honda<br />authorised service center.</div>
                                        </div>
                                        <hr className='m-0 p-0' />
                                        <div className='p-4 d-flex align-items-center bgsgray'>
                                            <div><LazyLoadImage
                                                alt=""
                                                effect="blur"
                                                className='img-fluid me-2'
                                                style={{ maxWidth: '60px' }}
                                                src={`${baseURL}images/warrenty-icon-2.png`}
                                            /></div>
                                            <div className='f16'>Convenient and hassle free service<br /> at 2780 + locations in India.</div>
                                        </div>
                                        <hr className='m-0 p-0' />
                                        <div className='p-4 d-flex align-items-center'>
                                            <div><LazyLoadImage
                                                alt=""
                                                effect="blur"
                                                className='img-fluid me-2'
                                                style={{ maxWidth: '60px' }}
                                                src={`${baseURL}images/warrenty-icon-3.png`}
                                            /></div>
                                            <div className='f16'>Coverage includes labor cost<br /> and spare parts.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <p className='mt-5 text-center f12'>*Extended Warranty is applicable to Generators only. 2 Years Standard Warranty is applicable on Select Models only.</p>
                        </div>
                    </div>
                </div>
            </div>
            <FotterMain />
        </>
    )
}
export default React.memo(ExtendedWarranty)