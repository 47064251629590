import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { QueryClient, QueryClientProvider } from "react-query";
import { actionTypes, useStateValue } from "./store";
import { useCookies } from "react-cookie";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Modal } from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Home from './pages/Home';
import Dashboard from "./pages/Myaccount/Dashboard";
import LoginForm from "./pages/Login/LoginForm";
import CloseIcon from '@mui/icons-material/Close';
import AboutUs from "./pages/StaticPages/AboutUs";
import PrivacyPolicy from "./pages/StaticPages/PrivacyPolicy";
import Compare from "./pages/Compare/Compare";
import EditProfile from "./pages/Myaccount/EditProfile";
import AddBusinessProfile from "./pages/Myaccount/AddBusinessProfile";
import ManageAddresses from "./pages/Myaccount/ManageAddresses";
import UpdateAddress from "./pages/Myaccount/UpdateAddress";
import MyWishList from "./pages/Myaccount/MyWishList";
import MyOrders from "./pages/Myaccount/MyOrders";
import OrderDetails from "./pages/Myaccount/OrderDetails";
import Page from "./pages/Investors/Page";
import ProductDetail from "./pages/Product/ProductDetail";
import EditAddress from "./pages/Myaccount/EditAddress";
import CategoryList from "./pages/Category/CategoryList";
import Checkout from "./pages/Cart/Checkout";
import SinglePage from "./pages/PressRelease/SinglePage";
import PressReleaseListing from "./pages/PressRelease/PressReleaseListing";
import InvestorsListing from "./pages/Investors/InvestorsListing";
import OnlinePayment from "./pages/StaticPages/OnlinePayment";
import ContactUs from "./pages/StaticPages/ContactUs";
import ServiceNetwork from "./pages/StaticPages/ServiceNetwork";
import Calculator from "./pages/StaticPages/Calculator";
import HondaGenuineOil from "./pages/StaticPages/HondaGenuineOil";
import ExtendedWarranty from "./pages/StaticPages/ExtendedWarranty";
import ExportsLP from "./pages/StaticPages/Exports/ExportsLP";
import CareersLP from "./pages/Careers/CareersLP";
import WhyBuyHonda from "./pages/StaticPages/WhyBuyHonda";
import NoFound from "./pages/StaticPages/NoFound";



const queryClient = new QueryClient();
function App() {

   {/* for login popup */}
  const handleCloseLogin = () => {
    dispatch({ type: actionTypes.SET_LOGINMODAL, value: false });
  }
   {/* for login popup */}
  
  const onBlur = () => {
    //alert('hi')
   // console.log("Tab is in onBlur");
  };
  // User has switched back to the tab
  const onFocus = () => {
    //console.log("Tab is in focus");
  };

  // for snackbar msgalert alert
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'bottom',
  });
  const { vertical, horizontal } = state;
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  // end snackbar msgalert alert

  const [cookie] = useCookies(["jwt", "huid"]);
  const [{ token, loginmodal, msgalert, msgalertMessage, msgalertType }, dispatch] = useStateValue();
  useEffect(() => {

    const setToken = () => {
      const { jwt, huid } = cookie;
      
      if (jwt) {
       // dispatch({ type: actionTypes.SET_TOKEN, value: jwt });
        var temp_xID  = CryptoJS.AES.decrypt(jwt, 'HON_MX46d67ee5d05');
        var xID = temp_xID.toString(CryptoJS.enc.Utf8);
        dispatch({ type: actionTypes.SET_TOKEN, value: xID });
      }
      if (huid) {
       // dispatch({ type: actionTypes.SET_HUID, value: huid });
        var temp_uType  = CryptoJS.AES.decrypt(huid, 'HON_MX46d67ee5d05');
        var xUType = temp_uType.toString(CryptoJS.enc.Utf8);
        dispatch({ type: actionTypes.SET_HUID, value: xUType });
      }
    };
    if (token === null) {
      setToken();
    }




    // window.addEventListener("focus", onFocus);
    // window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    //onFocus();
    // Specify how to clean up after this effect:
    // return () => {
    //     window.removeEventListener("focus", onFocus);
    //     window.removeEventListener("blur", onBlur);
    // };


  }, [dispatch, token, cookie]);

  const handleCloseMsgalert = () => {
    dispatch({ type: actionTypes.SET_MSGALERT, value: false });
  }


  
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />

          <Route path='/myaccount/dashboard' element={token ? <Dashboard /> : <p>Plasse Login</p>} />
          <Route path='/myaccount/edit-profile' element={token ? <EditProfile /> : <p>Plasse Login</p>} />
          <Route path='/myaccount/add-business-profile' element={token ? <AddBusinessProfile /> : <p>Plasse Login</p>} />
          <Route path='/myaccount/manage-addresses' element={token ? <ManageAddresses /> : <p>Plasse Login</p>} />
          <Route path='/myaccount/update-address' element={token ? <UpdateAddress /> : <p>Plasse Login</p>} />
          <Route path='/myaccount/manage-wishlist' element={token ? <MyWishList /> : <p>Plasse Login</p>} />
          <Route path='/myaccount/manage-orders' element={token ? <MyOrders /> : <p>Plasse Login</p>} />
          <Route path='/myaccount/order-details' element={token ? <OrderDetails /> : <p>Plasse Login</p>} />
          <Route path='/myaccount/edit-address' element={token ? <EditAddress /> : <p>Plasse Login</p>} />
          <Route path='/product-detail/:productID' element={<ProductDetail />} />
          <Route path='/product-category/:categorySlug' element={<CategoryList />} />
          <Route path='/compare' element={<Compare />} />
          <Route path='/checkout' element={<Checkout />} />
          

          <Route path='/investors/' element={<InvestorsListing/>} />
          <Route path='/investors/:investorURL' element={<Page/>} />
          
          <Route path='/press-release' element={<PressReleaseListing />} />
          <Route path='/press-release/:pageSlug' element={<SinglePage />} />
      
          {/* for static page */}
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/why-buy-a-honda' element={<WhyBuyHonda />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/service-network' element={<ServiceNetwork />} />
          <Route path='/calculator' element={<Calculator />} />
          <Route path='/honda-genuine-oil' element={<HondaGenuineOil />} />
          <Route path='/extended-warranty' element={<ExtendedWarranty />} />
          <Route path='/exports' element={<ExportsLP />} />

          <Route path='/careers' element={<CareersLP />} />
          
          
          <Route path='/online-payment' element={<OnlinePayment />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='*' element={<NoFound />} />
           {/* for static page */}
           
        </Routes>
      
      




        {/* for login popup */}
        <Modal
          size="lg"
          show={loginmodal}
          backdrop="static"
          keyboard={false}
          contentClassName='border-0 rounded-0'
          onHide={() => handleCloseLogin()}
          aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Body className="p-0">
                <IconButton onClick={() => handleCloseLogin()} aria-label="delete" size="small" className="position-absolute top-0 end-0">
                  <CloseIcon fontSize="small" />
                </IconButton>
                <LoginForm />
            </Modal.Body>
        </Modal>
        {/* for login popup */}

        {/* for alert pop */}
        <Snackbar open={msgalert} onClose={handleCloseMsgalert}  autoHideDuration={3000}  >
          <Alert onClose={handleCloseMsgalert} severity={msgalertType} sx={{ minWidth: 200 }}>
            {msgalertMessage}
          </Alert>
        </Snackbar>
       {/*  end for alert pop */}

       

        <CookieConsent
          location="bottom"
          buttonText="I accepted"
          enableDeclineButton
          onDecline={() => {
            // alert("nay!");
          }}
          onAccept={(acceptedByScrolling) => {
            if (acceptedByScrolling) {
              //alert("Accept was triggered by user scrolling");
            } else {
              // alert("Accept was triggered by clicking the Accept button");
            }
          }}
          cookieName="hondaCookiee"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          By using Honda Website, you agree to our Cookie Policy.{" "}
          <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
        </CookieConsent>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
