import React from 'react'
import { Helmet } from 'react-helmet-async';
import FotterMain from '../BaseComponents/FotterMain';
import Header from '../BaseComponents/Header'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="About Us" />
      </Helmet>
      <Header />
      <div className='innerpagebg pb-5'>
        <div className='about__image'>
          <div className='about__image-bg'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-4 col-12 mt-5'>
                  <div className='bg-white mt-5 p-5'>
                    <h4 className='d-block m-0 fw-bold text-uppercase mb-3 mt-2 text-center'>
                      <span className='txtred'>About</span> <span className='txtdgray'>Us</span>
                    </h4>
                    <div className='f18 text-center'>India's No. 1 Power Products company, Honda India Power Products Limited (HIPP), is a subsidiary of Honda Motor Co. Japan and was incorporated on 19th Sept 1985.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='bg-white shadow-sm'>
                <br /><br /><br /><br /><br />
              </div>
            </div>
            <div className='col-md-8'>
              <div className='row mt-5'>
                <div className='col-12 col-lg-4 d-flex'>
                  <div>
                    <img src={require("./../../assets/icons/hand-shake.png")} alt="" className='' style={{ width: '40px' }} />
                  </div>
                  <div className='ms-3'>
                    <span className='txtred fw-bold f18'>STRENGTH</span><br />600 DEALERS
                  </div>
                </div>
                <div className='col-12 col-lg-4 d-flex'>
                  <div>
                    <img src={require("./../../assets/icons/customer.png")} alt="" className='' style={{ width: '40px' }} />
                  </div>
                  <div className='ms-3'>
                    <span className='txtred fw-bold f18'>CUSTOMERS</span><br /> 5 MILLION+
                  </div>
                </div>
                <div className='col-12 col-lg-4 d-flex'>
                  <div>
                    <img src={require("./../../assets/icons/coronavirus.png")} alt="" className='' style={{ width: '40px' }} />
                  </div>
                  <div className='ms-3'>
                    <span className='txtred fw-bold f18'>EXPORT</span><br /> 35 COUNTRIES
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-4'>
            <div className='col-12 f14' style={{ lineHeight: '24px' }}>
              <p>Ever since, it has been the undisputed leader in the power products industry, manufacturing and marketing a range of Portable Generators, Water Pumps, Tillers and General Purpose Engine at its state-of-art manufacturing facility at Greater Noida. It is also engaged in the marketing of Lawn Mower, Brush Cutter and Long-tailed outboard motors.</p>
              <p>What started 31 years ago as a dream to serve our customers with products that truly help him do their work more efficiently has unfolded into a remarkable journey of achieving many milestones. HIPP has been the preferred choice of the customers worldwide and its existence is the result of the creation of the joy of buying, selling & empowering the dreams of over 2.5 million users which makes HIPP the undeniable leader in the power production industry.</p>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6'>
              <div className=''>
                <div className='f14 m-5' style={{ lineHeight: '24px' }}>
                  <h4 className='d-block m-0 fw-bold text-uppercase mb-0 mt-2 text-start f20'>
                    <span className='txtred'>CORE</span> <span className='txtdgray'>PHILOSOPHY</span>
                  </h4>
                  <p>"The power of dreams"</p>
                  Driven to the core by its philosophy of "The power of dreams", HIPP with the strength of 17 Area Offices across India and over 600 dealers have been continuously bringing joy and satisfaction through its range of power products that suit the requirements of a variety of customers segments in agriculture, domestic and commercial. This gives the customers the choice to demand more from the world leader "Honda".
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='card p-4 text-center mb-4 shadow-sm border-0'>
                    <LazyLoadImage
                      alt=""
                      effect="blur"
                      className='img-fluid mb-4 mt-3'
                      style={{ maxWidth: '75px' }}
                      src='https://www.demo.wipl.co.in/honda/home-icon-4.png'
                    />
                    <div className='txtdgray f16 f14sm fw-bold'>
                      Environmental friendly
                    </div>
                    <div className='txtlgeay'>
                      4-stroke technology
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='card p-4 text-center mb-4 shadow-sm border-0'>
                    <LazyLoadImage
                      alt=""
                      effect="blur"
                      className='img-fluid mb-4 mt-3'
                      style={{ maxWidth: '75px' }}
                      src='https://www.demo.wipl.co.in/honda/home-icon-5.png'
                    />
                    <div className='txtdgray f16 f14sm fw-bold'>
                      2500 company
                    </div>
                    <div className='txtlgeay'>
                      trained mechanics
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='card p-4 text-center mb-4 shadow-sm border-0'>
                    <LazyLoadImage
                      alt=""
                      effect="blur"
                      className='img-fluid mb-4 mt-3'
                      style={{ maxWidth: '75px' }}
                      src='https://www.demo.wipl.co.in/honda/home-icon-6.png'
                    />
                    <div className='txtdgray f16 f14sm fw-bold'>
                      Mobile service
                    </div>
                    <div className='txtlgeay'>
                      vans for prompt service
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='card p-4 text-center mb-4 shadow-sm border-0'>
                    <LazyLoadImage
                      alt=""
                      effect="blur"
                      className='img-fluid mb-4 mt-3'
                      style={{ maxWidth: '75px' }}
                      src='https://www.demo.wipl.co.in/honda/home-icon-3.png'
                    />
                    <div className='txtdgray f16 f14sm fw-bold'>
                      Automated
                    </div>
                    <div className='txtlgeay'>
                      service workshops
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='bglblue'>
          <div className='container pt-5 pb-5'>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <h4 className='d-block mb-3 fw-bold text-uppercase mb-0 mt-2 text-start f20'>
                  <span className='txtred'>TECHNOLOGY</span> <span className='txtdgray'>& CERTIFICATION</span>
                </h4>
                <p className='f14' style={{ lineHeight: '24px' }}>HIPP benefits from the rich experience of Honda Motor Co. Japan the second largest engine manufacturer in the world, because of their strong emphasis on R&D and in-house technical innovation.</p>
                <p className='f14' style={{ lineHeight: '24px' }}>The Ultra Silent, Self-start range of India's first "Pure Sine Wave EU series Generator" and the highly fuel-efficient range of OHV Engines are some of the products which exemplify Honda's pursuit of technological excellence. The product range of Generators conforms to the most stringent phase ll Noise & Emission norms as laid down by Central Pollution Control Board (CPCB), Government of India.</p>
                <p className='f14' style={{ lineHeight: '24px' }}>Every product manufactured is the result of persistence in quality, giving it the cutting edge for that extra reliability. It was in recognition of this that HIPP became the first power products manufacturing company in India to have been awarded the ISO 9001:2000 Certification for its Quality Assurance Systems & ISO 14001 for its Environment Management systems.</p>
              </div>
              <div className='col-12 col-lg-6'>
                Image Here
              </div>
            </div>
          </div>
        </div>
        <div className='container pt-5 pb-5'>
          <div className='row'>
            <div className='col-12'>
              <h4 className='d-block mb-3 fw-bold text-uppercase mb-0 mt-2 text-center f20'>
                <span className='txtred'>WHY</span> <span className='txtdgray'>US</span>
              </h4>
              <p className='f18 text-center' style={{ lineHeight: '28px' }}>Honda is committed to bringing joy in the lives of customers through innovative, reliable and technologically advanced power products that make a significant contribution to the Indian society by increasing the quality of their living standards and becoming a part of their daily lives.</p>
              <p className='f14 text-center' style={{ lineHeight: '24px' }}>While maintaining its leadership in the domestic generator market and export to over 35 countries, HIPP is making active efforts in developing new applications for its General-Purpose Engines (GPE). HIPP enjoys the majority of market share of Generators and Water pumps. The business of GPE and Water Pump sets is expected to contribute significantly to the company's future growth plans.</p>
              <p className='f14 text-center' style={{ lineHeight: '24px' }}>What started off as an "impossible dream" has today blossomed into a really touching people's daily lives in various ways? That's the power of dreams...and that dream lives on.</p>
            </div>
          </div>
        </div>

      </div>
      <FotterMain />
    </>
  )
}

export default React.memo(AboutUs)