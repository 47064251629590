import React from 'react'
import { Link } from 'react-router-dom'

const SectionTitle = () => {
  return (
    <>
    <div className='container'>
        <div className='d-flex align-items-center mb-3'>
            <div className='text-uppercase me-2'>
                <h4 className='d-inline-block m-0 fw-bold'>
                    <span className='txtred'>Product</span> <span className='txtdgray'>Range</span>
                </h4>
            </div>
            <div className='flex-grow-1 position-relative bg-border-mid'></div>
            <div className='position-relative'>
                <Link to=""><div className='tiltbg ps-3 pe-3 text-white f12'>View</div></Link> 
            </div>
        </div>
    </div>
    </>
  )
}

export default React.memo(SectionTitle)
