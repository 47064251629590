import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PressReleaseCel from '../PressReleaseCel';


const PressReleasetab = () => {
    const state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
    };
    return (
        <div>
            <OwlCarousel className='owl-theme with-nav-bottom-center' autoplay={false} items={4} nav={true} dots={false} responsive={state.responsive}>
                <div className="p-2">
                    <PressReleaseCel />
                </div>
                <div className="p-2">
                    <PressReleaseCel />
                </div>
                <div className="p-2">
                    <PressReleaseCel />
                </div>
                <div className="p-2">
                    <PressReleaseCel />
                </div>
                <div className="p-2">
                    <PressReleaseCel />
                </div>
                <div className="p-2">
                    <PressReleaseCel />
                </div>
            </OwlCarousel>
        </div>
    )
}

export default PressReleasetab