import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useStateValue } from '../../store';
import Button from '@mui/material/Button';
import Header from '../BaseComponents/Header';
import FotterMain from '../BaseComponents/FotterMain';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import { getCityList, getCountryList, getStateList, saveCustomerAddress } from '../../networkCalls';

const addressType = [
    {
        value: 'Home',
        label: 'Home',
    },
    {
        value: 'Office',
        label: 'Office',
    },
];


const validationSchema = yup.object({
    addressLine1: yup
        .string('Enter your address')
        .required('Address is required'),
    zipcode: yup
        .string('Enter your zipcode ')
        .min(6, 'Min 6 Digit')
        .max(6, 'Max 6 Digit')
        .matches(/^[0-9]+$/, "Must be only digits")
        .required('Zipcode is required'),
    addressType: yup
        .string('Enter your Address Type ')
        .required('Address Type is required'),
});

const UpdateAddress = () => {

    const navigate = useNavigate();
    const [{ token, huid }] = useStateValue();
    const [errMessage, setErrMessage] = useState('');
    const [susMessage, setSusMessage] = useState('');

    const [countryList, setCountryList] = React.useState([]);
    const pullCountryList = () => {
        getCountryList().then((res) => {
            //console.log("res.data ", res.data);
            switch (res.data.status) {
                case 1:
                    setCountryList(res.data.countryList);
                    return true;
                case 0:
                    break;
                default:
                    return;
            }
        })
    }

    const [stateList, setStateList] = React.useState([]);
    const pullStateList = (value) => {
        var data = new FormData();
        data.append('countryId', value);
        getStateList(data).then((res) => {
            //  console.log("res.data ", res.data);
            switch (res.data.status) {
                case 1:
                    setStateList(res.data.stateList);
                    return true;
                case 0:
                    break;
                default:
                    return;
            }
        })
    }

    const [cityList, setCityList] = React.useState([]);
    const pullCityList = (value) => {
        var data = new FormData();
        data.append('stateId', value);
        getCityList(data).then((res) => {
            //  console.log("res.data ", res.data);
            switch (res.data.status) {
                case 1:
                    setCityList(res.data.cityList);
                    return true;
                case 0:
                    break;
                default:
                    return;
            }
        })
    }

    useEffect(() => {
        pullCountryList();
    }, [token, huid]);



    const [country, setCountry] = useState('');
    const countryChange = (e, value) => {
        //   console.log(value);
        setCountry(value?.id)
        pullStateList(value?.id)
    };
    const [state, setState] = useState('');
    const stateChange = (e, value) => {
        // console.log(value);
        setState(value?.id)
        pullCityList(value?.id)
    };
    const [city, setCity] = useState('');
    const cityChange = (e, value) => {
        // console.log(value);
        setCity(value?.id);
    };

    const formik = useFormik({
        initialValues: {
            addressLine1: '',
            addressLine2: '',
            zipcode: '',
            addressType: '',
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            var data = new FormData();
            data.append('customerId', huid);
            data.append('XAuth', token);
            data.append('addressLine1', values.addressLine1);
            data.append('addressLine2', values.addressLine2);
            data.append('country', country);
            data.append('state', state);
            data.append('city', city);
            data.append('zipcode', values.zipcode);
            data.append('addressType', values.addressType);
            // for (var pair of data.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            saveCustomerAddress(data).then((res) => {
                //console.log('resres', res.data);
                switch (res.data.status) {
                    case 1:
                        setErrMessage('');
                        setSusMessage(res.data.msz);
                        return true;
                    case 0:
                        setErrMessage(res.data.msz);
                        break;
                    default:
                        return;
                }
            })
        },
    });


    return (
        <>
            <Header />

            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 text-left m-auto'>
                            <div className="d-flex align-items-center">
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>Add</span> <span className='txtdgray'>Address</span>
                                </h4>
                                <div className="ms-auto">
                                    <Button variant="text" onClick={() => navigate(-1)} size="medium" className='d-block mt-3'>
                                        BACK
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-lg-9 col-md-12 col-12 m-auto'>
                            {errMessage ? <><Alert severity="error">{errMessage}</Alert></> : null}
                            {susMessage ? <><Alert severity="success">{susMessage}</Alert></> : null}
                            <div className='card p-4 mb-3'>
                                <div className="d-flex">
                                    <div>
                                        <label className="text-uppercase fw-bold">Address Information</label>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <hr />
                                </div>
                                <form
                                    onSubmit={formik.handleSubmit}
                                    autoComplete="off"
                                >
                                    <div className="row">
                                        <div className='col-lg-12  col-12'>
                                            <TextField
                                                
                                                className='mb-4 w-100'
                                                variant="standard"
                                                id="addressLine1"
                                                name="addressLine1"
                                                label="Address Line 1 *"
                                                type="text"
                                                value={formik.values.addressLine1}
                                                onChange={formik.handleChange}
                                                error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
                                                helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
                                            />
                                        </div>
                                        <div className='col-lg-12 col-12'>
                                            <TextField
                                                
                                                className='mb-4 w-100'
                                                variant="standard"
                                                id="addressLine2"
                                                name="addressLine2"
                                                label="Address  Line 2"
                                                type="text"
                                                value={formik.values.addressLine2}
                                                onChange={formik.handleChange}
                                                error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
                                                helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
                                            />
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-12'>
                                            <Autocomplete
                                                disablePortal
                                                id="country"
                                                className='mb-4'
                                                name="country"
                                                options={countryList}
                                                onChange={countryChange}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => <TextField required variant="standard" {...params} label="Select Country" />}
                                            />
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-12'>
                                            <Autocomplete
                                                disablePortal
                                                id="state"
                                                className='mb-4'
                                                name="state"
                                                options={stateList}
                                                onChange={stateChange}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => <TextField required variant="standard" {...params} label="Select State" />}
                                            />
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-12'>
                                            <Autocomplete
                                                disablePortal
                                                className='mb-4'
                                                id="city"
                                                name="city"
                                                options={cityList}
                                                onChange={cityChange}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => <TextField required variant="standard" {...params} label="Select City" />}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-4 col-12'>
                                            <TextField
                                                
                                                className='mb-4 w-100'
                                                variant="standard"
                                                id="zipcode"
                                                name="zipcode"
                                                inputProps={{
                                                    maxLength: 6
                                                }}
                                                label="Zipcode *"
                                                type="text"
                                                value={formik.values.zipcode}
                                                onChange={formik.handleChange}
                                                error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                                                helperText={formik.touched.zipcode && formik.errors.zipcode}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-4 col-12'>
                                            <TextField
                                                id="addressType"
                                                className='w-100 mb-4'
                                                select
                                                label="Type of Address *"
                                                name="addressType"
                                                variant="standard"
                                                value={formik.values.addressType}
                                                onChange={formik.handleChange}
                                                error={formik.touched.addressType && Boolean(formik.errors.addressType)}
                                                helperText={formik.touched.addressType && formik.errors.addressType}
                                            >
                                                {addressType.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <div className="relative w-full mb-3">
                                                <Button color="error" variant="contained" className='w-100' type="submit">
                                                    ADD NEW ADDRESS
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FotterMain />
        </>
    )
}

export default UpdateAddress