import React from 'react'
import { Link } from 'react-router-dom'
import FacebookIcon from '@mui/icons-material/Facebook';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const FotterMain = () => {
    return (
        <>
            <div className='bgdgray site-footer'>
                <div className="decor decor--type--bottom site-footer__decor">
                    <div className="decor__body">
                        <div className="decor__start"></div>
                        <div className="decor__end"></div>
                        <div className="decor__center"></div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row pt-5 pb-4'>
                        <div className='col-lg-4 col-md-6 col-12 mb-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h3 className='text-white f18  mb-3'>Contact Us</h3>
                                    <p className='mb-0 f16'>HONDA INDIA POWER PRODUCTS LTD.</p>
                                    <p className='f12'>(Formerly known as Honda Siel Power Products Ltd.)</p>
                                </div>
                            </div>
                            <div className='row f13'>
                                <div className='col-7'>
                                    <p className='text-white  mb-1'>HEAD OFFICE</p>
                                    <p>
                                        Plot no 5 Sector 41 Kasna,<br />
                                        Greater Noida Industrial Area<br />
                                        Distt Gautam Budh Nagar ( UP)
                                    </p>
                                    <p><span className='text-white'>Telephone:</span> 0120-2590100</p>
                                    <p>
                                        <span className='text-white'>Toll Free:</span> 1800-11-2323<br />
                                        <span className='text-white'>E-Mail (Sales):</span> ho.mktg@hspp.com<br />
                                        <span className='text-white'>E-Mail (Service):</span> ho.service@hspp.com
                                    </p>
                                </div>
                                <div className='col-5'>
                                    <p className='text-white mb-1'>REGISTERED OFFICE</p>
                                    <p>409, DLF Tower B<br />Jasola Commercial<br />Complex, South Delhi<br />New Delhi- 110025</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-2'>
                            <div className='row'>
                                <div className='col-6'>
                                    <h3 className='text-white f18 mb-3'>Company</h3>
                                    <ul className='flinkmanu'>
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="/investors">Investors</Link></li>
                                        <li><Link to="/why-buy-a-honda">Why Buy A Honda</Link></li>
                                        <li><Link to="/careers">Careers</Link></li>
                                        <li><Link to="/exports">Exports</Link></li>
                                        <li><Link to="/press-release">Press Release</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                        <li><Link to="/online-payment">Online Payment</Link></li>
                                        
                                    </ul>
                                </div>
                                <div className='col-6'>
                                    <h3 className='text-white f18  mb-3'>Products</h3>
                                    <ul className='flinkmanu'>
                                        <li><Link to="">Tillers</Link></li>
                                        <li><Link to="">Brush Cutters</Link></li>
                                        <li><Link to=""> Water Pumps</Link></li>
                                        <li><Link to="">Lawn Mowers</Link></li>
                                        <li><Link to="">Generators</Link></li>
                                        <li><Link to="">General Purpose Engines</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h3 className='text-white f18  mb-3'>Environmental Initiatives</h3>
                                    <div><LazyLoadImage
                                        alt=""
                                        effect="blur"
                                        className='img-fluid mb-3'
                                        src='https://www.demo.wipl.co.in/honda/icon-f.png'
                                    /></div>
                                </div>
                            </div>
                            <div className='row f13'>
                                <div className='col-7'>
                                    <p className='text-white  mb-1'>Follow us on social networks</p>
                                    <div><a href="https://www.facebook.com/hondapowerproductsindia/" target="_blank" className='text-white'><FacebookIcon /></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bgred text-white' >
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center f12 pt-2 pb-2'>
                            Copyright @ Honda India Power Products Ltd. (Formerly known as Honda Siel Power Products Ltd.), 2020, All Right Reserved
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default React.memo(FotterMain)