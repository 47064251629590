import React from 'react'
import { Link, useParams } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FotterMain from '../BaseComponents/FotterMain';
import Header from '../BaseComponents/Header';
import ProductGridBox from '../BaseComponents/ProductCels/ProductGridBox';

const CategoryList = () => {
    const { categorySlug } = useParams();
    console.log('categorySlug---', categorySlug);

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [age, setAge] = React.useState('');

    const handleChangeAge = (event) => {
      setAge(event.target.value);
    };
    return (
        <>
            <Header />
            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='text-uppercase me-2'>
                                <h4 className='d-inline-block m-0 fw-bold'>
                                    <span className='txtred'>{categorySlug}</span>
                                </h4>
                            </div>
                            <p className='txtlgeay mt-2'>EP1000 is a very compact and light weight Portable Generator equipped with Honda’s advanced 4-Stroke Technology providing unmatched fuel efficiency and extremely low noise operation.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Box
                                sx={{ }}
                            // sx={{  maxWidth: 240, bgcolor: 'background.paper' }}
                            >
                                <Tabs
                                    value={value}
                                    // orientation="vertical"
                                    orientation="horizontal"
                                    //onChange={handleChange}
                                    className="mt-2"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    TabIndicatorProps={{
                                        style: {
                                            display: "none",
                                        },
                                    }}
                                    // scrollButtons
                                    allowScrollButtonsMobile
                                    //indicatorColor='secondary'
                                    // aria-label=""
                                >

                                    <div className='cursorPointer mb-2' >
                                        <div className='card p-3 position-relative me-3'>
                                            <div className='position-absolute caticontick' > <DoneIcon className='f-16' /></div>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>All Tillers</h3></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                        <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>Big Series</h3></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                        <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>Mid Series</h3></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                        <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>GXV Series</h3></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                         <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>Micro 4</h3></div>
                                            </div>
                                        </div>  
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                        <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>Micro 4</h3></div>
                                            </div>
                                        </div>  
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                         <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>Micro 4</h3></div>
                                            </div>
                                        </div>  
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                        <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>Micro 4</h3></div>
                                            </div>
                                        </div>  
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                        <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>Micro 4</h3></div>
                                            </div>
                                        </div>  
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                        <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>Micro 4</h3></div>
                                            </div>
                                        </div>  
                                    </div>
                                    <div className='cursorPointer mb-2'>
                                        <div className='card p-3 position-relative me-3'>
                                           <div className='d-flex align-items-center'>
                                               <div className='cat_img'><img src="https://www.hondaindiapower.com/uploads/products/sub-category/big.jpg" style={{width:'60px'}} alt="" /></div>
                                               <div><h3 className='f16 m-0 ms-2'>Micro 4</h3></div>
                                            </div>
                                        </div>  
                                    </div>
                                </Tabs>
                            </Box>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <hr />
                        </div>
                    </div>
                    <div className='container'>
                        <div className='d-flex align-items-center mb-3'>
                            <div className='text-uppercase me-2 flex-grow-1'>
                                <h3 className='mb-0 f17'>TILLERS</h3>
                            </div>
                            {/* <div className='flex-grow-1 position-relative bg-border-mid'></div> */}
                            <div className='sdf'>
                                 <Box sx={{ minWidth: 160 }}>
                                    <FormControl className='w-100'>
                                            <InputLabel id="sd">Short By Price</InputLabel>
                                            <Select
                                            labelId="sd"
                                            id="sd"
                                            size="small"
                                            value={age}
                                            label="S"
                                            onChange={handleChangeAge}
                                            >
                                            <MenuItem value={10}>High to Low</MenuItem>
                                            <MenuItem value={20}>Low to High</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 mb-3'>
                           <ProductGridBox />
                        </div>
                    </div>
                </div>
            </div>

            <FotterMain />
        </>
    )
}

export default CategoryList