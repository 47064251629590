import React from 'react'
import { Link } from 'react-router-dom'

const NoFound = () => {
  return (
    <div className='vh-100 w-100 d-flex align-items-center justify-content-center' style={{background:'#174054'}}>
        <div className='row'>
            <div className='col-12 text-center'>
                <div className='fw-bold text-white' style={{fontSize:'120px', lineHeight:'140px'}}>404</div>
            </div>
            <div className='col-12 text-center'>
                <div className='text-white mb-3'>PAGE NOT FOUND</div>
                <Link className='text-white fw-bold' to="/">GO TO HOME</Link>
            </div>
        </div>
    </div>
  )
}

export default NoFound