import React, {useState} from 'react'
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useMediaQuery } from '@mui/material';
import ProductGridBox from '../ProductCels/ProductGridBox';
import LinesEllipsis from 'react-lines-ellipsis';


const CategoryDisplayCel = ({ catlist }) => {
    const state = {
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
    };

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const mediumViewport = useMediaQuery('(min-width:768px)');

    const [activeTab, setActiveTab] = useState(catlist?.[0].id); 
    const handleCategory = (value) => {
        console.log('selected value', value)
        setActiveTab(value);
    }

    
    //console.log('catlist first id', catlist?.[1].id)
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-2 col-md-4 pe-0'>
                        <Box
                            sx={{ bgcolor: 'background.paper' }}
                            // sx={{  maxWidth: 240, bgcolor: 'background.paper' }}
                            >
                            <Tabs
                                value={value}
                                //orientation="vertical"
                                orientation={mediumViewport ? "vertical" : "horizontal"}
                                onChange={handleChange}
                                className="mt-2"
                                variant="scrollable"
                                scrollButtons="auto"
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                                //scrollButtons
                                allowScrollButtonsMobile
                                //indicatorColor='secondary'
                                //aria-label=""
                            >
                                {catlist.map((data, i) => (
                                    <div key={i} className={`btn border shadow-sm me-lg-0 me-3 mb-3 ${activeTab == data?.id ? 'redborder' : ''}`} onClick={() => { handleCategory(data?.id) }}>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-4  text-center'>
                                                <img src={data?.image} alt={data?.name} style={{ width: '50px' }} />
                                            </div>
                                            <div className='col-8 ps-0  text-start'>
                                                <div className='fw-bold f14'>{data?.name}</div>
                                                <LinesEllipsis
                                                    text={data?.description}
                                                    maxLine='1'
                                                    ellipsis='...'
                                                    trimRight
                                                    basedOn='letters'
                                                    className='f12 txtlgeay overflow-hidden'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Tabs>
                        </Box>
                    </div>
                    <div className='col-lg-10 col-md-8'>
                        <OwlCarousel className='owl-theme with-nav-bottom-center' autoplay={false} items={4} nav={true} dots={false} responsive={state.responsive}>
                            <div className="p-2">
                                <ProductGridBox />
                            </div>
                            <div className="p-2">
                                <ProductGridBox />
                            </div>
                            <div className="p-2">
                                <ProductGridBox />
                            </div>
                            <div className="p-2">
                                <ProductGridBox />
                            </div>
                            <div className="p-2">
                                <ProductGridBox />
                            </div>
                            <div className="p-2">
                                <ProductGridBox />
                            </div>
                            <div className="p-2">
                                <ProductGridBox />
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryDisplayCel