import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Alert from '@mui/material/Alert';
import * as yup from 'yup';
import { useCookies } from "react-cookie";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { useStateValue } from '../../store';
import Button from '@mui/material/Button';
import Header from '../BaseComponents/Header';
import FotterMain from '../BaseComponents/FotterMain';
import MenuItem from '@mui/material/MenuItem';
import { getCustomerProfile, updateCustomerProfile } from '../../networkCalls';
import { useNavigate } from "react-router-dom";
import FullPageApiLoadingCell from '../BaseComponents/FullPageApiLoadingCell';

const titleprofile = [
    {
        value: 'Mr',
        label: 'Mr.',
    },
    {
        value: 'Mrs',
        label: 'Mrs.',
    },
    {
        value: 'Miss',
        label: 'Miss.',
    },
    {
        value: 'Ms',
        label: 'Ms.',
    },
];


const validationSchema = yup.object({
    cname: yup
        .string('Enter your first name')
        .required('First name is required'),
    lname: yup
        .string('Enter your last name')
        .required('Last name is required'),
    email: yup
        .string('Enter your email')
        //.required()
        .notRequired()
        .email('Enter a valid email id'),
});



const EditProfile = () => {
    const navigate = useNavigate();

    const [{ token, huid }, dispatch] = useStateValue();
   
    const [, setCookie] = useCookies(["hdetial"]);

    const [loading, setLoading] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [susMessage, setSusMessage] = useState('');

    const [customerData, setCustomerData] = React.useState(null);


    const callApiProfile = () => {
        var data = new FormData();
        data.append('customerId', huid);
        data.append('XAuth', token);
        setLoading(true);
        getCustomerProfile(data).then((res) => {
            setLoading(false);
            console.log('resres', res.data);
            switch (res.data.status) {
                case 1:
                    setCustomerData(res.data.customerData);
                    setfileDisplay(res.data.customerData.profilePic)
                    return true;
                case 0:
                    setErrMessage(res.data.msz);
                    break;
                default:
                    return;
            }
        })
    }
    useEffect(() => {
        callApiProfile();
    }, [token, huid]);


    const [file, setFileState] = React.useState('');
    const [fileDisplay, setfileDisplay] = React.useState('');
    const userAttachment = e => {
        if (validateFileSize(e)) {
            setFileState(e.target.files[0]);
            setfileDisplay(URL.createObjectURL(e.target.files[0]));
        }
    };
    // for check file size
    const [err, seterr] = React.useState(null);
    const validateFileSize = (e) => {
        let file = e.target.files[0];
        let size = 2000000;
        //console.log(file.size);
        if (file.size > size) {
            seterr('image size must be less then 2MB');
            return false
        }
        seterr(null);
        return true
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: `${customerData?.title}`,
            cname: `${customerData?.firstName}`,
            lname: `${customerData?.lastName}`,
            idproff: `${customerData?.govtIdProof}`,
            email: `${customerData?.email}`,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            var data = new FormData();
            data.append('customerId', huid);
            data.append('XAuth', token);
            data.append('title', values.title);
            data.append('firstName', values.cname);
            data.append('lastName', values.lname);
            data.append('govtIdProof', values.idproff);
            if(customerData?.emailVerified===0){
                data.append('email', values.email);
            }
            // data.append('phone', values.phone);
            if (file) {
                data.append('profilePic', file);
            }
            for (var pair of data.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            updateCustomerProfile(data).then((res) => {
                setLoading(false);
                console.log('resres', res.data);
                switch (res.data.status) {
                    case 1:
                        setSusMessage(res.data.msz);
                        setCookie("hdetial", values.cname);
                        return true;
                    case 0:
                        setErrMessage(res.data.msz);
                        break;
                    default:
                        return;
                }
            })
        },
    });



    return (
        <>
            <Header />

            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 text-left m-auto'>
                            <div className="d-flex align-items-center">
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>Edit</span> <span className='txtdgray'>Profile</span>
                                </h4>
                                <div className="ms-auto">
                                    <Button variant="text" onClick={() => navigate(-1)} size="medium" className='d-block mt-3'>
                                        BACK
                                    </Button>
                                </div>
                            </div>
                            {errMessage?<><Alert severity="error">{errMessage}</Alert></>:null}
                            {susMessage?<><Alert severity="success">{susMessage}</Alert></>:null}
                        </div>
                    </div>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-lg-9 col-md-12 col-12 m-auto'>
                            {loading ? <><FullPageApiLoadingCell /></> : <>
                                <div className='card p-4 mb-3'>
                                    <div className="d-flex">
                                        <div>
                                            <label className="text-uppercase fw-bold">Personal Information</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <hr />
                                    </div>
                                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="relative mb-3 text-center">
                                                    <input accept="image/*" onChange={userAttachment} className='d-none' id="icon-button-file" type="file" />
                                                    <Badge
                                                        overlap="circular"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        badgeContent={<label htmlFor="icon-button-file">
                                                            <IconButton color="primary" className="btneditpic" aria-label="upload picture" component="span">
                                                                <PhotoCamera />
                                                            </IconButton>
                                                        </label>}
                                                    >
                                                        <Avatar sx={{ width: 98, height: 98 }} alt="" src={fileDisplay} />
                                                    </Badge>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-12 mb-3">
                                                <div className="mb-3">
                                                    <TextField
                                                        id="title"
                                                        className='w-100'
                                                        select
                                                        label="Title"
                                                        name="title"
                                                        variant="standard"
                                                        value={formik.values.title}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.title && Boolean(formik.errors.title)}
                                                        helperText={formik.touched.title && formik.errors.title}
                                                    >
                                                        {titleprofile.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                            </div>
                                            <div className='col-lg-5 col-md-4 col-12'>
                                                <TextField
                                                    
                                                    className='mb-4 w-100'
                                                    variant="standard"
                                                    id="cname"
                                                    name="cname"
                                                    label="First Name *"
                                                    type="text"
                                                    value={formik.values.cname}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.cname && Boolean(formik.errors.cname)}
                                                    helperText={formik.touched.cname && formik.errors.cname}
                                                />
                                            </div>
                                            <div className='col-lg-5 col-md-4 col-12'>
                                                <TextField
                                                    
                                                    className='mb-4 w-100'
                                                    variant="standard"
                                                    id="lname"
                                                    name="lname"
                                                    label="Last Name *"
                                                    type="text"
                                                    value={formik.values.lname}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.lname && Boolean(formik.errors.lname)}
                                                    helperText={formik.touched.lname && formik.errors.lname}
                                                />
                                            </div>
                                            <div className='col-lg-12 col-12'>
                                                {customerData?.emailVerified===0?<>
                                                    <TextField
                                                    
                                                    className='mb-1 w-100'
                                                    variant="standard"
                                                    id="email"
                                                    name="email"
                                                    label="Email ID"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                                    helperText={formik.touched.email && formik.errors.email}
                                                />
                                                </>:<></>} 
                                            </div>
                                            <div className='col-12'>
                                                <hr />
                                            </div>
                                            <div className='col-lg-12 col-12'>
                                                <TextField
                                                    
                                                    className='mb-4 w-100' 
                                                    variant="standard"
                                                    id="idproff"
                                                    name="idproff"
                                                    label="Govt. Id Proof"
                                                    type="text"
                                                    value={formik.values.idproff}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.idproff && Boolean(formik.errors.idproff)}
                                                    helperText={formik.touched.idproff && formik.errors.idproff}
                                                />
                                            </div>
                                            <div className="col-lg-12 col-12">
                                                <div className="relative w-full mb-3">
                                                    <Button color="error" variant="contained" className='w-100' type="submit">
                                                        UPDATE PROFILE
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <FotterMain />
        </>
    )
}

export default EditProfile