import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormik } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import { getCityList, getStateList, googleCaptchaAPIKey } from '../../../networkCalls';

const validationSchema = yup.object({
  productName: yup
    .string('Select Product')
    .required('Select Product'),
  likeTo: yup
    .string('Select Like to')
    .required('Select Like to'),
  cname: yup
    .string('Enter your Name')
    .required('Name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Enter your email'),
  mobile: yup
    .string('')
    //.notRequired()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, 'Enter 10 digit mobile number')
    .max(10, 'Enter 10 digit mobile number'),
  comments: yup
    .string('')
    .notRequired(),

});

const productList = [
  {
    value: 7,
    label: 'Generators',
  },
  {
    value: 6,
    label: 'Water Pumps',
  },
  {
    value: 5,
    label: 'Tillers',
  },
  {
    value: 4,
    label: 'Brush Cutters',
  },
  {
    value: 3,
    label: 'General Purpose Engines',
  },
  {
    value: 2,
    label: 'Lawn Mowers',
  },
];
const liketoOption = [
  {
    value: 'Service',
    label: 'Service',
  },
  {
    value: 'Sales',
    label: 'Sales',
  },
  {
    value: 'Demo',
    label: 'Demo',
  },
  {
    value: 'OEMs',
    label: 'OEMs',
  },
  {
    value: 'Supplier',
    label: 'Supplier',
  },
  {
    value: 'Export',
    label: 'Export',
  },
];



const EnqueyCel = () => {
  const drawerWidth = {
    width: '800px',
    '@media(minWidth: 780px)': {
      width: '320px'
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: drawerWidth,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [openEnq, setopenEnq] = React.useState(false);
  const handleOpenEnq = () => setopenEnq(true);
  const handleCloseEnq = () => setopenEnq(false);

  const [stateList, setStateList] = React.useState([]);
  const pullStateList = (value) => {
    var data = new FormData();
    data.append('countryId', 'IND');
    getStateList(data).then((res) => {
      console.log("res.data ", res.data);
      switch (res.data.status) {
        case 1:
          setStateList(res.data.stateList);
          return true;
        case 0:
          break;
        default:
          return;
      }
    })
  }
  const [state, setState] = useState('');
  const [cityList, setCityList] = React.useState([]);
  const pullCityList = (value) => {
    var data = new FormData();
    data.append('stateId', value);
    getCityList(data).then((res) => {
      //  console.log("res.data ", res.data);
      switch (res.data.status) {
        case 1:
          setCityList(res.data.cityList);
          return true;
        case 0:
          break;
        default:
          return;
      }
    })
  }

  const stateChange = (e, value) => {
    // console.log(value);
    setState(value?.id)
    pullCityList(value?.id)
  };
  const [city, setCity] = useState('');
  const cityChange = (e, value) => {
    // console.log(value);
    setCity(value?.id);
  };
  // capcha code
  const TEST_SITE_KEY = googleCaptchaAPIKey;
  const recaptchaRef = React.createRef();
  const [captchavalue, setCaptchavalue] = React.useState('');
  const onVerifyCaptcha = captchavalue => {
      setCaptchavalue({ captchavalue });
  };
  // end capcha code

  //console.log('captchavalue', captchavalue)
  const enabledbtn = captchavalue!='';

  const formik = useFormik({
    initialValues: {
      productName: '',
      likeTo: '',
      cname: '',
      email: '',
      mobile: '',
      comments:''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var data = new FormData();
      data.append('productName', values.productName);
      data.append('likeTo', values.likeTo);
      data.append('cname', values.cname);
      data.append('email', values.email);
      data.append('mobile', values.mobile);
      data.append('state', state);
      data.append('city', city);
      data.append('comments', values.comments);

      for (var pair of data.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
    },
  });


  useEffect(() => {
    pullStateList();
  }, []);

  return (
    <>
      <div className='productenq'>
        <div className='p-3'>
          <div className='p-5 border'>
            <h4 className='d-block m-0 fw-bold text-uppercase mb-3'>
              <span className='txtred d-block mb-1'>PRODUCT</span> <span className='text-white'>ENQUIRIES</span>
            </h4>
            <Button color="error" onClick={handleOpenEnq} variant="contained" type="submit">
              CLICK HERE
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={openEnq}
        // onClose={handleCloseProfile}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={handleCloseEnq}
            size="medium"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ float: 'right', position: 'absolute', right: '20px', top: '20px', zIndex: '3' }}
          >
            <CancelIcon />
          </IconButton>
          <div>
            <div className='row'>
              <div className='col-12 mb-3'>
                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                  <span className='txtred'>PRODUCT</span> <span className='txtdgray'>ENQUIRIES</span>
                </h4>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <TextField
                    required
                    id="productName"
                    className='w-100 mb-3'
                    select
                    label="Select Product"
                    name="productName"
                    variant="standard"
                    value={formik.values.productName}
                    onChange={formik.handleChange}
                    error={formik.touched.productName && Boolean(formik.errors.productName)}
                    helperText={formik.touched.productName && formik.errors.productName}
                  >
                    {productList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className='col-12 col-md-6'>
                  Select Modal
                </div>
                <div className='col-12 col-md-6'>
                  <TextField
                    required
                    id="likeTo"
                    className='w-100 mb-3'
                    select
                    label="I would like to"
                    name="likeTo"
                    variant="standard"
                    value={formik.values.likeTo}
                    onChange={formik.handleChange}
                    error={formik.touched.likeTo && Boolean(formik.errors.likeTo)}
                    helperText={formik.touched.likeTo && formik.errors.likeTo}
                  >
                    {liketoOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className='col-12 col-md-6'>
                  <TextField
                    
                    className='mb-3 w-100'
                    variant="standard"
                    id="cname"
                    name="cname"
                    label="Name"
                    type="text"
                    value={formik.values.cname}
                    onChange={formik.handleChange}
                    error={formik.touched.cname && Boolean(formik.errors.cname)}
                    helperText={formik.touched.cname && formik.errors.cname}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <TextField
                    required
                    className='mb-3 w-100'
                    variant="standard"
                    id="email"
                    name="email"
                    label="Email ID"
                    type="text"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <TextField
                    required
                    variant="standard"
                    className='mb-3 w-100'
                    label="Mobile Number"
                    inputProps={{
                      maxLength: 10
                    }}
                    id="mobile"
                    name="mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                    helperText={formik.touched.mobile && formik.errors.mobile}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Autocomplete
                    disablePortal
                    id="state"
                    className='mb-3 '
                    name="state"
                    options={stateList}
                    onChange={stateChange}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField required variant="standard" {...params} label="Select State" />}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Autocomplete
                    disablePortal
                    className='mb-3'
                    id="city"
                    name="city"
                    options={cityList}
                    onChange={cityChange}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField required variant="standard" {...params} label="Select City" />}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <TextField
                    
                    className='mb-3 w-100'
                    variant="standard"
                    id="comments"
                    name="comments"
                    label="Comments"
                    type="text"
                    multiline
                    rows={3}
                    value={formik.values.comments}
                    onChange={formik.handleChange}
                    error={formik.touched.comments && Boolean(formik.errors.comments)}
                    helperText={formik.touched.comments && formik.errors.comments}
                  />
                </div>
                <div className="col-md-12 col-12 mb-3">
                    <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        theme="light"
                        ref={recaptchaRef}
                        sitekey={TEST_SITE_KEY}
                        onChange={onVerifyCaptcha}
                        onExpired={() => {
                            recaptchaRef.current.reset(); // here
                            setCaptchavalue('');
                        }}
                    />
                </div>
              </div>
              <Button color="error" disabled={!enabledbtn}  variant="contained" className='w-100' type="submit">
                SUBMIT
              </Button>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  )
}
export default React.memo(EnqueyCel)
