import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/base.css';
import '../src/assets/css/layout.css';
import App from './App';

import reducer, { initialState } from "./store/reducer";
import { StateProvider } from "./store/StateProvider";


ReactDOM.render(
  <HelmetProvider>
    <Helmet>
      <title>Honda India Power Products Ltd.</title>
      <meta name="description" content="India's No. 1 Power Products company, Honda India Power Products Limited (HIPP) (Formerly known as Honda Siel Power Products Ltd. HSPP), is a subsidiary of Honda Motor Co. Japan and was incorporated on 19th Sept, 1985." />
    </Helmet>
    <React.StrictMode>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById("root")
);

