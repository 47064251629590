import React from 'react'

const WishListIcon = () => {
  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path d="M23 4c3.9 0 7 3.1 7 7 0 6.3-11.4 15.9-14 16.9-2.6-1-14-10.6-14-16.9 0-3.9 3.1-7 7-7 2.1 0 4.1 1 5.4 2.6l1.6 2 1.6-2C18.9 5 20.9 4 23 4m0-2c-2.8 0-5.4 1.3-7 3.4C14.4 3.3 11.8 2 9 2c-5 0-9 4-9 9 0 8 14 19 16 19s16-11 16-19c0-5-4-9-9-9z"></path></svg>
    </>
  )
}

export default WishListIcon