import React from 'react'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Header from '../BaseComponents/Header'
import FotterMain from '../BaseComponents/FotterMain';

const HondaGenuineOil = () => {
  return (
    <>
      <Header />
      <div className="post-view__header post-header post-header--has-image mb-0">
        <div className="post-header__image post-header__image-oil"></div>
        <div className="post-header__body container mt-5">
          <h3 className="post-header__title text-end col-12 f28 mt-5">
            INTRODUCING<br />
            HONDA 4 STROKE<br />
            GENUINE ENGINE OIL
          </h3>
          <div className='text-end mt-3'>With advanced technology for your powerful<br />and reliable Honda Power Products</div>
        </div>
      </div>
      <div className='innerpagebg pt-5 pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-11  m-auto'>
              <h4 className='d-block m-0 fw-bold text-uppercase mb-3 mt-2'>
                <span className='txtred'>HONDA</span> <span className='txtdgray'>GENUINE OIL</span>
              </h4>
              <p className='f18'>Honda Genuine Oil has an extensive distribution network consisting of 50 distributors & over 650 dealers servicing over 40,000 retail outlets across the country.</p>
              <ul className='m-0 p-0 list-unstyled mb-4'>
                <li className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Developed for Honda Power Product Engines and tested by Honda R&D.</li>
                <li className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />Genuine Engine Oil researched to give maximum engine performance for Honda Power Products Engines.</li>
                <li className='mb-1 d-flex'><ArrowForwardIosIcon className='f12 txtred mt-1 me-1' />For effective performance, it is essential to recommend Honda Genuine Engine Oil for Honda Power Product Engines.</li>
              </ul>
              <div className='row'>
                <div className='col-lg-12'>
                  <hr />
                  <h4 className='d-block m-0 fw-bold text-uppercase mb-3 mt-2 mb-4'>
                    <span className='txtred'>BENEFITS OF</span> <span className='txtdgray'>HIPP GENUINE OIL</span>
                  </h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-4 col-12 mb-3'>
                  <div className='post-view__body card rounded-0 border-0 shadow-sm p-4'>
                    <div className='d-flex'>
                      <div className='txtred me-2 fw-bold'>01.</div>
                      <div>
                        <h3 className='f18 fw-bold mb-1'>FRICTION CONTROL</h3>
                        <p className='m-0 p-0 f12'>Reduce friction in lubricating parts and prevent friction losses</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 mb-3'>
                  <div className='post-view__body card rounded-0 border-0 shadow-sm p-4'>
                    <div className='d-flex'>
                      <div className='txtred me-2 fw-bold'>02.</div>
                      <div>
                        <h3 className='f18 fw-bold mb-1'>SEALING</h3>
                        <p className='m-0 p-0 f12'>Prevent leakage of compressed gas and combustion products from flowing into crank case</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 mb-3'>
                  <div className='post-view__body card rounded-0 border-0 shadow-sm p-4'>
                    <div className='d-flex'>
                      <div className='txtred me-2 fw-bold'>03.</div>
                      <div>
                        <h3 className='f18 fw-bold mb-1'>PURIFICATION</h3>
                        <p className='m-0 p-0 f12'>Prevent pollution of degradation products<br />to engine.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 mb-3'>
                  <div className='post-view__body card rounded-0 border-0 shadow-sm p-4'>
                    <div className='d-flex'>
                      <div className='txtred me-2 fw-bold'>04.</div>
                      <div>
                        <h3 className='f18 fw-bold mb-1'>COOLING</h3>
                        <p className='m-0 p-0 f12'>Prevent heating in all parts of the engine</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 mb-3'>
                  <div className='post-view__body card rounded-0 border-0 shadow-sm p-4'>
                    <div className='d-flex'>
                      <div className='txtred me-2 fw-bold'>05.</div>
                      <div>
                        <h3 className='f18 fw-bold mb-1'>TRANSMISSION OF POWER</h3>
                        <p className='m-0 p-0 f12'>Supply of lubricants to all parts of engine.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 mb-3'>
                  <div className='post-view__body card rounded-0 border-0 shadow-sm p-4'>
                    <div className='d-flex'>
                      <div className='txtred me-2 fw-bold'>06.</div>
                      <div>
                        <h3 className='f18 fw-bold mb-1'>RUST PREVENTION</h3>
                        <p className='m-0 p-0 f12'>Prevent corrosion of air and water to metal.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FotterMain />
    </>
  )
}

export default React.memo(HondaGenuineOil)