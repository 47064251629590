import React from 'react'
import { Link } from "react-router-dom";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { actionTypes, useStateValue } from '../../store';
import Header from '../BaseComponents/Header';
import FotterMain from '../BaseComponents/FotterMain';

const OrderDetails = () => {
  return (
    <>
     <Header />
            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 text-left m-auto'>
                            <div className="d-flex align-items-center">
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>Order </span> <span className='txtdgray'>Details</span>
                                </h4>
                                {/* <div className="ms-auto">
                                    <Link to="/myaccount/update-address" className='d-block mt-3 fw-bold'>
                                        ADD NEW ADDRESS
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-lg-9 col-md-12 col-12 m-auto'>
                            <div className='card p-3 mb-3'>
                                <div className="row">
                                    <div className='col-12 col-md-4'>
                                        <h6 className='f14 fw-bold'>Delivery Address</h6>
                                        <div className='fw-bold'>Sachin</div>
                                        <div>
                                            King bazar Lakhnawali Road, Surajpur, Greater Noida - 201306, 
                                            Uttar Pradesh<br />Phone number 9540488243
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <h6 className='f14 fw-bold'>Billing Address</h6>
                                        <div className='fw-bold'>Sachin</div>
                                        <div>
                                            King bazar Lakhnawali Road, Surajpur, Greater Noida - 201306, 
                                            Uttar Pradesh<br />Phone number 9540488243
                                        </div>  
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <h6 className='f14 fw-bold'>More actions</h6>
                                        <div>
                                            Download Invoice
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div className='card p-3 mb-3'>
                                <div className="row">
                                    <div className='col-12 col-md-4'>
                                        <h6 className='f14 fw-bold'>Dealer Information</h6>
                                        <div className='fw-bold'>dsfdsf</div>
                                        <div>
                                            dsfdsfdsf
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <h6 className='f14 fw-bold'>Order Track</h6>
                                        <div>
                                             This order is also tracked by 9540488243  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='card p-3 mb-3'>
                                <Link to="#">
                                    <div className="row text-black">
                                        <div className="col-lg-6 col-12">
                                            <div className="row">
                                                <div className="col-lg-3 col-12">
                                                    <div className="">
                                                        <div className="" style={{ height: '75px', width: '75px' }}>
                                                            <img className="img-fluid" alt="" src="https://www.hondaindiapower.com/uploads/products/6/20XD_01.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <div className="">
                                                        <div className='fw-bold'>WB20XD</div>
                                                        <div className='f12 mt-1'>
                                                            <span className="text-muted">Order ID : </span>
                                                            <span className="">432324</span>
                                                        </div>
                                                        <div className='f12'>
                                                            <span className="text-muted">Dealer : </span>
                                                            <span className="">Varun</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-12">
                                            <CurrencyRupeeIcon className='f14 fw-bold' />23,322
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div>
                                                <span className="f12 fw-bold"><Brightness1Icon className='text-success f12 me-1' />Refund Completed</span>
                                                <div className="f12 text-muted">You returned this order because you needed it in a smaller size. </div>
                                            </div>
                                        </div>
                                    </div>

                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FotterMain />
    </>
  )
}

export default OrderDetails