import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { useFormik } from 'formik';
import { useCookies } from "react-cookie";
import CryptoJS from 'crypto-js';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import { actionTypes, useStateValue } from '../../store';
import { login, loginVerifyOtp, customerSignup, customerOTPVerify } from '../../networkCalls';


const titleprofile = [
    {
      value: 'Mr',
      label: 'Mr.',
    },
    {
      value: 'Mrs',
      label: 'Mrs.',
    },
    {
        value: 'Miss',
        label: 'Miss.',
      },
      {
        value: 'Ms',
        label: 'Ms.',
      },
  ];
  

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        // .email('Enter a valid email')
        .required('Enter your email or mobile phone number'),
    // password: yup
    //     .string('Enter your password')
    //     .min(8, 'Password should be of minimum 8 characters length')
    //     .required('Password is required'),
});
const validationSchemaOTP = yup.object({
    otp: yup
        .string('Enter your OTP')
        .min(4, 'Min 4 Digit')
        .max(4, 'Max 4 Digit')
        .matches(/^[0-9]+$/, "Must be only digits")
        .required('You must specify a 4 Digit OTP')
});


const validationSchemaRegisOTP = yup.object({
    otp: yup
        .string('Enter your OTP')
        .min(4, 'Min 4 Digit')
        .max(4, 'Max 4 Digit')
        .matches(/^[0-9]+$/, "Must be only digits"),
    // otpemail: yup
    //     .string('Enter your OTP')
    //     .min(4, 'Min 4 Digit')
    //     .max(4, 'Max 4 Digit')
    //     .matches(/^[0-9]+$/, "Must be only digits")
      
});

const validationSchemaRegis = yup.object({
    email: yup
        .string('Enter your email')
        //.required()
        .notRequired()
        .email('Enter a valid email id'),
    cname: yup
        .string('Enter your first name')
        .required('First name is required'),
    lname: yup
        .string('Enter your last name')
        .required('Last name is required'),
    mobile: yup
        .string('')
        //.notRequired()
        .required()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Enter 10 digit mobile number')
        .max(10, 'Enter 10 digit mobile number')
});


const LoginForm = () => {

    const [errMessage, setErrMessage] = useState('');
    const [susMessage, setSusMessage] = useState('');
    
    const [loading, setLoading] = React.useState(false);
    const [customerId, setCustomerId] = useState('');
    const [, setCookie] = useCookies(["jwt", "huid", "hdetial"]);
    const [{ token }, dispatch] = useStateValue();
    //console.log('token', token);

    const [isOTP, setIsOTP] = useState(false);

    const [isOTPRegis, setisOTPRegis] = useState(false);
    const [isRegistration, setIsRegistration] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
            // password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            var data = new FormData();
            data.append('email', values.email);
            login(data).then((res) => {
            //console.log('resres', res.data);
            setLoading(false);
            switch (res.data.status) {
                case 1:
                    setIsOTP(true);
                    setSusMessage(res.data.msz);
                    setErrMessage('');
                    return true;
                case 0:
                    setErrMessage(res.data.msz);
                    break;
                default:
                    return;
            }
            });
        },
    });


    const formikotp = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: validationSchemaOTP,
        onSubmit: (values) => {
            setLoading(true);
            var data = new FormData();
            data.append('email', formik.values.email);
            data.append('OTP', values.otp);
            loginVerifyOtp(data).then((res) => {
                console.log('resres', res.data);
                setLoading(false);
                switch (res.data.status) {
                    case 1:
                        // dispatch({ type: actionTypes.SET_TOKEN, value: res.data.data.accessToken });
                        // setCookie("jwt", res.data.data.accessToken);
                       
                        var userTokenCookie = CryptoJS.AES.encrypt(`${res.data.data.accessToken}`, 'HON_MX46d67ee5d05').toString();
                        dispatch({ type: actionTypes.SET_TOKEN, value: res.data.data.accessToken });
                        setCookie("jwt", userTokenCookie);
                        // dispatch({ type: actionTypes.SET_HUID, value: res.data.data.customerId });
                        // setCookie("huid", res.data.data.customerId);
                        
                        var userIDCookie = CryptoJS.AES.encrypt(`${res.data.data.customerId}`, 'HON_MX46d67ee5d05').toString();
                        dispatch({ type: actionTypes.SET_HUID, value: res.data.data.customerId });
                        setCookie("huid", userIDCookie);

                        setCookie("hdetial", res.data.data.firstName);
                       
                        dispatch({ type: actionTypes.SET_LOGINMODAL, value: false });
                        setErrMessage('');
                        return true;
                    case 0:
                        setErrMessage(res.data.msz);
                        break;
                    default:
                        return;
                }
            });
        },
    });

    
    const formikregisotp = useFormik({
        initialValues: {
            otp: '',
           // otpemail:''
        },
        validationSchema: validationSchemaRegisOTP,
        onSubmit: (values) => {
            setLoading(true);
            var data = new FormData();
            data.append('customerId', customerId);
            data.append('phoneOTP', values.otp);
            
           // data.append('emailOTP', values.otpemail);
           // alert(JSON.stringify(values, null, 2));
            // for (var pair of data.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            customerOTPVerify(data).then((res) => {
                //console.log('resOTP', res.data);
                setLoading(false);
                    switch (res.data.status) {
                        case 1:
                            setSusMessage(res.data.msz);
                            setErrMessage('');
                            setisOTPRegis(false);
                            setIsRegistration(false);
                            return true;
                        case 0:
                            setErrMessage(res.data.msz);
                            break;
                        default:
                            return;
                    }
            });
        },
    });
    const enabledbtn = formikregisotp?.values?.otp?.length > 3 || formikregisotp?.values?.otpemail?.length > 3;
    const formikResister = useFormik({
        initialValues: {
            title:'',
            cname: '',
            lname:'',
            mobile: '',
            email: '',  
        },
        validationSchema: validationSchemaRegis,
        onSubmit: (values) => {
            setLoading(true);
            var data = new FormData();
            data.append('title', values.title);
            data.append('firstName', values.cname);
            data.append('lastName', values.lname);
            data.append('phone', values.mobile);
            data.append('email', values.email);
            for (var pair of data.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            customerSignup(data).then((res) => {
                console.log('resDate', res.data);
                setLoading(false);
                    switch (res.data.status) {
                        case 1:
                            setCustomerId(res?.data?.data?.customerId)
                            setisOTPRegis(true);
                            setIsRegistration(false);
                            setErrMessage('');
                            return true;
                        case 0:
                            setErrMessage(res.data.msz);
                            break;
                        default:
                            return;
                }
            });
        },
    });
    


    return (
        <div className='container'>
            <div className='row d-flex align-items-center'>
                <div className='col-lg-4 d-none d-lg-block ps-0'>
                    <div className='bg-black h-100' style={{ minHeight: '540px' }}>
                        {!isRegistration?<><div className='text-white p-4'>
                            <h3 className='mt-5'>Login</h3>
                            <p>Get access to your Orders, Wishlist and Recommendations.</p>
                        </div></>:<></>}
                        {isRegistration?<><div className='text-white p-4'>
                            <h3 className='mt-5'>You're new here!</h3>
                            <p>Sign up with your mobile number or email to get started</p>
                        </div></>:<></>}
                    </div>
                </div>
                <div className='col-lg-8 col-md-12'>
                    <div className='p-4'>
                        {token?<></>:<></>}
                        {errMessage?<><Alert severity="error">{errMessage}</Alert></>:null}
                        {susMessage?<><Alert severity="success">{susMessage}</Alert></>:null}
                        <div className='row'>
                            <div className='col-lg-12 mt-4 mb-4'>
                                {isOTP ? <>
                                    <div className='text-center f14 mb-5'>
                                        Please enter the 4 Digit OTP sent to<br /><span className='fw-bold'>{formik.values.email}</span>. 
                                        <Link to="#" onClick={()=>{setIsOTP(false); setErrMessage(''); setSusMessage('');}}>Change</Link>
                                    </div>
                                    <form onSubmit={formikotp.handleSubmit} autoComplete="off">
                                        <TextField
                                            
                                            className='mb-4 w-100'
                                            variant="standard"
                                           // type="number"
                                            inputProps={{
                                                maxLength: 4
                                            }}
                                            id="otp"
                                            name="otp"
                                            label="Enter OTP"
                                            value={formikotp.values.otp}
                                            onChange={formikotp.handleChange}
                                            error={formikotp.touched.otp && Boolean(formikotp.errors.otp)}
                                            helperText={formikotp.touched.otp && formikotp.errors.otp}
                                        />
                                        <Button color="error" variant="contained" className='w-100' type="submit">
                                            Verify
                                        </Button>
                                        {/* <div className='f14 mt-3 text-center'>Not received your code? <Link to="#">Resend code</Link></div> */}
                                    </form>
                                </> : <>

                                    {!isRegistration && !isOTPRegis ? <><form onSubmit={formik.handleSubmit} autoComplete="off">
                                        <TextField
                                            className='mb-5 w-100'
                                            variant="standard"
                                            id="email"
                                            name="email"
                                            label="Enter Mobile Number / Email ID"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                        <div className='f12 mb-3'>By continuing, you agree to Honda's <Link to="/privacy-policy" target='_blank'>Terms & Privacy Policy</Link>.</div>
                                        {!loading ? <Button color="error" variant="contained" className='w-100' type="submit">
                                            Login
                                        </Button> : <LoadingButton className="w-100"
                                                loading
                                                loadingPosition="end"
                                                variant="contained"
                                            >
                                                Login...
                                        </LoadingButton>}
                                    </form></> :
                                        null
                                    }
                                </>}
                                {isRegistration ? <>
                                    <form onSubmit={formikResister.handleSubmit} autoComplete="off">
                                        <div className='row'>
                                            <div className='col-md-4 col-12'>
                                                <TextField
                                                    id="title"
                                                    className='w-100'
                                                    select
                                                    label="Title"
                                                    name="title"
                                                    variant="standard"
                                                    value={formikResister.values.title}
                                                    onChange={formikResister.handleChange}
                                                    error={formikResister.touched.title && Boolean(formikResister.errors.title)}
                                                    helperText={formikResister.touched.title && formikResister.errors.title}
                                                    >
                                                    {titleprofile.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                        </MenuItem>
                                                    ))}
                                                    </TextField>

                                            </div>
                                            <div className='col-md-4 col-12'>
                                                    <TextField
                                                    
                                                    className='mb-4 w-100'
                                                    variant="standard"
                                                    id="cname"
                                                    name="cname"
                                                    label="First Name *"
                                                    type="text"
                                                    value={formikResister.values.cname}
                                                    onChange={formikResister.handleChange}
                                                    error={formikResister.touched.cname && Boolean(formikResister.errors.cname)}
                                                    helperText={formikResister.touched.cname && formikResister.errors.cname}
                                                />
                                            </div>
                                            <div className='col-md-4 col-12'>
                                                    <TextField
                                                    
                                                    className='mb-4 w-100'
                                                    variant="standard"
                                                    id="lname"
                                                    name="lname"
                                                    label="Last Name *"
                                                    type="text"
                                                    value={formikResister.values.lname}
                                                    onChange={formikResister.handleChange}
                                                    error={formikResister.touched.lname && Boolean(formikResister.errors.lname)}
                                                    helperText={formikResister.touched.lname && formikResister.errors.lname}
                                                />
                                            </div>
                                        </div>
                                        <TextField
                                            
                                            variant="standard"
                                            className='mb-4 w-100' 
                                            label="Mobile Number *"
                                            inputProps={{
                                                maxLength: 10
                                            }}
                                            id="mobile"
                                            name="mobile"
                                            value={formikResister.values.mobile}
                                            onChange={formikResister.handleChange}
                                            error={formikResister.touched.mobile && Boolean(formikResister.errors.mobile)}
                                            helperText={formikResister.touched.mobile && formikResister.errors.mobile}
                                        />
                                        <TextField
                                            
                                            className='mb-4 w-100'
                                            variant="standard"
                                            id="email"
                                            name="email"
                                            label="Email ID"
                                            value={formikResister.values.email}
                                            onChange={formikResister.handleChange}
                                            error={formikResister.touched.email && Boolean(formikResister.errors.email)}
                                            helperText={formikResister.touched.email && formikResister.errors.email}
                                        />
                                        <div className='f12 mb-3'>By continuing, you agree to Honda's <Link to="/privacy-policy" target='_blank'>Terms & Privacy Policy</Link>.</div>
                                        {!loading ? <Button color="error" variant="contained" className='w-100' type="submit">
                                            Register
                                        </Button> : <LoadingButton className="w-100"
                                                loading
                                                //loadingPosition="end"
                                                variant="contained"
                                            >
                                                Register...
                                        </LoadingButton>}
                                       
                                    </form>
                                </> : null}
                            </div>
                            {isOTPRegis?<>
                                    <div className='text-left f14 mb-5'>
                                       Please enter the 4 Digit OTP sent<br /> to your Mobile <span className='fw-bold'>{formikResister.values.mobile}</span>
                                    </div>
                                    <form onSubmit={formikregisotp.handleSubmit} autoComplete="off">
                                        <TextField
                                            
                                            className='mb-4 w-100'
                                            variant="standard"
                                           // type="number"
                                            inputProps={{
                                                maxLength: 4
                                            }}
                                            id="otp"
                                            name="otp"
                                            label="Enter 4 Digit Mobile OTP"
                                            value={formikregisotp.values.otp}
                                            onChange={formikregisotp.handleChange}
                                            error={formikregisotp.touched.otp && Boolean(formikregisotp.errors.otp)}
                                            helperText={formikregisotp.touched.otp && formikregisotp.errors.otp}
                                        />
                                         {!loading ?  <Button color="error" disabled={!enabledbtn} variant="contained" className='w-100' type="submit">
                                            Verify OTP
                                        </Button> : <LoadingButton className="w-100"
                                                loading
                                               // loadingPosition="end"
                                                variant="contained"
                                            >
                                                Verify OTP...
                                        </LoadingButton>}
                                        {/* <div className='f14 mt-3 text-center'>Not received your code? <Link to="#">Resend code</Link></div> */}
                                    </form>
                                </>:null}

                            <div className='text-center'>
                                {!isOTP && !isOTPRegis ? <>OR</> : null}
                            </div>
                            <div className='text-center mt-3 fw-bold mb-3'>
                                {isRegistration ? <>Existing User? <Link to="#" onClick={() => setIsRegistration(false)}>Log in</Link></> :
                                    <>
                                        {!isOTP && !isOTPRegis ? <>New to Honda? <Link to="#" onClick={() => setIsRegistration(true)}>Create an account</Link></> : null}
                                    </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default LoginForm