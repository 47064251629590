import React from 'react'

const WishListIconsm = () => {
  return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M14 3c2.2 0 4 1.8 4 4 0 4-5.2 10-8 10S2 11 2 7c0-2.2 1.8-4 4-4 1 0 1.9.4 2.7 1L10 5.2 11.3 4c.8-.6 1.7-1 2.7-1m0-2c-1.5 0-2.9.6-4 1.5C8.9 1.6 7.5 1 6 1 2.7 1 0 3.7 0 7c0 5 6 12 10 12s10-7 10-12c0-3.3-2.7-6-6-6z"></path></svg>
    </>
  )
}

export default WishListIconsm