import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckIcon from '@mui/icons-material/Check';
import LinesEllipsis from 'react-lines-ellipsis';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import { useStateValue } from "./../../store";
import { getCustomerAddressList, getCityList, getCountryList, getStateList, } from '../../networkCalls';
import { Button } from '@mui/material';

// const PaytmChecksum = require('./../../Payment/paytmChecksum');
// const https = require('https');


// import {PaytmButton} from '../../Payment/PaytmButton';

const addressType = [
    {
        value: 'Home',
        label: 'Home',
    },
    {
        value: 'Office',
        label: 'Office',
    },
];

const Checkout = () => {
    const navigate = useNavigate();
    const [{ token, huid }] = useStateValue();
    const [deliveryAddId, setDeliveryAddId] = React.useState(null);
    const [samebilling, setSameBilling] = React.useState(false);
    const [haveGST, setHaveGST] = React.useState(false);
    const [businessName, setBusinessName] = React.useState('');
    const [gstNo, setGSTNo] = React.useState('');
    const [countryList, setCountryList] = React.useState([]);
    const pullCountryList = () => {
        getCountryList().then((res) => {
            //console.log("res.data ", res.data);
            switch (res.data.status) {
                case 1:
                    setCountryList(res.data.countryList);
                    return true;
                case 0:
                    break;
                default:
                    return;
            }
        })
    }
    const [stateList, setStateList] = React.useState([]);
    const pullStateList = (value) => {
        var data = new FormData();
        data.append('countryId', value);
        getStateList(data).then((res) => {
            //  console.log("res.data ", res.data);
            switch (res.data.status) {
                case 1:
                    setStateList(res.data.stateList);
                    return true;
                case 0:
                    break;
                default:
                    return;
            }
        })
    }

    const [cityList, setCityList] = React.useState([]);
    const pullCityList = (value) => {
        var data = new FormData();
        data.append('stateId', value);
        getCityList(data).then((res) => {
            //  console.log("res.data ", res.data);
            switch (res.data.status) {
                case 1:
                    setCityList(res.data.cityList);
                    return true;
                case 0:
                    break;
                default:
                    return;
            }
        })
    }

    const [addressList, setAddressList] = React.useState([]);
    const pullAddressList = () => {
        var data = new FormData();
        data.append('customerId', huid);
        data.append('XAuth', token);
        // setLoading(true);
        getCustomerAddressList(data).then((res) => {
            // console.log("res.data ", res.data);
            // setLoading(false);
            switch (res.data.status) {
                case 1:
                    setAddressList(res.data.customerAddress);
                    return true;
                case 0:
                    //setErrMessage(res.data.msz);
                    break;
                default:
                    return;
            }
        })
    }

    const [country, setCountry] = useState('');
    const countryChange = (e, value) => {
        //   console.log(value);
        setCountry(value?.id)
        pullStateList(value?.id)
    };
    const [state, setState] = useState('');
    const stateChange = (e, value) => {
        // console.log(value);
        setState(value?.id)
        pullCityList(value?.id)
    };
    const [city, setCity] = useState('');
    const cityChange = (e, value) => {
        // console.log(value);
        setCity(value?.id);
    };

    const [addressValue, setAddressValue] = React.useState('');
    const handleAddress = (value) => {
        setAddressValue(value);
        setDeliveryAddId(value);
    };
    const addNewAddress = () => {
        navigate("/myaccount/update-address");
    }
    const selectDeliveryAddress = (value) => {
        // api call for check delivery is possible or not for product
        setDeliveryAddId(value);
    };
    const sameBillingChange = (event) => {
        setSameBilling(event.target.checked);
    };
    const haveGSTChange = (event) => {
        setHaveGST(event.target.checked);
    };

    const businessNameChange = e => {
        setBusinessName(e.target.value);
    };
    const gstNoChange = e => {
        setGSTNo(e.target.value);
    };

    const [paymentMode, setPaymentMode] = React.useState('');

    const paymentModeChange = (value) => {
        setPaymentMode(value);
    };


    // final data for api hit paytm
    const [paymentData, setPaymentData] = useState({
        token: "",
        order: "",
        mid: "",
        amount: ""
    });

    const initialize = () => {
        // let orderId = 'Order_' + new Date().getTime();
        // // Sandbox Credentials
        // let mid = "nPPiAw42328535581499"; // Merchant ID
        // let mkey = "!mdbNWFpZS&5S#iH"; // Merhcant Key
        // var paytmParams = {};
        // paytmParams.body = {
        //     requestType: "Payment",
        //     mid: mid,
        //     websiteName: "WEBSTAGING",
        //     orderId: orderId,
        //     callbackUrl: "http://localhost:3000/myaccount/manage-orders",
        //     txnAmount: {
        //         value: 100,
        //         currency: "INR",
        //     },
        //     userInfo: {
        //         custId: "1001",
        //     },
        // };
        // PaytmChecksum.generateSignature(
        //     JSON.stringify(paytmParams.body),
        //     mkey
        // ).then(function (checksum) {
        //     //console.log(checksum);
        //     paytmParams.head = {
        //         signature: checksum,
        //     };
        //     var post_data = JSON.stringify(paytmParams);
        //     var options = {
        //         /* for Staging */
        //         // hostname: "securegw-stage.paytm.in" /* for Production */,
        //         hostname: "securegw-stage.paytm.in",
        //         port: 443,
        //         path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
        //         method: "POST",
        //         headers: {
        //             "Content-Type": "application/json",
        //             "Content-Length": post_data.length,
        //         },
        //     };
        //     var response = "";
        //     var post_req = https.request(options, function (post_res) {
        //         post_res.on("data", function (chunk) {
        //             response += chunk;
        //         });
        //         post_res.on("end", function () {
        //             console.log("Response: ", response);
        //             // res.json({data: JSON.parse(response), orderId: orderId, mid: mid, amount: amount});
        //             setPaymentData({
        //                 ...paymentData,
        //                 token: JSON.parse(response).body.txnToken,
        //                 order: orderId,
        //                 mid: mid,
        //                 amount: 100,
        //             });
        //         });
        //     });
        //     post_req.write(post_data);
        //     post_req.end();
        // });
    }

    const makePayment = () => {
       // console.log('hi')
        // var config = {
        //     "root": "",
        //     "style": {
        //         "bodyBackgroundColor": "#fafafb",
        //         "bodyColor": "",
        //         "themeBackgroundColor": "#0FB8C9",
        //         "themeColor": "#ffffff",
        //         "headerBackgroundColor": "#284055",
        //         "headerColor": "#ffffff",
        //         "errorColor": "",
        //         "successColor": "",
        //         "card": {
        //             "padding": "",
        //             "backgroundColor": ""
        //         }
        //     },
        //     "data": {
        //         "orderId": paymentData.order,
        //         "token": paymentData.token,
        //         "tokenType": "TXN_TOKEN",
        //         "amount": paymentData.amount 
        //     },
        //     "payMode": {
        //         "labels": {},
        //         "filter": {
        //             "exclude": []
        //         },
        //         "order": [
        //             "CC",
        //             "DC",
        //             "NB",
        //             "UPI",
        //             "PPBL",
        //             "PPI",
        //             "BALANCE"
        //         ]
        //     },
        //     "website": "WEBSTAGING",
        //     "flow": "DEFAULT",
        //     "merchant": {
        //         "mid": paymentData.mid,
        //         "redirect": false
        //     },
        //     "handler": {
        //         "transactionStatus":
        //             function transactionStatus(paymentStatus) {
        //                 console.log(paymentStatus);
        //             },
        //         "notifyMerchant":
        //             function notifyMerchant(eventName, data) {
        //                 console.log("Closed");
        //             }
        //     }
        // };
        // if (window.Paytm && window.Paytm.CheckoutJS) {
        //     window.Paytm.CheckoutJS.init(config).
        //         then(function onSuccess() {
        //             window.Paytm.CheckoutJS.invoke();
        //         }).catch(function onError(error) {
        //             console.log("Error => ", error);
        //         });
        // }
    }

    
    useEffect(() => {
        pullAddressList();
        pullCountryList();
       // initialize();
    }, [token, huid]);



    return (
        <>
            <div className='bgred'>
                <div className='container-fluid pt-2 pb-2'>
                    <div className='d-flex align-items-center'>
                        <div className=''>
                            <IconButton size="medium" color="inherit" >
                                <ArrowBackIosIcon className='text-white' />
                            </IconButton>
                        </div>
                        <div className='text-white'>LOGO</div>
                    </div>
                </div>
            </div>
            <div className='innerpagebg pb-5'>
                <div className='container pt-4'>
                    <div className='row'>
                        <div className='col-12 col-lg-8'>
                            <div className='chk_login_wrap mb-3'>
                                <div className='wrap_login_s bg-white shadow-sm rounded'>
                                    <h3 className='bglblue text-black f16 text-uppercase m-0 p-3'>
                                        <span className='bgred rounded f14 d-inline-block p-2 pt-1 pb-1 me-1 text-white'>1</span>
                                        <span>Login</span>
                                        <span><CheckIcon className='f18 ms-1' color='success' /></span>
                                    </h3>
                                    <div className='login_body p-3'>
                                        <span className='f16 fw-bold'>Varun Garg</span> <span className='f16'>8285016345</span>
                                    </div>
                                </div>
                            </div>
                            {deliveryAddId ? <>
                                <div className='chk_addSelected_wrap mb-3'>
                                    <div className='wrap_addSelected_s bg-white shadow-sm rounded'>
                                        <h3 className='bglblue text-black f16 text-uppercase m-0 p-3'>
                                            <span className='bgred rounded f14 d-inline-block p-2 pt-1 pb-1 me-1 text-white'>2</span>
                                            <span>Delivery Address</span>
                                            <span><CheckIcon className='f18 ms-1' color='success' /></span>
                                        </h3>
                                        <div className='addSelected_body p-3'>
                                            <div>King bazar, Lakhnawali Road, Surajpur, Greater Noida, Uttar Pradesh</div>
                                            <Button color="primary" size="small" variant="outlined" onClick={() => { selectDeliveryAddress(null) }} type="submit" className='mt-2 mb-2'>
                                                Change Address
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </> :
                                <>
                                    <div className='chk_deladd_wrap mb-3'>
                                        <div className='wrap_deladd_s bg-white shadow-sm rounded'>
                                            <h3 className='bgdgray text-white f16 text-uppercase m-0 p-3'>
                                                <span className='bgred rounded f14 d-inline-block p-2 pt-1 pb-1 me-1 text-white'>2</span>
                                                <span>delivery Address</span>
                                            </h3>
                                            <div className='deladd_body'>
                                                <div className='row'>
                                                    {addressList && addressList.length > 0 && addressList.map((data, key) => (
                                                        <>
                                                            <div key={data.addressId} className="col-sm-12 col-12" onClick={() => { handleAddress(data.addressId) }}>
                                                                <div className={`d-flex p-2 ${addressValue === data.addressId ? 'bgsgray' : ''}`} style={{ borderBottom: 'solid 1px #f0f0f0' }}>
                                                                    <div>
                                                                        <Radio
                                                                            checked={addressValue === data.addressId}
                                                                            value={data.addressId}
                                                                            size="small"
                                                                            name="address-radio"
                                                                            className="float-start pb-0"
                                                                            inputProps={{ 'aria-label': 'A' }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <p className='mb-2 mt-2 f14'><span className='text-uppercase me-1 '>{data?.addressType}</span>{data?.addressLine1}</p>
                                                                        <p className='f13 mb-0'>{data?.addressLine2 ? <>{data?.addressLine2},</> : <></>} {data?.city}, {data?.state}, {data?.country} - {data?.zipcode}</p>
                                                                        {/* {addressValue === data.addressId ? <>
                                                                            <Button color="warning" variant="contained" onClick={() => { selectDeliveryAddress(data.addressId) }} type="submit" className='mt-2 mb-2'>
                                                                                Deliver Here
                                                                            </Button>
                                                                        </> : null} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='bg-white shadow-sm'>
                                                <Button variant="text" className='w-100' onClick={() => { addNewAddress() }} startIcon={<AddIcon />}>
                                                    Add a new address
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className='chk_deladd_wrap mb-3'>
                                <div className='wrap_deladd_s bg-white shadow-sm rounded'>
                                    <h3 className='bgdgray text-white f16 text-uppercase m-0 p-3'>
                                        <span className='bgred rounded f14 d-inline-block p-2 pt-1 pb-1 me-1 text-white'>3</span>
                                        <span>Billing To</span>
                                    </h3>
                                    <div className='deladd_body'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <FormGroup  >
                                                    <FormControlLabel className='p-2'
                                                        control={<Checkbox className='ms-2' checked={samebilling}
                                                            onChange={sameBillingChange} />} label="My Billing information is the same as my Delivey information" />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        {!samebilling ? <>
                                            <div className='p-3 pt-0'>
                                                <div className='row'>
                                                    <div className='col-12 col-md-6'>
                                                        <TextField
                                                            required

                                                            className='mb-4 w-100'
                                                            variant="standard"
                                                            name="addressLine1"
                                                            id="addressLine1"
                                                            label="Address Line 1"
                                                            helperText=""
                                                        // value={businessName}
                                                        // onChange={businessNameChange}
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <TextField

                                                            className='mb-4 w-100'
                                                            variant="standard"
                                                            name="addressLine2"
                                                            id="addressLine2"
                                                            label="Address Line 2"
                                                            helperText=""
                                                        // value={businessName}
                                                        // onChange={businessNameChange}
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="country"
                                                            className='mb-4'
                                                            name="country"
                                                            options={countryList}
                                                            onChange={countryChange}
                                                            sx={{ width: '100%' }}
                                                            renderInput={(params) => <TextField required variant="standard" {...params} label="Select Country" />}
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="state"
                                                            className='mb-4'
                                                            name="state"
                                                            options={stateList}
                                                            onChange={stateChange}
                                                            sx={{ width: '100%' }}
                                                            renderInput={(params) => <TextField required variant="standard" {...params} label="Select State" />}
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <Autocomplete
                                                            disablePortal
                                                            className='mb-4'
                                                            id="city"
                                                            name="city"
                                                            options={cityList}
                                                            onChange={cityChange}
                                                            sx={{ width: '100%' }}
                                                            renderInput={(params) => <TextField required variant="standard" {...params} label="Select City" />}
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <TextField
                                                            required

                                                            className='mb-4 w-100'
                                                            variant="standard"
                                                            name="zipcode"
                                                            id="zipcode"
                                                            label="Zipcode"
                                                            helperText=""
                                                        // value={businessName}
                                                        // onChange={businessNameChange}
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <TextField
                                                            required
                                                            id="addressType"
                                                            className='w-100 mb-4'
                                                            select
                                                            label="Type of Address"
                                                            name="addressType"
                                                            variant="standard"
                                                        // value={formik.values.addressType}
                                                        // onChange={formik.handleChange}

                                                        >
                                                            {addressType.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div>

                                                </div>
                                            </div>
                                        </> : null}
                                        <div className='row'>
                                            <div className='col-12'>
                                                <FormGroup  >
                                                    <FormControlLabel className='p-2'
                                                        control={<Checkbox className='ms-2' checked={haveGST}
                                                            onChange={haveGSTChange} />} label="GST Billing" />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        {haveGST ? <div className='p-3 pt-0'>
                                            <div className='row'>
                                                <div className='col-md-6 col-12'>
                                                    <TextField
                                                        required

                                                        className='mb-4 w-100'
                                                        variant="standard"
                                                        name="businessName"
                                                        id="businessName"
                                                        label="Business Name"
                                                        helperText=""
                                                        value={businessName}
                                                        onChange={businessNameChange}
                                                    />
                                                </div>
                                                <div className='col-md-6 col-12'>
                                                    <TextField
                                                        required

                                                        className='mb-4 w-100'
                                                        variant="standard"
                                                        name="gstno"
                                                        id="gstno"
                                                        label="GST No"
                                                        helperText=""
                                                        value={gstNo}
                                                        onChange={gstNoChange}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null}
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='check-sidebar mb-3'>
                                <div className='cart-review_wrap bg-white shadow-sm rounded'>
                                    <h3 className='f16 txtlgeay p-3 mb-0'>REVIEW YOUR ORDER (3)</h3>
                                    <hr className='mt-0 mb-0' />
                                    <div className='items-wrap'>
                                        <div className='p-3 bg-white'>
                                            <div className='d-flex'>
                                                <div className=''><img src="https://honda.wipl.co.in/SuperAdmin/public/images/Category/1646992951Category-Image.jpg" style={{ width: '50px' }} alt="" /></div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <LinesEllipsis
                                                                text='WB20XD'
                                                                maxLine='1'
                                                                ellipsis='...'
                                                                trimRight
                                                                basedOn='letters'
                                                                className='f14 fw-bold overflow-hidden'
                                                            />
                                                        </div>
                                                        <div className='col-12 txtlgeay f12'>
                                                            Dealer: Honda xyz
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex mt-1 align-items-center'>
                                                        <div className='f12 col-4 col-md-3'>
                                                            <CurrencyRupeeIcon className='f12' />2000
                                                        </div>
                                                        <div className='d-flex align-items-center f12 col-4 col-md-5'>
                                                            Qty: 2
                                                        </div>
                                                        <div className='text-start text-md-end col-4 col-md-4'>
                                                            <div className='f14'><CurrencyRupeeIcon className='f14' />4000</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='m-0 mt-2 mb-2' />
                                            <div className='d-flex'>
                                                <div className=''><img src="https://honda.wipl.co.in/SuperAdmin/public/images/Category/1646992951Category-Image.jpg" style={{ width: '50px' }} alt="" /></div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <LinesEllipsis
                                                                text='WB20XD'
                                                                maxLine='1'
                                                                ellipsis='...'
                                                                trimRight
                                                                basedOn='letters'
                                                                className='f14 fw-bold overflow-hidden'
                                                            />
                                                        </div>
                                                        <div className='col-12 txtlgeay f12'>
                                                            Dealer: Honda xyz
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex mt-1 align-items-center'>
                                                        <div className='f12 col-4 col-md-3'>
                                                            <CurrencyRupeeIcon className='f12' />2000
                                                        </div>
                                                        <div className='d-flex align-items-center f12 col-4 col-md-5'>
                                                            Qty: 2
                                                        </div>
                                                        <div className='text-start text-md-end col-4 col-md-4'>
                                                            <div className='f14'><CurrencyRupeeIcon className='f14' />4000</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='m-0 mt-2 mb-2' />
                                            <div className='d-flex'>
                                                <div className=''><img src="https://honda.wipl.co.in/SuperAdmin/public/images/Category/1646992951Category-Image.jpg" style={{ width: '50px' }} alt="" /></div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <LinesEllipsis
                                                                text='WB20XD'
                                                                maxLine='1'
                                                                ellipsis='...'
                                                                trimRight
                                                                basedOn='letters'
                                                                className='f14 fw-bold overflow-hidden'
                                                            />
                                                        </div>
                                                        <div className='col-12 txtlgeay f12'>
                                                            Dealer: Honda xyz
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex mt-1 align-items-center'>
                                                        <div className='f12 col-4 col-md-3'>
                                                            <CurrencyRupeeIcon className='f12' />2000
                                                        </div>
                                                        <div className='d-flex align-items-center f12 col-4 col-md-5'>
                                                            Qty: 2
                                                        </div>
                                                        <div className='text-start text-md-end col-4 col-md-4'>
                                                            <div className='f14'><CurrencyRupeeIcon className='f14' />4000</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bglblue p-3'>
                                        <div className='f12 mb-2'>Have a Coupon?</div>
                                        <div className='d-flex'>
                                            <div className='flex-grow-1'>
                                                <TextField
                                                    className='w-100'
                                                    variant="standard"
                                                    name="gstno"
                                                    id="gstno"
                                                    label=""
                                                    placeholder='Coupon Code'
                                                    helperText=""
                                                // value={gstNo}
                                                // onChange={gstNoChange}
                                                />
                                            </div>
                                            <div>
                                                <Button variant="text" className=''
                                                //startIcon={<AddIcon />}
                                                >APPLY
                                                </Button>
                                            </div>
                                        </div>
                                        <div className='mt-2 text-success f12'>Happy20- coupon was successfully applied.</div>
                                    </div>
                                    <div className='bg-white p-3'>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className=''>Cart Subtotal </div>
                                            <div className=''><CurrencyRupeeIcon className='f14' />12,000</div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className=''>Discount </div>
                                            <div className=''>- <CurrencyRupeeIcon className='f14' />2400</div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between mb-2 fw-bold'>
                                            <div className=''>Total </div>
                                            <div className=''><CurrencyRupeeIcon className='f14' />9600</div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className=''>GST <span className='f12'>(included)</span></div>
                                            <div className=''><CurrencyRupeeIcon className='f14' />1200</div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between f16 fw-bold mb-3'>
                                            <div className=''>Grand Total </div>
                                            <div className=''><CurrencyRupeeIcon className='f14' />9600</div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className={`box-radio-container d-flex align-items-center border rounded p-2 ${paymentMode == 'paytm' ? 'border-success' : ''}`} onClick={() => { paymentModeChange('paytm') }}>
                                                    <div className="clearfix">
                                                        <Radio
                                                            checked={paymentMode == 'paytm'}
                                                            value="paytm"
                                                            size="small"
                                                            name="radio-buttons"
                                                            className="float-start"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                        />
                                                    </div>
                                                    <div className="text-center">Payment Gateway and Logo</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mt-3'>
                                            {/* <PaytmButton /> */}
                                            <Button color="error" variant="contained" className='w-100'
                                            //startIcon={<AddIcon />}
                                            onClick={makePayment}
                                            >
                                                Continue to Payment
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout