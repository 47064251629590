import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const AboutUSCel = () => {
    return (
        <div className='container-fluid'>
            <div className='row d-flex align-items-center'>
                <div className='col-lg-1 col-md-1'>
                </div>
                <div className='col-lg-5 col-md-5'>
                    <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                        <span className='txtred'>About</span> <span className='txtdgray'>Us</span>
                    </h4>
                    <p className='f17 mt-3'>India's No. 1 Power Products company, Honda India Power Products Limited (HIPP) (Formerly known as Honda Siel Power Products Ltd. HSPP), is a subsidiary of Honda Motor Co. Japan and was incorporated on 19th Sept, 1985.</p>
                    <p>Ever since, it has been the undisputed leader in the power products industry, manufacturing and marketing a range of Portable Generators, Water Pumps and General Purpose Engine at its state-of-art manufacturing facilities at Greater Noida. It is also engaged in the marketing of Lawnmower, Brush Cutter, Long tailed outboard motors and Power Tiller.</p>
                    <div className='d-flex align-items-center text-center' >
                        <div className='flex-fill m-2 ms-0'>
                            <div className='bgsgray border p-2  pb-4'>
                                <div className='txtlgeay fw-bold f12'>
                                    <img src={require("./../../../assets/icons/hand-shake.png")} alt="" className='d-block m-auto pt-2 pb-2' style={{ width: '40px' }} />
                                    STRENGTH<br />600 DEALERS
                                </div>
                            </div>
                        </div>
                        <div className='flex-fill m-2'>
                            <div className='bgsgray border p-2 pb-4'>
                                <div className='txtlgeay fw-bold f12'>
                                    <img src={require("./../../../assets/icons/customer.png")} alt="" className='d-block m-auto pt-2 pb-2' style={{ width: '40px' }} />
                                    CUSTOMERS<br /> 5 MILLION+
                                </div>
                            </div>
                        </div>
                        <div className='flex-fill m-2 me-0'>
                            <div className='bgsgray border p-2 pb-4'>
                                <div className='txtlgeay fw-bold f12'>
                                    <img src={require("./../../../assets/icons/coronavirus.png")} alt="" className='d-block m-auto pt-2 pb-2' style={{ width: '40px' }} />
                                    EXPORT<br /> 35 COUNTRIES
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 bgred vh-100 aboutbg d-none d-lg-block'>
                    <LazyLoadImage
                        alt=""
                        effect="blur"
                        className='img-fluid w-75 mt-5 pt-5'
                        src='https://www.demo.wipl.co.in/honda/Att_02_FQ650.png'
                    />
                </div>
            </div>
        </div>
    )
}

export default React.memo(AboutUSCel)