import React, {useEffect, useState, useCallback} from 'react'
import { Link } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { useStateValue } from '../../store';
import Header from '../BaseComponents/Header';
import FotterMain from '../BaseComponents/FotterMain';
import { getCustomerAddressList } from '../../networkCalls';
import AddressViewCell from './AddressViewCell';
import FullPageApiLoadingCell from '../BaseComponents/FullPageApiLoadingCell';


const ManageAddresses = () => {
    const [{ token, huid }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false);
    const [addressList, setAddressList] = React.useState([]);
    const [errMessage, setErrMessage] = useState('');
    const pullAddressList = () => {
        var data = new FormData();
        data.append('customerId', huid);
        data.append('XAuth', token);
        setLoading(true);
        getCustomerAddressList(data).then((res) => {
            // console.log("res.data ", res.data);
             setLoading(false);
            switch (res.data.status) {
                case 1:
                    setAddressList(res.data.customerAddress);
                    return true;
                case 0:
                    setErrMessage(res.data.msz);
                    break;
                default:
                return;
            }
        })
    }

    const callback = useCallback((pass) => {
        //console.log('bar', pass);
        pullAddressList();
    }, []);

    useEffect(() => {
        pullAddressList();
    }, [token, huid]);
    return (
        <>
            <Header />
            <div className='innerpagebg pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 text-left m-auto'>
                            <div className="d-flex align-items-center">
                                <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                                    <span className='txtred'>Manage</span> <span className='txtdgray'>Address ({addressList.length})</span>
                                </h4>
                                <div className="ms-auto">
                                    <Link to="/myaccount/update-address" className='d-block mt-3 fw-bold'>
                                        ADD NEW ADDRESS
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-lg-9 col-md-12 col-12 m-auto'>
                            {errMessage?<><Alert severity="error">{errMessage}</Alert></>:null}
                            {loading ? <><FullPageApiLoadingCell /></> : <>
                            {addressList && addressList.length > 0 && addressList.map((data, key) => (
                                <AddressViewCell itemIn={data} key={key}  addressBackIn={callback} />
                            ))}
                            </>}
                            {addressList && addressList.length < 0 ?<><div className='row'>
                                <div className='col-lg-12'>
                                    <div className='card text-center p-4'>
                                        No Records Found
                                    </div>
                                </div>
                            </div></>:<>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <FotterMain />
        </>
    )
}

export default ManageAddresses