import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { GoogleMap, useJsApiLoader, InfoWindow, Marker } from '@react-google-maps/api';
import Header from '../../BaseComponents/Header';
import FotterMain from '../../BaseComponents/FotterMain';

const containerStyle = {
  width: '100%',
  height: '500px'
};

const center = {
  lat: 35.9078,
  lng: 127.7669
};

const markers = [
  {
    id: 1,
    name: "Chicago, Illinois",
    position: { lat: 41.881832, lng: -87.623177 }
  },
  {
    id: 2,
    name: "Denver, Colorado",
    position: { lat: 39.739235, lng: -104.99025 }
  },
  {
    id: 3,
    name: "Los Angeles, California",
    position: { lat: 34.052235, lng: -118.243683 }
  },
  {
    id: 4,
    name: "New York, New York",
    position: { lat: 40.712776, lng: -74.005974 }
  }
];

const ExportsLP = () => {


  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [value1, setValue1] = React.useState('1');
  const handle1Change = (event, newValue) => {
    setValue1(newValue);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyC9zXsb2CA3tHPDDn0TCcE3ZhhFWqKGOj8"
  })

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <>
      <Header />
      <div className="post-view__header post-header post-header--has-image ">
        <div className="post-header__image post-header__image-export"></div>
        {/* <div className="post-header__image" style="background-image: url('https://red-parts.react.themeforest.scompiler.ru/themes/red/images/posts/post-1.jpg);"></div> */}
        <div className="post-header__body mt-5 mb-5">
          <div className="post-header__categories mt-5">
            <div className="post-header__categories-list mt-5 mb-5">
              <div className="post-header__categories-item mb-3 mt-5">&nbsp;</div>
            </div>
          </div>
          <h3 className="post-header__title mt-5 mb-5">&nbsp;</h3>
          <div className="post-header__meta mt-5">
            <ul className="post-header__meta-list mt-5">
              <li className="post-header__meta-item mt-5"></li>
            </ul>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12  m-auto'>
            <div className='post-view__body card rounded-0 border-0 shadow-sm p-5'>
              {/* content here */}
              <div class="cm-content prscot">
                <h4 className='d-block m-0 fw-bold text-uppercase mb-3 mt-2'>
                  <span className='txtred'>EXPORT</span> <span className='txtdgray'>ACTIVITIES</span>
                </h4>
                <p className='f18'>To expand our markets, we introduced Power Products in Overseas Markets and have established the image of “Honda” with Made in India.</p>
                <p className='f14'>Currently, our products are being sold to more than 35 countries across the globe and our 7.0 kVA generator has established its good image due to quality, performance, technology ( Fuel Injection System ) in USA, Europe and other developed markets.</p>
                <p className='f14'>This model is meeting the stringent air & noise pollution norms applicable of India, USA and other developed nations and this is the first “Fuel Injection Based” generator (in its category) introduced by Honda in the world market in 2014.</p>
                <p className='f14'>Due to our overseas performance, Central & State Govt. recognized it through National & State level awards and Certificate of Recognition from time to time.</p>
                <hr />
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='d-block m-0 fw-bold text-uppercase text-start mb-3 mt-4'>
                      <span className='txtred'>EXPORT</span> <span className='txtdgray'>PRODUCTS</span>
                    </h4>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList
                            onChange={handleChange}
                            variant='scrollable'
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            TabIndicatorProps={{
                              style: {
                                backgroundColor: '#CB1100'
                              },
                            }}
                            aria-label="">
                            <Tab label="ALL" value="1" />
                            <Tab label="TILLERS" value="2" />
                            <Tab label="WATER PUMPS" value="3" />
                            <Tab label="GENERAL PURPOSE ENGINES" value="4" />
                            <Tab label="GENERATORS" value="5" />
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                          1
                        </TabPanel>
                        <TabPanel value="2">
                          2
                        </TabPanel>
                        <TabPanel value="3">
                          3
                        </TabPanel>
                        <TabPanel value="4">
                          4
                        </TabPanel>
                        <TabPanel value="5">
                          5
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='d-block m-0 fw-bold text-uppercase text-start mb-3 mt-4'>
                      <span className='txtred'>DISTRIBUTION</span> <span className='txtdgray'>CHANNEL</span>
                    </h4>
                    <p className='f14'>Our products are available through Global Honda Distribution Network and each country across the globe, Honda Power Products Distribution network is available.<br />If there is no distributor or our above product is not available through them, then please do write to us at ho.exports@hspp.com</p>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                      <TabContext value={value1}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList
                            onChange={handle1Change}
                            variant='scrollable'
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            TabIndicatorProps={{
                              style: {
                                backgroundColor: '#CB1100'
                              },
                            }}
                            aria-label="">
                            <Tab label="ASIA & OCEANIA" value="1" />
                            <Tab label="NORTH AMERICA" value="2" />
                            <Tab label="MIDDLE EAST & AFRICA" value="3" />
                            <Tab label="SOUTH AMERICA" value="4" />
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                         {value1}
                            
                        </TabPanel>
                        <TabPanel value="2">
                         2
                        </TabPanel>
                        <TabPanel value="3">
                          3
                        </TabPanel>
                        <TabPanel value="4">
                          4
                        </TabPanel>
                      </TabContext>
                      {value1}
                      {isLoaded ? <><GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={2}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                          >
                            { /* Child components, such as markers, info windows, etc. */}
                            {markers.map(({ id, name, position }) => (
                              <Marker
                                key={id}
                                position={position}
                                onClick={() => handleActiveMarker(id)}
                              >
                                {activeMarker === id ? (
                                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                    <div>{name}</div>
                                  </InfoWindow>
                                ) : null}
                              </Marker>
                            ))}
                          </GoogleMap></> : <></>}
                    </Box>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='d-block m-0 fw-bold text-uppercase text-start mb-3 mt-4'>
                      <span className='txtred'>WRITE TO</span> <span className='txtdgray'>US</span>
                    </h4>
                    <p className='f14'>For any kind of Overseas business inquiry, please write a mail with your name, complete address, website details, E-Mail ID to hondaexports@hspp.com we will revert to your inquiry soon.</p>

                  </div>
                </div>
              </div>
              {/* content here */}

            </div>
          </div>
        </div>
      </div>


      <div style={{ clear: 'both', height: '40px' }}></div>
      <FotterMain />
    </>
  )
}

export default ExportsLP
