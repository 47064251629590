import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useMediaQuery } from '@mui/material';
import Eventboxtab from './Eventboxtab';
import PressReleasetab from './PressReleasetab';
import Videoboxtab from './Videoboxtab';

const ExploreMore = () => {
    const mediumViewport = useMediaQuery('(min-width:768px)');
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <div className='bgsgray  pt-5 pb-5 tabred'>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                <h4 className='d-block m-0 fw-bold text-uppercase text-center mb-3'>
                        <span className='txtred'>Explore</span> <span className='txtdgray'>More</span>
                </h4>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList 
                            onChange={handleChange} 
                            variant={mediumViewport ? "" : "scrollable"}
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                           
                            centered={mediumViewport ? true : false}
                            TabIndicatorProps={{
                                style: {
                                    //display: "none",
                                    backgroundColor:'#CB1100'
                                },
                              }}
                            
                            aria-label="">
                            <Tab label="VIDEOS" value="1" />
                            <Tab label="EVENTS" value="2" />
                            <Tab label="PRESS RELEASE" value="3" />
                        </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Videoboxtab />
                        </TabPanel>
                        <TabPanel value="2">
                            <Eventboxtab />
                        </TabPanel>
                        <TabPanel value="3">
                             <PressReleasetab />
                        </TabPanel>
                    </TabContext>
                </Box>
                </div>
            </div>
        </div>
    </div>
  )
}

export default React.memo(ExploreMore)