import React from 'react'
// import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
// import SearchIcon from '@mui/icons-material/Search';
// import CategoryIcon from '@mui/icons-material/Category';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Link } from 'react-router-dom';
const HeaderSearchCel = () => {
    const [searchText, setSearchText] = React.useState('');
    const searchTextChange = e => {
        setSearchText(e.target.value);
    };
    const popResult = searchText.length > 2;
    return (
        <>
            <div className='header__search'>
                <div className='search'>
                    <div className='search__body'>
                        <div className='search__shadow'></div>
                        {/* <IconButton disabled className='search__button search__button--start m-2'>
                            <CategoryIcon />
                        </IconButton> */}
                        <TextField
                            id="standard-basic"
                            label="Search By Products Name"
                            variant="standard"
                            InputLabelProps={{ min: 0, style: { width: '100%', fontSize: '16px', top: '-3px', transformOrigin: 'top center', color: '#999' } }}
                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                            className='w-100 ms-3 me-3 text-center'
                            size="small"
                            value={searchText}
                            onChange={searchTextChange}
                        />
                        {/* <IconButton className='search__button m-2'>
                            <SearchIcon />
                        </IconButton> */}
                        <div className="search__decor">
                            <div className="search__decor-start"></div>
                            <div className="search__decor-end"></div>
                        </div>
                        <div className={`search__dropdown search__dropdown--suggestions suggestions ${popResult ? 'search__dropdown--open' : ''}`}>
                            <div className="suggestions__group">
                                <div className="text-uppercase txtlgeay text-uppercase f12 ps-3 pb-2 fw-bold">Products</div>
                                <div className="ps-3 pe-3">
                                    <Link to="">
                                        <div className="d-flex text-black mb-2">
                                            <div className="">
                                                <img alt="" className='border p-1' src="https://www.hondaindiapower.com/uploads/products/category/Water-Pump-Sets.jpg" style={{width:'50px'}} />
                                            </div>
                                            <div className="flex-grow-1 ps-2 pe-2">
                                                <div className='f13'>WB30XD</div>
                                                <div className='txtlgeay f12 pt-1'>Petrol Water Pumps</div>
                                            </div>
                                            <div className="fw-bold"><CurrencyRupeeIcon className='f12 fw-bold' />23,231</div>
                                        </div>
                                    </Link>
                                    <Link to="">
                                        <div className="d-flex text-black mb-2">
                                            <div className="">
                                                <img alt="" className='border p-1' src="https://www.hondaindiapower.com/uploads/products/category/Water-Pump-Sets.jpg" style={{width:'50px'}} />
                                            </div>
                                            <div className="flex-grow-1 ps-2 pe-2">
                                                <div  className='f13'>WB20XD</div>
                                                <div className='txtlgeay f12 pt-1'>Petrol Water Pumps</div>
                                            </div>
                                            <div className="fw-bold"><CurrencyRupeeIcon className='f12 fw-bold' />23,231</div>
                                        </div>
                                    </Link>
                                    <Link to="">
                                        <div className="d-flex text-black mb-2">
                                            <div className="">
                                                <img alt="" className='border p-1' src="https://www.hondaindiapower.com/uploads/products/category/Water-Pump-Sets.jpg" style={{width:'50px'}} />
                                            </div>
                                            <div className="flex-grow-1 ps-2 pe-2">
                                                <div  className='f13'>WB15X</div>
                                                <div className='txtlgeay f12 pt-1'>Petrol Water Pumps</div>
                                            </div>
                                            <div className="fw-bold"><CurrencyRupeeIcon className='f12 fw-bold' />23,231</div>
                                        </div>
                                    </Link>
                                </div>
                                <hr className='mb-2' />
                                <div className="text-uppercase txtlgeay text-uppercase f12 ps-3 pb-2 fw-bold">CATEGORIES</div>
                                <div className="ps-3 pe-3">
                                    <Link to="">
                                        <div className="d-flex text-black mb-2">
                                            <div className="flex-grow-1">
                                                <div className="f12">Tillers</div>
                                            </div>
                                        </div>
                                        <div className="d-flex text-black mb-2">
                                            <div className="flex-grow-1">
                                                <div className="f12">Brush Cutters</div>
                                            </div>
                                        </div>
                                        <div className="d-flex text-black mb-2">
                                            <div className="flex-grow-1">
                                                <div className="f12">Water Pumps</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderSearchCel