import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import VideoBoxCel from '../VideoBoxCel';

const videoData = [
    {
        id: 1,
        title: 'India’s Natural Farming Methods',
        mode:'youtube',
        videoUrl: 'https://www.youtube.com/embed/MYBoQfpbXL0',
        videothumb: 'https://www.hondaindiapower.com//front/media/video-thu2.jpg',

    },
    {
        id: 2,
        title: 'Product Showcase',
        title: 'India’s Natural Farming Methods',
        mode:'web',
        videoUrl: 'https://www.hondaindiapower.com//front/media/video/HondaUMK450TProductAV.mp4',
        videothumb: 'https://www.hondaindiapower.com//front/media/video-new.jpg',
        
    }, {
        id: 3,
        title: 'Honda Corporate Video 2020',
        mode:'youtube',
        videoUrl: 'https://www.youtube.com/embed/vtNIpM-NDBM',
        videothumb: 'https://www.hondaindiapower.com//front/media/video/youtube-video-thumbnail.jpg',
    }, {
        id: 4,
        title: 'We are #HereForYou',
        mode:'web',
        videoUrl: 'https://www.hondaindiapower.com//front/media/video/stay-home-stay-safe.mp4',
        videothumb: 'https://www.hondaindiapower.com//front/media/video/facebook-video-thumbnail.jpg',
    }, {
        id: 5,
        title: 'Stroke Brush Cutter Range',
        mode:'youtube',
        videoUrl: 'https://www.facebook.com/watch/?v=743238659957018',
        videothumb: 'https://www.hondaindiapower.com//front/media/video/video5.jpg',
    }
];

const Videoboxtab = () => {
    const state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
    };
    return (
        <div>
            <OwlCarousel className='owl-theme with-nav-bottom-center' autoplay={false} items={4} nav={true} dots={false} responsive={state.responsive}>
                {videoData && videoData.length > 0 && videoData.map((data, key) => (
                     <div  key={key} className="p-2">
                        <VideoBoxCel itemIn={data} />
                     </div>
                ))}
            </OwlCarousel>
        </div>
    )
}

export default Videoboxtab