import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { actionTypes, useStateValue } from "./../../store";
import { saveToWishList } from '../../networkCalls';

const BtnWishList = ({ pid, btntype }) => {
    // console.log('pid', pid)
    // console.log('btntype', btntype)
    const [wish, setWish] = useState(false);
    const [{ token, huid, wishlistitemcount, wishListArrayID }, dispatch] = useStateValue();
    const handleWishList = (value) => {
        if (!token) {
            dispatch({ type: actionTypes.SET_LOGINMODAL, value: true });
        }
        else {
            var data = new FormData();
            data.append('customerId', huid);
            data.append('XAuth', token);
            data.append('productId', value);
            data.append('status', 1);
            saveToWishList(data).then((res) => {
                //console.log("res.data ", res.data);
                switch (res.data.status) {
                    case 1:
                        dispatch({ type: actionTypes.SET_WISHLISTCOUNT, value: wishlistitemcount + 1 });
                        dispatch({ type: actionTypes.SET_MSGALERTTYPE, value: 'success' });
                        dispatch({ type: actionTypes.SET_MSGALERTDATA, value: res.data.msz });
                        dispatch({ type: actionTypes.SET_MSGALERT, value: true });
                        return true;
                    case 0:
                        dispatch({ type: actionTypes.SET_MSGALERTTYPE, value: 'error' });
                        dispatch({ type: actionTypes.SET_MSGALERTDATA, value: res.data.msz });
                        dispatch({ type: actionTypes.SET_MSGALERT, value: true });
                        break;
                    default:
                        return;
                }
            })
        }
    }
    const checkAlredyWishList = () => {
        if (wishListArrayID?.indexOf(pid) > -1) {
            setWish(true)
        }
    }
    useEffect(() => {
        checkAlredyWishList();
    }, [wishListArrayID]);
    return (
        <>
            {btntype == 'icon' ? <>
                <Tooltip title="Add to WishList" arrow>
                    <IconButton size="small" className='mt-4 txtlgeay' edge="end" onClick={() => handleWishList(pid)}>
                        <FavoriteIcon fontSize="small" color={wish ? 'error' : ''} />
                    </IconButton>
                </Tooltip>
            </> : <>
                <Button variant="outlined" onClick={() => handleWishList(pid)} color="primary" startIcon={<FavoriteIcon />} type="submit" size="small" className='w-100 text-capitalize'>Add  wishlist</Button>
            </>}
        </>
    )
}

export default BtnWishList