import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactPlayer from 'react-player/lazy';

import { Modal } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const VideoBoxCel = ({ itemIn }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="post-card">
                <div className="post-card__image" onClick={handleShow}>
                    <Link to="#" onClick={handleShow}>
                    <LazyLoadImage
                        alt=""
                        effect="blur"
                        className='img-fluid w-100'
                        src={itemIn?.videothumb}
                    />
                    </Link>
                </div>
                <div className="post-card__content">
                    <div className="post-card__category"><span className='txtlgeay text-white'>Video</span></div>
                    <div className="post-card__title">
                        <h2 className='txtdgray'>{itemIn?.title}</h2>
                    </div>
                    <div className="post-card__date f12 mt-2"><Link to="#" className='txtred' onClick={handleShow}>VIEW VIDEO</Link></div>
                </div>
            </div>
          
            <Modal
                size="lg"
                show={show}
                backdrop="static"
                keyboard={false}
                contentClassName='border-0 rounded-0'
                onHide={() => handleClose()}
                aria-labelledby="video-lg"
                >
                <Modal.Body className="p-0">
                    <div onClick={() => handleClose()} aria-label="delete" size="small" className="position-absolute top-0 end-0 btn-out">
                        <CloseIcon fontSize="small" className='text-white' />
                    </div>
                    <div>
                        {itemIn?.mode=='youtube'?<>
                        <ReactPlayer url={itemIn?.videoUrl} width="100%" height='460px' />
                        {/* <iframe width="100%" height='440' src={itemIn?.videoUrl}
                        title={itemIn?.title} frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe> */}
                        </>
                        :null}

                       {itemIn?.mode=='web'?<>
                        <> 
                        <video controls width="100%" height='360px'>
                            <source src={itemIn?.videoUrl} type="video/mp4"  />
                            <p>Your browser doesn't support HTML5 video. Here is
                                a <a href={itemIn?.videoUrl}>link to the video</a> instead.</p>
                            </video></>
                        </>:null}
                    </div>
                    
                </Modal.Body>
            </Modal>
        </>
    )
}

export default VideoBoxCel