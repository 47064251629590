import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from '../../store';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Header from '../BaseComponents/Header';
import Avatar from '@mui/material/Avatar';
import FotterMain from '../BaseComponents/FotterMain';
import { getCustomerProfile, sendEmailVerification, emailOTPVerification } from '../../networkCalls';
import FullPageApiLoadingCell from '../BaseComponents/FullPageApiLoadingCell';

const validationSchemaOTP = yup.object({
  otp: yup
      .string('Enter your OTP')
      .min(4, 'Min 4 Digit')
      .max(4, 'Max 4 Digit')
      .matches(/^[0-9]+$/, "Must be only digits")
      .required('You must specify a 4 Digit OTP')
});

const Dashboard = () => {

  const [{ token, huid }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [susMessage, setSusMessage] = useState('');
  const [customerData, setCustomerData] = React.useState(null);

  const callApiProfile = () => {
    var data = new FormData();
    data.append('customerId', huid);
    data.append('XAuth', token);
    // for (var pair of data.entries()) {
    //      console.log(pair[0] + ', ' + pair[1]);
    // }
    setLoading(true);
    getCustomerProfile(data).then((res) => {
      setLoading(false);
      console.log('resres', res.data);
      switch (res.data.status) {
        case 1:
          setCustomerData(res.data.customerData);
          return true;
        case 0:
          setErrMessage(res.data.msz);
          break;
        default:
          return;
      }
    })
  }


  const [verifyModal, setVerifyModal] = useState(false);

  const handleClose = () => setVerifyModal(false);
  const handleShow = () => setVerifyModal(true);

  const verifyEmail = (value) => {
    var data = new FormData();
    data.append('customerId', huid);
    data.append('XAuth', token);
    data.append('email', value);
    // for (var pair of data.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    // setLoading(true);
    sendEmailVerification(data).then((res) => {
      console.log('resres', res.data);
      switch (res.data.status) {
        case 1:
          setVerifyModal(true);
          setSusMessage(res.data.msz);
          setErrMessage('');
          return true;
        case 0:
          setErrMessage(res.data.msz);
          setSusMessage('');
          break;
        default:
          return;
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: validationSchemaOTP,
    onSubmit: (values) => {
      var data = new FormData();
      data.append('customerId', huid);
      data.append('XAuth', token);
      data.append('OTP', values.otp);
      data.append('email', customerData?.email)
      // for (var pair of data.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }
      emailOTPVerification(data).then((res) => {
        console.log('resres', res.data);
        switch (res.data.status) {
          case 1:
            setSusMessage(res.data.msz);
            callApiProfile();
            setErrMessage('');
            setVerifyModal(false);
            return true;
          case 0:
            setErrMessage(res.data.msz);
            setSusMessage('');
            break;
          default:
            return;
        }
      });
    },
  });


  useEffect(() => {
    callApiProfile();
  }, [token, huid]);

  return (
    <>
      <Header />
      <div className='innerpagebg pt-5 pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h4 className='d-inline-block m-0 fw-bold text-uppercase'>
                <span className='txtred'>My</span> <span className='txtdgray'>Account</span>
              </h4>
            </div>
          </div>
        </div>
        <div className='container mt-3'>
          <div className='row'>
            <div className='col-lg-9 col-md-12 col-12 m-auto'>
              {loading ? <FullPageApiLoadingCell /> : <>
                <div className='card p-3 mb-3'>
                  <div className="d-flex">
                    <div>
                      <label className="text-uppercase fw-bold">Personal Information</label>
                    </div>
                    <div className="ms-auto">
                      <Link to="/myaccount/edit-profile">EDIT</Link>
                    </div>
                  </div>
                  <div className="col-md-12 col-12">
                    <hr />
                  </div>
                  <div className='row'>
                    <div className="col-md-2 col-12 mb-2">
                      <Avatar
                        alt="Remy Sharp"
                        src={customerData?.profilePic}
                        sx={{ width: 120, height: 120 }}
                      />
                    </div>
                    <div className="col-md-10 col-12 mb-2">
                      <div className='row mt-2'>
                        <div className="col-md-6 col-12 mb-2">
                          <label className="f12 d-block text-muted">Name</label>
                          <label className="f14 d-block">{customerData?.title} {customerData?.firstName} {customerData?.lastName}</label>
                        </div>
                        <div className="col-md-6 col-12 mb-2">
                          <label className="f12 d-block text-muted">Mobile</label>
                          <label className="f14 d-block">{customerData?.phone} {customerData?.phoneVerified == 1 ? <><Chip label="Verified" color="success" variant="outlined" /></> :
                            <><Chip label="Not Verified" color="error" variant="outlined" /></>}</label>
                        </div>
                        <div className="col-md-6 col-12 mb-2">
                          <label className="f12 d-block text-muted">Email Id</label>
                          <label className="f14 d-block">{customerData?.email} {customerData?.emailVerified == 1 ? <>
                            <Chip label="Verified" color="success" variant="outlined" /></> : <>
                            <Chip label="Not Verified" color="error" variant="outlined" /></>}</label>
                            {customerData?.emailVerified == 1 ? <></>:<><Button variant="outlined" onClick={() => verifyEmail(customerData?.email)} size="small" type="submit" className='mt-3'>
                            Verify your email id
                          </Button></>}
                        </div>
                        <div className="col-md-6 col-12 mb-2">
                          <label className="f12 d-block text-muted">Govt. Id Proof</label>
                          <label className="f14 d-block">{customerData?.govtIdProof}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>}
            </div>
            {/* <div className='col-lg-6 col-md-12 col-12'>
              <div className='card p-3 mb-3'>
                <div className="d-flex">
                  <div>
                    <label className="text-uppercase fw-bold">Business Information</label>
                  </div>
                  <div className="ms-auto">
                    <Link to="/myaccount/add-business-profile">ADD</Link>
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <hr />
                </div>
                <div className='row'>
                  <div className="col-md-6 col-12 mb-2">
                    <label className="f12 d-block text-muted">Business Name</label>
                    <label className="f14 d-block">Wizard Infoways Pvt. Ltd.</label>
                  </div>
                  <div className="col-md-6 col-12 mb-2">
                    <label className="f12 d-block text-muted">Company GST</label>
                    <label className="f14 d-block">09BATPG7667M</label>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Modal
        size="md"
        show={verifyModal}
        backdrop="static"
        keyboard={false}
        contentClassName='border-0 rounded-0'
        onHide={() => handleClose()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="p-0">
          <IconButton onClick={handleClose} aria-label="delete" size="small" className="position-absolute top-0 end-0">
            <CloseIcon fontSize="small" />
          </IconButton>
          <div className='mt-4'>
            <div className='p-3'>
              {errMessage ? <><Alert severity="error">{errMessage}</Alert></> : null}
              {susMessage ? <><Alert severity="success">{susMessage}</Alert></> : null}
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='p-3'>
                    <div className='text-left f14 mb-2 mt-3'>
                      Please enter the 4 Digit OTP sent<br /> to your Email Id <span className='fw-bold'>{customerData?.email}</span>
                    </div>
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                      <TextField
                        
                        className='mb-4 w-100'
                        variant="standard"
                        // type="number"
                        inputProps={{
                          maxLength: 4
                        }}
                        id="otp"
                        name="otp"
                        label="Enter 4 Digit Email OTP"
                        value={formik.values.otp}
                        onChange={formik.handleChange}
                        error={formik.touched.otp && Boolean(formik.errors.otp)}
                        helperText={formik.touched.otp && formik.errors.otp}
                      />
                      <Button color="error" variant="contained" className='w-100' type="submit">
                        Verify EMAIL OTP
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <FotterMain />
    </>
  );
};

export default Dashboard;
