import React from 'react'
import FotterMain from '../BaseComponents/FotterMain'
import Header from '../BaseComponents/Header'
import EnqueyCel from '../BaseComponents/HomeCels/EnqueyCel'
import FindDealerCel from '../BaseComponents/HomeCels/FindDealerCel'

const ContactUs = () => {
    return (
        <>
            <Header />
            <div className='innerpagebg pb-5'>
                <div className='about__image'>
                    <div className='about__image-bg'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-4 col-12 mt-5'>
                                    <div className='bg-white mt-5 p-4 pb-5 pt-5'>
                                        <h4 className='d-block m-0 fw-bold text-uppercase mb-3 mt-2 text-start'>
                                            <span className='txtred'>Contact</span> <span className='txtdgray'>Us</span>
                                        </h4>
                                        <div className='f18 text-start fw-bold'>HONDA INDIA POWER PRODUCTS LTD.</div>
                                        <div className='f14 mt-2'>(Formerly known as Honda Siel Power Products Ltd.)</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container pt-5'>
                    <div className='row'>
                        <div className='col-lg-4 col-12 mb-3'>
                            <div className='card rounded-0 border-0 shadow-sm p-4'>
                                <h5 className='f18 fw-bold'><span className='txtred'>HEAD</span> <span className='txtdgray'>OFFICE</span></h5>
                                <div className='f16' style={{lineHeight:'26px'}}>
                                    Plot no 5 Sector 41 Kasna,<br />
                                    Greater Noida Industrial Development Area<br />
                                    Distt Gautam Budh Nagar ( UP)<br />
                                    <span className='text-black fw-bold'>Telephone:</span> 0120-2590100
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12 mb-3'>
                            <div className='card rounded-0 border-0 shadow-sm p-4'>
                                <h5 className='f18 fw-bold'><span className='txtred'>REACH</span> <span className='txtdgray'>US</span></h5>
                                <div className='f16' style={{lineHeight:'26px'}}>
                                    <span className='text-black fw-bold'>Toll Free:</span> 1800-11-2323<br />
                                    <span className='text-black fw-bold'>E-Mail (Sales):</span> ho.mktg@hspp.com<br />
                                    <span className='text-black fw-bold'>E-Mail (Service):</span> ho.service@hspp.com<br />
                                    <span className='text-black fw-bold'></span><br />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12 mb-3'>
                            <div className='card rounded-0 border-0 shadow-sm p-4'>
                                <h5 className='f18 fw-bold'><span className='txtred'>REGISTERED</span> <span className='txtdgray'>OFFICE</span></h5>
                                <div className='f16' style={{lineHeight:'26px'}}>
                                    409, DLF Tower B<br />
                                    Jasola Commercial Complex, South Delhi<br />
                                    New Delhi- 110025<br />
                                    <span className='text-black fw-bold'>CIN:</span> L40103DL2004PLC203950
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-white pt-5'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <EnqueyCel />
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <FindDealerCel />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5"></div>
            <FotterMain />
        </>
    )
}

export default ContactUs