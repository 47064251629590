export const initialState = {
  token: null,
  loginmodal: false,
  compareitemcount: null,
  huid:null,
  wishlistitemcount: '0',
  wishListArrayID: null,
  msgalert: false,
  msgalertMessage: null,
  msgalertType: '',
};

export const actionTypes = {
  SET_TOKEN: "SET_TOKEN",
  SET_HUID: "SET_HUID",
  SET_LOGINMODAL: "SET_LOGINMODAL",
  SET_COMPAREITEMCOUNT: "SET_COMPAREITEMCOUNT",
  SET_WISHLISTCOUNT: "SET_WISHLISTCOUNT",
  SET_WISHLISTALLID: "SET_WISHLISTALLID",
  SET_MSGALERT: "SET_MSGALERT",
  SET_MSGALERTDATA: "SET_MSGALERTDATA",
  SET_MSGALERTTYPE: "SET_MSGALERTTYPE",

};

const reducer = (state, action) => {
  //console.log(action);
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return { ...state, token: action.value };
    case actionTypes.SET_HUID:
        return { ...state, huid: action.value };
    case actionTypes.SET_LOGINMODAL:
        return { ...state, loginmodal: action.value };
    case actionTypes.SET_COMPAREITEMCOUNT:
        return { ...state, compareitemcount: action.value };
    case actionTypes.SET_MSGALERT:
        return { ...state, msgalert: action.value };
    case actionTypes.SET_MSGALERTDATA:
        return { ...state, msgalertMessage: action.value };
    case actionTypes.SET_MSGALERTTYPE:
        return { ...state, msgalertType: action.value };
    case actionTypes.SET_WISHLISTCOUNT:
        return { ...state, wishlistitemcount: action.value };
    case actionTypes.SET_WISHLISTALLID:
        return { ...state, wishListArrayID: action.value };
    default:
      return state;
  }
};

export default reducer;
